// src/store/breadcrumbSlice.ts

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'

interface BreadcrumbState {
  breadcrumbMap: Record<string, string>
}

const initialState: BreadcrumbState = {
  breadcrumbMap: {},
}

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    // setBreadcrumbMap: (state, action) => {
    //   return { ...state, ...action.payload }
    // },
    setBreadcrumbMap(state, action: PayloadAction<Record<string, string>>) {
      state.breadcrumbMap = action.payload
    },
  },
})

// export const { setBreadcrumbMap } = breadcrumbSlice.actions

// export const breadcrumbData = (state: RootState) => state.breadcrumb.breadcrumbMap

// export default breadcrumbSlice.reducer

export const { setBreadcrumbMap } = breadcrumbSlice.actions
export const selectBreadcrumbData = (state: RootState) => state.breadcrumb.breadcrumbMap
export default breadcrumbSlice.reducer

// export const { setBreadcrumbMap } = breadcrumbSlice.actions

// export default breadcrumbSlice.reducer

const CameraIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M19.9999 18.3327C18.1666 18.3327 16.6666 19.8327 16.6666 21.666C16.6666 23.4993 18.1666 24.9993 19.9999 24.9993C21.8333 24.9993 23.3333 23.4993 23.3333 21.666C23.3333 19.8327 21.8333 18.3327 19.9999 18.3327ZM31.6666 11.666H29.4999L28.9999 10.166C28.8333 9.66602 28.6666 9.33268 28.3333 8.83268C28.3333 8.83268 28.3333 8.83268 28.3333 8.66602L28.1666 8.49935C27.1666 7.33268 25.8333 6.66602 24.3333 6.66602H15.6666C14.8333 6.66602 13.8333 6.99935 13.1666 7.49935C12.9999 7.49935 12.9999 7.66602 12.8333 7.66602C11.9999 8.33268 11.3333 9.16602 10.9999 10.166L10.4999 11.666H8.33325C5.49992 11.666 3.33325 13.8327 3.33325 16.666V29.9993C3.33325 32.8327 5.49992 34.9993 8.33325 34.9993H31.6666C34.4999 34.9993 36.6666 32.8327 36.6666 29.9993V16.666C36.6666 13.8327 34.4999 11.666 31.6666 11.666ZM19.9999 28.3327C16.3333 28.3327 13.3333 25.3327 13.3333 21.666C13.3333 17.9993 16.3333 14.9993 19.9999 14.9993C23.6666 14.9993 26.6666 17.9993 26.6666 21.666C26.6666 25.3327 23.6666 28.3327 19.9999 28.3327Z"
        fill="white"
      />
    </svg>
  )
}

export default CameraIcon

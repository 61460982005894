import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LinkExpiryIcon, LogoIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import { LINK_EXPIRY } from 'constants/Constants'

import '../login.scss'

const LinkExpiry = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-neutral-50">
      <div className="link-wrapper screen-height">
        <div className="link-sub-wrapper">
          <LogoIcon />
          <p className="desktop-heading-h3 mt-6 mb-2 text-neutral-800 font-medium">{LINK_EXPIRY.LINK_EXPIRE}</p>
          <p className="para-p2 text-neutral-500 font-medium mb-10">{LINK_EXPIRY.SORRY_TEXT}</p>

          <LinkExpiryIcon />

          <CustomButton
            className="mt-6"
            text={LINK_EXPIRY.LOGIN}
            htmlType="button"
            onClick={() => {
              navigate('/login')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default LinkExpiry

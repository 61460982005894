import React from 'react'

const DownIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? '20'} height={props.height ?? '20'} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M14.0827 7.6666C13.7493 7.33326 13.2493 7.33326 12.916 7.6666L9.99935 10.5833L7.08268 7.6666C6.74935 7.33326 6.24935 7.33326 5.91602 7.6666C5.58268 7.99993 5.58268 8.49993 5.91602 8.83326L9.41602 12.3333C9.58268 12.4999 9.74935 12.5833 9.99935 12.5833C10.2493 12.5833 10.416 12.4999 10.5827 12.3333L14.0827 8.83326C14.416 8.49993 14.416 7.99993 14.0827 7.6666Z"
        fill={props.className ?? 'black'}
      />
    </svg>
  )
}

export default DownIcon

import React from 'react'

const SelectDownIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M14.0827 8.16656C13.7493 7.83323 13.2493 7.83323 12.916 8.16656L9.99935 11.0832L7.08268 8.16656C6.74935 7.83323 6.24935 7.83323 5.91602 8.16656C5.58268 8.4999 5.58268 8.9999 5.91602 9.33323L9.41602 12.8332C9.58268 12.9999 9.74935 13.0832 9.99935 13.0832C10.2493 13.0832 10.416 12.9999 10.5827 12.8332L14.0827 9.33323C14.416 8.9999 14.416 8.4999 14.0827 8.16656Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  )
}

export default SelectDownIcon

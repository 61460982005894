import React from 'react'
import { Typography } from 'antd'

interface IHeadingText {
  text: string
  classString?: string
  onClick?: () => void
  style?: any
}

const HeadingText = ({ text, style, classString, onClick }: IHeadingText) => {
  const { Text } = Typography

  // eslint-disable-next-line react/prop-types
  return (
    <Text className={classString} onClick={onClick} style={style}>
      {text}
    </Text>
  )
}
export default HeadingText

import React from 'react'

const SearchIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6089 11.2555L11.3313 11.6048L11.6468 11.9202L14.1134 14.3869C14.156 14.4294 14.167 14.4701 14.167 14.5C14.167 14.5299 14.156 14.5705 14.1134 14.6131C14.0709 14.6557 14.0302 14.6667 14.0003 14.6667C13.9704 14.6667 13.9298 14.6557 13.8872 14.6131L11.4205 12.1464L11.1065 11.8323L10.7576 12.1072C8.74017 13.6967 5.86474 13.6976 3.84641 12.1099C1.52434 10.2126 1.10015 6.72698 2.98976 4.34693C4.88756 2.0233 8.37546 1.59928 10.7559 3.4914C13.1356 5.38301 13.507 8.86762 11.6089 11.2555ZM2.16699 7.83332C2.16699 10.7095 4.45751 13 7.33366 13C10.2098 13 12.5003 10.7095 12.5003 7.83332C12.5003 4.95718 10.2098 2.66665 7.33366 2.66665C4.45751 2.66665 2.16699 4.95718 2.16699 7.83332Z"
        stroke="#68696C"
      />
    </svg>
  )
}

export default SearchIcon

import React from 'react'

const BlackFlag = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none" {...props}>
      <path
        d="M15.27 1.68C15.0417 1.51551 14.7779 1.40715 14.4999 1.36372C14.2219 1.32028 13.9376 1.34299 13.67 1.43C12.9642 1.62849 12.2331 1.72281 11.5 1.71C10.4166 1.65119 9.35433 1.38647 8.37002 0.93C7.15404 0.367632 5.83875 0.0515559 4.50002 0C1.61002 0 0.500017 1 0.300017 1.14C0.204325 1.23379 0.128459 1.34586 0.0769233 1.46954C0.0253878 1.59323 -0.000765517 1.72601 1.7057e-05 1.86V17C1.7057e-05 17.2652 0.105374 17.5196 0.29291 17.7071C0.480447 17.8946 0.734801 18 1.00002 18C1.26523 18 1.51959 17.8946 1.70712 17.7071C1.89466 17.5196 2.00002 17.2652 2.00002 17V12.7C2.79685 12.3949 3.64748 12.2554 4.50002 12.29C5.58343 12.3488 6.64571 12.6135 7.63002 13.07C8.846 13.6324 10.1613 13.9484 11.5 14C12.7055 14.0455 13.9047 13.8057 15 13.3C15.2952 13.1613 15.5455 12.9425 15.7223 12.6684C15.8991 12.3944 15.9954 12.0761 16 11.75V3.11C15.9997 2.83097 15.9333 2.55598 15.8065 2.30747C15.6796 2.05895 15.4958 1.84395 15.27 1.68ZM14 11.59C13.2029 11.894 12.3525 12.0335 11.5 12C10.4153 11.9425 9.35212 11.6742 8.37002 11.21C7.15172 10.6558 5.8374 10.3433 4.50002 10.29C3.65752 10.2755 2.81682 10.373 2.00002 10.58V2.42C2.79498 2.10734 3.64652 1.96428 4.50002 2C5.58477 2.05748 6.64792 2.32582 7.63002 2.79C8.84832 3.34425 10.1626 3.6567 11.5 3.71C12.343 3.72245 13.1839 3.62154 14 3.41V11.59Z"
        fill={props.className ?? 'black'}
      />
    </svg>
  )
}

export default BlackFlag

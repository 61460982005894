import React from 'react'

const ProviderProfileBadge = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="72" viewBox="0 0 51 72" fill="none">
      <path
        d="M25.5086 3.52857C25.7885 3.45417 26.0829 3.45417 26.3628 3.52857L49.7141 9.73607C50.4424 9.92968 50.9493 10.589 50.9493 11.3426V42.1759C50.9493 47.6277 48.4953 52.7899 44.2674 56.2319L28.1058 69.3891C26.842 70.418 25.0293 70.418 23.7654 69.3891L7.6614 56.2788C3.39704 52.8071 0.921875 47.6004 0.921875 42.1016V11.3426C0.921875 10.589 1.42884 9.92968 2.15716 9.73607L25.5086 3.52857Z"
        fill="#6E1BE0"
      />
      <path
        d="M4.30745 10.1343L25.9879 4.37097L47.6683 10.1343V41.977C47.6683 44.9745 46.319 47.8128 43.9944 49.7052L25.9879 64.3644L7.9813 49.7052C5.65672 47.8128 4.30745 44.9745 4.30745 41.9769V10.1343Z"
        fill="url(#paint0_linear_14300_232563)"
        stroke="url(#paint1_linear_14300_232563)"
        strokeWidth="6.66667"
      />
      <g filter="url(#filter0_d_14300_232563)">
        <path
          d="M35.6886 28.7714H29.8705V23.0133C29.8705 22.1171 29.1364 21.3906 28.2309 21.3906H23.6909C22.7855 21.3906 22.0514 22.1171 22.0514 23.0133V28.7714H16.2333C15.3278 28.7714 14.5938 29.4979 14.5938 30.394V34.8872C14.5938 35.7834 15.3278 36.5099 16.2333 36.5099H22.0514V42.268C22.0514 43.1641 22.7854 43.8906 23.6909 43.8906H28.2309C29.1364 43.8906 29.8705 43.1642 29.8705 42.268V36.5099H35.6886C36.5941 36.5099 37.3281 35.7834 37.3281 34.8872V30.394C37.3281 29.4979 36.5941 28.7714 35.6886 28.7714Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id="filter0_d_14300_232563" x="13.9688" y="21.2344" width="23.9844" height="23.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.46875" />
          <feGaussianBlur stdDeviation="0.3125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14300_232563" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14300_232563" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_14300_232563" x1="26.0008" y1="24.125" x2="26.0007" y2="96.3516" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_14300_232563" x1="-7.78069" y1="-10.9328" x2="62.4271" y2="51.6658" gradientUnits="userSpaceOnUse">
          <stop stopColor="#220EB1" />
          <stop offset="1" stopColor="#9A46F7" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ProviderProfileBadge

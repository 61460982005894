import React, { Suspense, useEffect } from 'react'
import { ConfigProvider } from 'antd'
import Loader from 'component/Loader/Loader'
import AppRoutes from 'routes/routes'

// import './firebase/messaging_init_in_sw'
import { onForegroundMessage, requestPermission } from './firebase/messaging_init_in_sw'

const App = () => {
  const primaryColor = localStorage.getItem('theme-color') ?? '#220EB1'

  useEffect(() => {
    const setupFirebase = async () => {
      const permissionGranted = await requestPermission()
      if (permissionGranted) {
        onForegroundMessage((payload) => {
          // Trigger browser notification
          void new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: '/firebase-logo.png',
          })
        })
      }
    }

    void setupFirebase()
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryColor,
          fontFamily: 'DM Sans, sans-serif',
        },
      }}
    >
      <Loader />
      <Suspense fallback={<Loader />}>
        <AppRoutes />
      </Suspense>
    </ConfigProvider>
  )
}

export default App

import React, { useEffect } from 'react'
import { COMMON } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

const DashBoard = () => {
  const dispatch = useAppDispatch()
  const isFacility = useAppSelector((state) => state.auth.auth.profileDetails.isFacility)
  const facilityName = useAppSelector((state) => state.auth.auth.profileDetails.facility_name)
  const firstName = useAppSelector((state) => state.auth.auth.profileDetails.first_name)

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: `${COMMON.HELLO} ${isFacility ? facilityName : firstName}.`,
      })
    )
  }, [dispatch])

  return <div>DashBoard</div>
}

export default DashBoard

import React from 'react'

const GoldStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M16.5 8.07495C16.575 7.69995 16.275 7.24995 15.9 7.24995L11.625 6.64995L9.675 2.74995C9.6 2.59995 9.525 2.52495 9.375 2.44995C9 2.22495 8.55 2.37495 8.325 2.74995L6.45 6.64995L2.175 7.24995C1.95 7.24995 1.8 7.32495 1.725 7.47495C1.425 7.77495 1.425 8.22495 1.725 8.52495L4.8 11.525L4.05 15.8C4.05 15.95 4.05 16.1 4.125 16.25C4.35 16.625 4.8 16.775 5.175 16.55L9 14.525L12.825 16.55C12.9 16.625 13.05 16.625 13.2 16.625C13.275 16.625 13.275 16.625 13.35 16.625C13.725 16.55 14.025 16.175 13.95 15.725L13.2 11.45L16.275 8.44995C16.425 8.37495 16.5 8.22495 16.5 8.07495Z"
        fill="#FB8600"
      />
    </svg>
  )
}

export default GoldStar

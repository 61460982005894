// builtin
import React from 'react'
// External
import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from 'hooks/reduxHooks'

const PublicRoute = () => {
  const token = useAppSelector((state) => state.auth.auth.accessToken)
  return token ? <Navigate to="/" /> : <Outlet />
}

export default PublicRoute

const AngleLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M8.83333 9.99992L12.9167 5.91659C13.25 5.58326 13.25 5.08326 12.9167 4.74992C12.5833 4.41659 12.0833 4.41659 11.75 4.74992L7 9.49992C6.66667 9.83326 6.66667 10.3333 7 10.6666L11.75 15.4166C11.9167 15.5833 12.0833 15.6666 12.3333 15.6666C12.5833 15.6666 12.75 15.5833 12.9167 15.4166C13.25 15.0833 13.25 14.5833 12.9167 14.2499L8.83333 9.99992Z"
        fill="#220EB1"
      />
    </svg>
  )
}

export default AngleLeft

import { useEffect, useState } from 'react'
import { Dropdown, message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DownIcon, DownloadPdf, ThreeDotIcon, UploadDoc } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, TABLE_COLUMNS } from 'constants/Constants'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/helper'

import { GET_PROVIDER_CREDENTIAL } from '../api'

const Credential = ({ providerId, tabNo }: { providerId: string; tabNo: string }) => {
  const dispatch = useAppDispatch()
  const [listData, setNewListData] = useState([])
  const [visibleSections, setVisibleSections] = useState<number[]>([])
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })

  useEffect(() => {
    if (providerId && tabNo === '3') {
      void getCredentialData(providerId, sortTable?.order, sortTable?.field)
    }
  }, [providerId, tabNo, sortTable])

  const getCredentialData = async (id: string, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_PROVIDER_CREDENTIAL(id, order, field)
      if (response?.status === 200) {
        setNewListData(response?.data?.data ?? [])
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const toggleVisibility = (index: number) => {
    setVisibleSections((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.CREDENTIAL.CREDENTIAL,
      dataIndex: 'credential',
      key: 'credential',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 140,
      render: (_value, record) => {
        return <HeadingText text={record?.name} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 301,
      render: (_value, record) => {
        return <HeadingText text={record?.document_response?.credential} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.ISSUE_DATE,
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return <HeadingText text={dayjs(record?.document_response?.issue_date).format('MM/DD/YYYY, hh:mm A') ?? ''} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.EXPIRY_DATE,
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return <HeadingText text={dayjs(record?.document_response?.expiry_date).format('MM/DD/YYYY, hh:mm A') ?? ''} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.VERIFIED,
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',
      width: 91,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return <HeadingText text={formatTitle(record?.document_response?.is_verified)} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.DAYS,
      dataIndex: 'days',
      key: 'days',
      align: 'center',
      width: 66,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        const issueDate: Date = new Date(String(record?.document_response?.issue_date) ?? '')
        const expiryDate: Date = new Date(String(record?.document_response?.expiry_date) ?? '')
        const timeDifference: number = expiryDate.getTime() - issueDate.getTime()
        const daysDifference: number = timeDifference / (1000 * 3600 * 24)
        return <HeadingText text={Number.isNaN(daysDifference) ? '0' : String(daysDifference)} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.LICENCSE_NO,
      dataIndex: 'licenceNO',
      key: 'licenceNO',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 138,
      render: (_value, record) => {
        return <HeadingText text={record?.document_response?.license} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.ACTION,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 112,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName={`p-4 action-dropdown`}
              menu={{
                items: [
                  {
                    label: (
                      <div className="cursor-pointer w-full p-1" key="1">
                        <p className="flex justify-start items-start gap-2">
                          <UploadDoc /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.UPLOAD_DOCUMENT}</span>
                        </p>
                      </div>
                    ),
                    key: 0,
                  },
                  {
                    label: (
                      <div className="cursor-pointer p-1" key="2">
                        <p className="flex items-center gap-2">
                          <DownloadPdf /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.DOWNLOAD_PDF}</span>
                        </p>
                      </div>
                    ),
                    key: 1,
                  },
                ],
              }}
              trigger={['click']}
            >
              <CustomButton isIcon svgIcon={<ThreeDotIcon className="cursor-pointer svg" />} text="" className="btn-padding-8 three-dot-icon" type="default" />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="flex flex-col items-center gap-4 w-full px-10 pb-10 credential-wrapper">
      {listData?.map((item: { id: string; name: string; credentials_document: any[] }, index) => (
        <div key={item?.id ?? index} className="credential-type-wrapper w-full">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              toggleVisibility(index)
            }}
          >
            <div
              style={{
                transform: !visibleSections.includes(index) ? 'rotate(-90deg)' : 'rotate(0deg)', // Check if this section is in visibleSections
                transition: 'transform 0.3s',
              }}
            >
              <DownIcon height={25} width={24} />
            </div>
            <p className="text-neutral-800 mobile-heading-h5 font-medium">{item?.name}</p>
          </div>
          {visibleSections.includes(index) && <CustomTable column={columns} data={item.credentials_document} onChange={handleTableChange} />}
        </div>
      ))}
    </div>
  )
}

export default Credential

/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useState } from 'react'
import { Table, type TableColumnsType } from 'antd'
import { DownIcon } from 'assets/svgs'
import { COMMON, FACILITY_USER_PROFILE } from 'constants/Constants'

const data = [
  {
    id: 1,
    speciality: 'LTC Rehab Assisted Living',
    payBill: 'Reg Pay',
    shift1: '21.00',
    shift2: '21.00',
    shift3: '21.00',
    shift4: '21.00',
    shift5: '21.00',
    shiftEnd1: '21.00',
    shiftEnd2: '21.00',
    shiftEnd3: '21.00',
    shiftEnd4: '21.00',
    shiftEnd5: '21.00',
  },
  {
    id: 2,
    payBill: 'Reg Bill',
    shift1: '33.00',
    shift2: '33.00',
    shift3: '33.00',
    shift4: '33.00',
    shift5: '33.00',
    shiftEnd1: '33.00',
    shiftEnd2: '33.00',
    shiftEnd3: '33.00',
    shiftEnd4: '33.00',
    shiftEnd5: '33.00',
  },
  {
    id: 3,
    payBill: 'Double Bill',
    shift1: '00.00',
    shift2: '00.00',
    shift3: '00.00',
    shift4: '00.00',
    shift5: '00.00',
    shiftEnd1: '00.00',
    shiftEnd2: '00.00',
    shiftEnd3: '00.00',
    shiftEnd4: '00.00',
    shiftEnd5: '00.00',
  },
]

const RateSheet = () => {
  const [tableVisible, setTableVisible] = useState(false)
  const [tableVisible1, setTableVisible1] = useState(false)
  const [tableVisible2, setTableVisible2] = useState(false)
  const [tableVisible3, setTableVisible3] = useState(false)

  const columns: TableColumnsType = [
    {
      title: FACILITY_USER_PROFILE.RATE_SHEET.SPECIALITY,
      dataIndex: 'speciality',
      key: 'speciality',
      align: 'start',
      width: 131,
    },
    {
      title: FACILITY_USER_PROFILE.RATE_SHEET.PAY_BILL,
      align: 'center',
      children: [
        {
          title: '',
          dataIndex: 'payBill',
          key: 'payBill',
          align: 'center',
          width: 120,
          render: (text: string, record: any, index: number) => {
            if (index < 2) {
              return <span className="text-neutral-800 font-semi-bold">{text}</span>
            } else {
              return <span>{text}</span>
            }
          },
        },
      ],
    },
    {
      title: FACILITY_USER_PROFILE.RATE_SHEET.WEEK_DAY,
      align: 'center',
      children: [
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 1`,
          dataIndex: 'shift1',
          key: 'shift1',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 2`,
          dataIndex: 'shift2',
          key: 'shift2',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 3`,
          dataIndex: 'shift3',
          key: 'shift3',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 4`,
          dataIndex: 'shift4',
          key: 'shift4',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 5`,
          dataIndex: 'shift5',
          key: 'shift5',
          align: 'center',
          width: 120,
        },
      ],
    },
    {
      title: FACILITY_USER_PROFILE.RATE_SHEET.WEEK_END,
      align: 'center',
      children: [
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 1`,
          dataIndex: 'shiftEnd1',
          key: 'shiftEnd1',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 2`,
          dataIndex: 'shiftEnd2',
          key: 'shiftEnd2',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 3`,
          dataIndex: 'shiftEnd3',
          key: 'shiftEnd3',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 4`,
          dataIndex: 'shiftEnd4',
          key: 'shiftEnd4',
          align: 'center',
          width: 120,
        },
        {
          title: `${FACILITY_USER_PROFILE.RATE_SHEET.SHIFT} 5`,
          dataIndex: 'shiftEnd5',
          key: 'shiftEnd5',
          align: 'center',
          width: 120,
        },
      ],
    },
  ]
  return (
    <div className="rateSheet-wrapper flex px-10 flex-col items-center gap-4 self-stretch">
      <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-4 bg-neutral-50">
        <div
          className="flex items-center gap-2 w-full cursor-pointer"
          onClick={() => {
            setTableVisible(!tableVisible)
          }}
        >
          <DownIcon />
          <p className="text-neutral-800 text-20 font-medium">{COMMON.CMA}</p>
        </div>
        {tableVisible && (
          <span className={`w-full table-auto ${tableVisible ? 'transition-height' : ''}`}>
            <Table columns={columns} dataSource={data} bordered />
          </span>
        )}
      </div>
      <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-4 bg-neutral-50">
        <div
          className="flex items-center gap-2 w-full cursor-pointer"
          onClick={() => {
            setTableVisible1(!tableVisible1)
          }}
        >
          <DownIcon />
          <p className="text-neutral-800 text-20 font-medium">{COMMON.CNA}</p>
        </div>
        {tableVisible1 && (
          <span className={`w-full table-auto ${tableVisible1 ? 'transition-height' : ''}`}>
            <Table columns={columns} dataSource={data} bordered />
          </span>
        )}
      </div>
      <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-4 bg-neutral-50">
        <div
          className="flex items-center gap-2 w-full cursor-pointer"
          onClick={() => {
            setTableVisible2(!tableVisible2)
          }}
        >
          <DownIcon />
          <p className="text-neutral-800 text-20 font-medium">{COMMON.LPN}</p>
        </div>
        {tableVisible2 && (
          <span className={`w-full table-auto ${tableVisible2 ? 'transition-height' : ''}`}>
            <Table columns={columns} dataSource={data} bordered />
          </span>
        )}
      </div>
      <div className="flex p-4 flex-col justify-center items-center gap-2 self-stretch rounded-4 bg-neutral-50">
        <div
          className="flex items-center gap-2 w-full cursor-pointer"
          onClick={() => {
            setTableVisible3(!tableVisible3)
          }}
        >
          <DownIcon />
          <p className="text-neutral-800 text-20 font-medium">{COMMON.RN}</p>
        </div>
        {tableVisible3 && (
          <span className={`w-full table-auto ${tableVisible3 ? 'transition-height' : ''}`}>
            <Table columns={columns} dataSource={data} bordered />
          </span>
        )}
      </div>
    </div>
  )
}

export default RateSheet

import React from 'react'

const FacilityCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.4739 4.80657C12.412 4.74409 12.3382 4.69449 12.257 4.66065C12.1758 4.6268 12.0886 4.60938 12.0006 4.60938C11.9126 4.60938 11.8255 4.6268 11.7442 4.66065C11.663 4.69449 11.5893 4.74409 11.5273 4.80657L6.56062 9.77991L4.47395 7.68657C4.4096 7.62441 4.33364 7.57554 4.25041 7.54274C4.16717 7.50993 4.07829 7.49385 3.98883 7.49539C3.89938 7.49694 3.81111 7.51609 3.72905 7.55176C3.647 7.58742 3.57278 7.63889 3.51062 7.70324C3.44846 7.76759 3.39958 7.84355 3.36678 7.92679C3.33398 8.01002 3.31789 8.09891 3.31944 8.18836C3.32099 8.27781 3.34014 8.36609 3.3758 8.44814C3.41146 8.53019 3.46294 8.60441 3.52728 8.66657L6.08728 11.2266C6.14926 11.2891 6.22299 11.3387 6.30423 11.3725C6.38547 11.4063 6.47261 11.4238 6.56062 11.4238C6.64863 11.4238 6.73576 11.4063 6.817 11.3725C6.89824 11.3387 6.97198 11.2891 7.03395 11.2266L12.4739 5.78657C12.5416 5.72415 12.5956 5.64838 12.6326 5.56404C12.6695 5.47971 12.6886 5.38864 12.6886 5.29657C12.6886 5.20451 12.6695 5.11344 12.6326 5.0291C12.5956 4.94477 12.5416 4.869 12.4739 4.80657Z"
        fill="#68696C"
      />
    </svg>
  )
}

export default FacilityCheckIcon

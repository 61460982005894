import React, { type ReactNode, useEffect, useRef, useState } from 'react'
import { Form, TimePicker } from 'antd'
import { SelectDownIcon } from 'assets/svgs'
import dayjs from 'dayjs'

import './TimePicker.scss'

interface IRules {
  required?: boolean
  message?: string
}

interface ITimePicker {
  name: string
  format?: string
  rules?: IRules[] | any
  placeholder?: string
  icon?: ReactNode
  use12Hours?: boolean
  inputClass?: string
  labelClass?: string
  textClass?: string
  wrapperClass?: string
  error?: string | boolean
  disabled?: boolean
  mainClass?: string
  form: any
  value?: any
  reset?: boolean
  onChange?: (time: any) => void
}

const TimePickerField = ({ ...props }: ITimePicker) => {
  const {
    form,
    wrapperClass,
    mainClass,
    inputClass,
    labelClass,
    textClass,
    error,
    onChange,
    placeholder,
    rules,
    name,
    format,
    icon = '',
    disabled,
    value,
    reset,
    use12Hours = true,
  } = props

  const [focused, setFocused] = useState(false)
  const [hasValue, setValue] = useState(false)
  const timeRef = useRef<any>(null)

  useEffect(() => {
    const initialValue = timeRef.current?.nativeElement?.children[0]?.children[0]?.value
    const isValidDate = initialValue ? initialValue !== '' : false
    setFocused(isValidDate)
    setValue(form.getFieldValue(name))
  }, [timeRef])

  useEffect(() => {
    if (reset) {
      setFocused(false)
      setValue(false)
    }
  }, [reset])

  useEffect(() => {
    if (value) {
      setFocused(true)
      setValue(true)
    } else {
      setFocused(false)
      setValue(false)
    }
  }, [value])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    if (timeRef.current?.nativeElement?.children[0]?.children[0]?.value) {
      setValue(true)
      setFocused(true)
    } else {
      setValue(false)
      setFocused(false)
    }
  }

  const handleChange = (time: any) => {
    const isValidDate = dayjs(time).isValid()
    setValue(isValidDate)
    setFocused(isValidDate)
    if (onChange) {
      onChange(time) // Call the custom onChange function if provided
    }
  }

  return (
    <div className={`${mainClass as string}`}>
      <div className={`${wrapperClass as string} timePicker-wrapper ${error ? '' : 'mb-6'}`}>
        <div className={`floatingLabelInput ${inputClass as string}`}>
          {icon !== '' && <div className={`icon-container ${disabled ? 'icon-disabled' : ''}`}>{icon}</div>}
          <label
            htmlFor={name}
            className={`label-float ${focused || hasValue ? 'actives' : ''} ${labelClass as string} ${icon !== '' ? 'left-50' : ''} ${disabled ? 'text-neutral-300' : ''}`}
          >
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules} className="form-item-wrapper">
            <TimePicker
              id={name}
              ref={timeRef}
              suffixIcon={<SelectDownIcon color={disabled ? '#919195' : 'black'} />}
              popupClassName="time-menu-wrapper"
              className={`${textClass as string}`}
              needConfirm={false}
              onFocus={handleFocus}
              use12Hours={use12Hours}
              format={format}
              onBlur={handleBlur}
              onChange={handleChange}
              style={{
                border: '1px solid #C4C5C7',
                ...(icon !== '' && { paddingLeft: '50px' }),
              }}
              {...props}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </div>
  )
}

export default TimePickerField

import React from 'react'

const ActivityClockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.0002 7.3335H8.66683V4.66683C8.66683 4.49002 8.59659 4.32045 8.47157 4.19543C8.34655 4.0704 8.17698 4.00016 8.00017 4.00016C7.82336 4.00016 7.65379 4.0704 7.52876 4.19543C7.40374 4.32045 7.3335 4.49002 7.3335 4.66683V8.00016C7.3335 8.17697 7.40374 8.34654 7.52876 8.47157C7.65379 8.59659 7.82336 8.66683 8.00017 8.66683H10.0002C10.177 8.66683 10.3465 8.59659 10.4716 8.47157C10.5966 8.34654 10.6668 8.17697 10.6668 8.00016C10.6668 7.82335 10.5966 7.65378 10.4716 7.52876C10.3465 7.40373 10.177 7.3335 10.0002 7.3335ZM8.00017 1.3335C6.68162 1.3335 5.39269 1.72449 4.29636 2.45703C3.20004 3.18957 2.34555 4.23077 1.84097 5.44894C1.33638 6.66711 1.20436 8.00756 1.4616 9.30077C1.71883 10.594 2.35377 11.7819 3.28612 12.7142C4.21847 13.6466 5.40636 14.2815 6.69956 14.5387C7.99277 14.796 9.33322 14.6639 10.5514 14.1594C11.7696 13.6548 12.8108 12.8003 13.5433 11.704C14.2758 10.6076 14.6668 9.31871 14.6668 8.00016C14.6649 6.23266 13.9619 4.53811 12.712 3.28829C11.4622 2.03847 9.76767 1.33546 8.00017 1.3335ZM8.00017 13.3335C6.94533 13.3335 5.91419 13.0207 5.03712 12.4347C4.16006 11.8486 3.47648 11.0157 3.07281 10.0411C2.66914 9.0666 2.56352 7.99425 2.76931 6.95968C2.9751 5.92512 3.48305 4.97481 4.22893 4.22893C4.97481 3.48305 5.92512 2.9751 6.95968 2.76931C7.99425 2.56352 9.06661 2.66914 10.0411 3.07281C11.0157 3.47647 11.8486 4.16006 12.4347 5.03712C13.0207 5.91418 13.3335 6.94533 13.3335 8.00016C13.3319 9.41415 12.7695 10.7698 11.7696 11.7696C10.7698 12.7695 9.41416 13.3319 8.00017 13.3335Z"
        fill="#68696C"
      />
    </svg>
  )
}

export default ActivityClockIcon

import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Form, Image, message, Spin } from 'antd'
import { signature } from 'assets/imgs'
import { ClockIcon, CloseIcon, DownIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomSelect from 'component/Select/CustomSelect'
import TableImage from 'component/Table/TableImage'
import CommonTextArea from 'component/textArea/CommonTextArea'
import TimePickerField from 'component/TimePicker/TimePicker'
import { BUTTON_STRING, COMMON, TIME_ATTENDANCE_STRING, TIME_CARD_DATA } from 'constants/Constants'
import { TIME_AND_ATTENDANCE_STATUS } from 'constants/ConstantStatus'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { mediaUpload } from 'pages/Welcome/api'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData, ITimeCard } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { convertSecondsToHours, formatSecondsToHHMM } from 'utils/utils'
import { commonSelectValidation } from 'utils/Validations'

import ActivityFeed from '../ActivityFeed'
import { APPROVE_TIME_CARD, GET_TIME_CARD_DETAIL } from '../api'
import type { IDetailData, ITimeCardData } from '../types'

const floorData = [
  {
    value: 'C-HALL',
    label: 'C-HALL',
  },
]

const ViewTimeCard = ({
  setIsModalOpen,
  handleRejectClick,
  cardId,
  next,
  previous,
  index,
  lenght,
  isSignatureErrorShow,
  setIsSignatureErrorShow,
  isSignature,
  setIsSignature,
  signatureUrl,
  setSignatureUrl,
}: ITimeCard) => {
  const signPadRef = useRef<SignatureCanvas>(null)
  const [detailData, setDetailData] = useState<IDetailData>()
  const [error, setError] = useState({
    inTime: '',
    outTime: '',
    breakDuration: '',
    floor: '',
  })

  const signatureData: any = useAppSelector((state) => state?.auth?.auth)
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const breakTime = formatSecondsToHHMM(detailData?.break_duration as number)
    form.setFieldsValue({
      inTime: dayjs(detailData?.clock_in, 'HH:mm:ss'),
      outTime: dayjs(detailData?.clock_out, 'HH:mm:ss'),
      floor: 'Floor A',
      additionalDetail: detailData?.additional_details,
      breakDuration: dayjs(breakTime, 'HH:mm'),
    })
  }, [detailData])

  useEffect(() => {
    if (detailData?.approved_by ?? detailData?.rejected_by) {
      setIsSignature(true)
    } else {
      setIsSignature(false)
    }
  }, [detailData?.approved_by, detailData?.rejected_by])

  useEffect(() => {
    void handleGetTimeCardDetail(cardId)
  }, [cardId])

  useEffect(() => {
    if (isSignature && signPadRef.current) {
      const signaturePad = signPadRef.current

      setTimeout(() => {
        if (signaturePad) {
          signaturePad.fromDataURL(
            (detailData?.approved_by ?? detailData?.rejected_by)
              ? //  eslint-disable-next-line
                `${detailData?.base_url}${detailData?.authority_signature}`
              : signatureData?.signature
                ? `${signatureData?.profileDetails?.base_url as string}${signatureData?.signature as string}`
                : ''
          )
          signatureData?.signature || signatureUrl ? signaturePad.off() : signaturePad.on()
        }
      }, 100) // Adjust the timeout duration if necessary
    }
  }, [isSignature, signatureUrl, signatureData?.signature])

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = async (values: ITimeCardData) => {
    dispatch(setGlobalLoader(true))
    const minutesFromDate = Number(values.breakDuration.$d.getHours())
    const hoursFromDate = Number(values.breakDuration.$d.getMinutes())
    const timeInSeconds = minutesFromDate * 3600 + hoursFromDate * 60

    const payload = {
      clock_in: dayjs(values?.inTime, 'h:mm a').format('HH:mm:ss'),
      clock_out: dayjs(values?.outTime, 'h:mm a').format('HH:mm:ss'),
      break_duration: timeInSeconds,
      additional_detail: values?.additional_detail,
      authority_signature: signatureData?.signature ? signatureData?.signature : signatureUrl,
      floor: values?.floor,
    }

    if (!isSignature) {
      setIsSignatureErrorShow(!isSignature)
    } else {
      try {
        const response = await APPROVE_TIME_CARD(cardId, payload)
        if (response?.data?.statusCode === 1) {
          void message.success(response?.data?.message)
          void handleGetTimeCardDetail(cardId)
          // setIsModalOpen(false)
        } else {
          void message.error(response?.data?.message)
        }
      } catch (error: any) {
        void message.error(error)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetTimeCardDetail = async (cardId: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_TIME_CARD_DETAIL(cardId)
      if (response?.data?.statusCode === 1) {
        setDetailData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const dataURLtoBlob = (dataurl: any) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleSignatureUpload = async (dataUrl: string) => {
    if (typeof dataUrl !== 'string') {
      return
    }

    dispatch(setGlobalLoader(true))
    try {
      const formData = new FormData()
      const blob = dataURLtoBlob(dataUrl)
      formData.append('image', blob, 'signature.png')
      formData.append('folder', 'signature')
      const response = await mediaUpload(formData)
      if (response?.data?.statusCode === 1) {
        setSignatureUrl(response?.data?.data?.image)
        void message.success('Signature uploaded successfully')
      } else {
        void message.error('Failed to upload image. Please try again.')
      }
    } catch (error) {
      void message.error('Failed to upload image. Please try again.')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEndSignature = async () => {
    if (signPadRef.current) {
      // No need to clear the canvas here; just get the signature
      const signatureDataUrl = signPadRef.current.toDataURL()
      await handleSignatureUpload(signatureDataUrl)
    }
  }

  const timesheetImages = detailData?.time_sheet?.map((path) => `${detailData?.base_url}${path}`)

  return (
    <>
      <div className="flex flex-col flex-start self-stretch w-full time-card-container">
        <div className="flex py-4 px-6 justify-between items-center self-stretch border-b-stroke">
          <div className="flex items-center gap-6">
            <CustomButton isIcon svgIcon={<DownIcon className="left-arrow flex" />} type="default" className="header-btn" onClick={previous} disabled={index === 0} />
            <CustomButton isIcon svgIcon={<DownIcon className="right-arrow flex" />} type="default" className="header-btn" onClick={next} disabled={index === lenght - 1} />
          </div>
          <div className="flex p-2 flex-col justify-center items-center rounded-2 contact-close box-shadow ">
            <CloseIcon className="cursor-pointer" onClick={handleCancel} />
          </div>
        </div>
        <div className="timecard-detail-container flex justify-center w-full items-start ">
          <Form
            className="flex flex-col h-full justify-between w-full"
            form={form}
            onFinish={onFinish}
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setError)
            }}
          >
            <div className="detail-wrapper flex w-full p-8 flex-col items-start self-stretch rounded-3 ">
              <div className="shift-detail-wrapper mb-6 flex w-full justify-center items-start gap-2">
                <div className="shift-detail-box flex py-2 px-6 flex-col justify-center items-center gap-1 rounded-4 bg-neutral-50 w-full">
                  <p className="text-neutral-800 text-20 font-semi-bold">{detailData?.shift_id}</p>
                  <p className="text-neutral-600 para-p3 font-normal">{TIME_CARD_DATA.SHIFT_ID}</p>
                </div>
                <div className="shift-detail-box flex py-2 px-6 flex-col justify-center items-center gap-1 rounded-4 bg-neutral-50 w-full">
                  <p className="text-neutral-800 text-20 font-semi-bold">{detailData?.start_date ? dayjs(detailData?.start_date).format('DD/MM/YYYY') : '-'}</p>
                  <p className="text-neutral-600 para-p3 font-normal">{TIME_CARD_DATA.SHIFT_DATE}</p>
                </div>
                <div className="shift-detail-box flex py-2 px-6 flex-col justify-center items-center gap-1 rounded-4 bg-neutral-50 w-full">
                  {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
                  <TableImage
                    baseUrl={detailData?.base_url}
                    image={detailData?.profile_image}
                    name={(detailData?.first_name ?? '') + ' ' + (detailData?.last_name ?? '')}
                    email={detailData?.certificate_name}
                  />
                </div>
                <div className="shift-detail-box flex py-2 px-6 flex-col justify-center items-center gap-1 rounded-4 bg-neutral-50 w-full">
                  <p className="text-neutral-800 text-20 font-semi-bold">{detailData?.total_worked ? `${convertSecondsToHours(detailData?.total_worked)}` : ''}</p>
                  <p className="text-neutral-600 para-p3 font-normal text-center">{TIME_CARD_DATA.HARD_WORKED}</p>
                </div>
              </div>
              <div className="grid grid-col-2 gap-4 w-full">
                <div className="flex flex-col w-full">
                  <TimePickerField
                    disabled={!!detailData?.approved_by}
                    placeholder={COMMON.IN_TIME}
                    name="inTime"
                    icon={<ClockIcon />}
                    error={error.inTime}
                    rules={[
                      {
                        required: true,
                        message: `${VALIDATION_ERROR.SINGLE_SELECT} in time`,
                      },
                      {
                        validator: async (_: any, value: any) => {
                          const outTime = form.getFieldValue('outTime')
                          if (!value || !outTime || outTime.isAfter(value)) {
                            form.setFields([
                              { name: 'outTime', errors: [] },
                              { name: 'inTime', errors: [] },
                            ])
                            return
                          }
                          if (value.isSame(outTime)) {
                            throw new Error('In time and out time should not be the same')
                          }
                          throw new Error('In time should be earlier than out time')
                        },
                      },
                    ]}
                    onChange={() => {
                      void form.validateFields(['outTime'])
                    }}
                    form={form}
                    format="h:mm a"
                    value={getValue('inTime')}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <TimePickerField
                    disabled={!!detailData?.approved_by}
                    placeholder={COMMON.OUT_TIME}
                    name="outTime"
                    icon={<ClockIcon />}
                    error={error.outTime}
                    rules={[
                      {
                        required: true,
                        message: `${VALIDATION_ERROR.SINGLE_SELECT} out time`,
                      },
                      {
                        validator: async (_: any, value: any) => {
                          const inTime = form.getFieldValue('inTime')
                          if (!value || !inTime || value.isAfter(inTime)) {
                            form.setFields([
                              { name: 'inTime', errors: [] }, // Clear inTime error if conditions are met
                              { name: 'outTime', errors: [] }, // Clear outTime error if conditions are met
                            ])
                            return
                          }
                          if (value.isSame(inTime)) {
                            throw new Error('In time and out time should not be the same')
                          }
                          throw new Error('Out time should be later than in time')
                        },
                      },
                    ]}
                    onChange={() => {
                      void form.validateFields(['inTime'])
                    }}
                    form={form}
                    format="h:mm a"
                    value={getValue('outTime')}
                  />
                </div>
              </div>
              <div className="grid grid-col-2 gap-4 w-full">
                <div className="flex flex-col w-full">
                  <TimePickerField
                    disabled={!!detailData?.approved_by}
                    placeholder={COMMON.BREAK_DOWN_DUR}
                    name="breakDuration"
                    icon={<ClockIcon />}
                    error={error.breakDuration}
                    form={form}
                    format="HH:mm"
                    use12Hours={false}
                    rules={commonSelectValidation('break duration time')}
                    value={getValue('breakDuration')}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <CustomSelect disabled label={COMMON.FLOOR} options={floorData} name="floor" error={error.floor} rules={commonSelectValidation('floor')} />
                </div>
              </div>
              {detailData?.timecard_status === TIME_AND_ATTENDANCE_STATUS.APPROVED ? (
                <>
                  {detailData?.additional_details && (
                    <div className="flex flex-col items-start gap-2 mb-6 ">
                      <p className="text-neutral-500 para-p2 font-normal">{COMMON.ADDITIONAL_DETAIL}</p>
                      <p className="text-neutral-800 para-p1 font-medium">{detailData?.additional_details}</p>
                    </div>
                  )}
                  <p className="text-neutral-600 para-p2 font-normal mb-6">
                    {TIME_ATTENDANCE_STRING.APPROVED_ON} {dayjs(detailData?.approved_by?.timecard_approve_date).format('DD/MM/YYYY')} by {detailData?.approved_by?.name}
                  </p>
                </>
              ) : detailData?.timecard_status === TIME_AND_ATTENDANCE_STATUS.REJECTED ? (
                <>
                  <div className="flex flex-col items-start gap-2 mb-6 ">
                    <p className="text-neutral-500 para-p2 font-normal">{COMMON.REJECT_REASON}</p>
                    <p className="text-neutral-800 para-p1 font-medium">{detailData?.timecard_reject_reason}</p>
                  </div>

                  {detailData?.additional_details && (
                    <div className="flex flex-col items-start gap-2 mb-6 ">
                      <p className="text-neutral-500 para-p2 font-normal">{COMMON.ADDITIONAL_DETAIL}</p>
                      <p className="text-neutral-800 para-p1 font-medium">{detailData?.additional_details}</p>
                    </div>
                  )}
                  <p className="text-neutral-600 para-p2 font-normal mb-6">
                    {TIME_ATTENDANCE_STRING.REJECTED_ON} {dayjs(detailData?.rejected_by?.timecard_rejected_date).format('DD/MM/YYYY')} by {detailData?.rejected_by?.name}
                  </p>
                </>
              ) : (
                <CommonTextArea name="additional_detail" placeholder={COMMON.ADDITIONAL_DETAIL} rows={3} />
              )}
              <div className="flex w-full justify-between self-stretch providers-sign-wrapper">
                <Image.PreviewGroup items={timesheetImages}>
                  <div className="image-thumbnail-container">
                    <Image key={index} width={100} src={timesheetImages?.[0]} className="image-thumbnail rounded" />
                    {timesheetImages && timesheetImages?.length > 1 && <div className="overlay">+{timesheetImages?.length - 1}</div>}
                  </div>
                </Image.PreviewGroup>
                <div className="flex items-start gap-4 responsive">
                  <div className="signature-box-wrapper flex flex-col items-start gap-1">
                    <p className="text-neutral-400">{TIME_CARD_DATA.PROVIDER_SIGN}</p>
                    <div className="sign-img-wrapper flex flex-row justify-center items-center gap-4 self-stretch rounded-3 bg-neutral-50">
                      {detailData?.base_url ? (
                        <img src={`${detailData.base_url}${detailData.provider_signature}`} alt="provider-signature" className="signature-img" />
                      ) : (
                        <Spin size="small" />
                      )}
                    </div>
                  </div>
                  <div className="signature-box-wrapper flex flex-col items-start gap-1">
                    <p className="text-neutral-400">{TIME_CARD_DATA.AUTHOR_SIGN}</p>
                    <div
                      className={`sign-wrapper flex p-1 flex-col justify-center items-center gap-4 self-stretch rounded-3 ${(detailData?.approved_by ?? isSignature ?? detailData?.rejected_by) ? 'signature' : 'no-signature'}`}
                      onClick={() => {
                        setIsSignature(true)
                        setIsSignatureErrorShow(false)
                      }}
                    >
                      {(!detailData?.approved_by || !detailData?.rejected_by) && !isSignature ? (
                        <p className="text-secondary-600 para-p2  font-normal">{TIME_CARD_DATA.CLICK_TO_SIGN}</p>
                      ) : (
                        <SignatureCanvas
                          onEnd={handleEndSignature}
                          penColor="black"
                          canvasProps={{ height: '200px', width: '292px', className: 'time-card-signature w-full h-full' }}
                          ref={signPadRef}
                        />
                      )}
                    </div>
                    {isSignatureErrorShow && (!signatureData?.signature || !signatureUrl) && <p className="error-container">Please Add signature before prociding!</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="time-card-footer flex py-4 px-6 justify-end items-center gap-6 border-t-stroke">
              {detailData?.timecard_status === TIME_AND_ATTENDANCE_STATUS.APPROVED ? (
                // <CustomButton text={BUTTON_STRING.RATE_PROVIDER} type="default" className="rounded-3 w-160 h-49 hidden" onClick={handleRateClick} />
                <span className="h-49" />
              ) : detailData?.timecard_status === TIME_AND_ATTENDANCE_STATUS.REJECTED ? (
                <CustomButton text={BUTTON_STRING.APPROVE_NOW} type="primary" className="rounded-3 border-0 w-180 h-49" htmlType="submit" />
              ) : (
                <>
                  <CustomButton
                    text={BUTTON_STRING.REJECT}
                    type="default"
                    className="rounded-3 w-160 h-49"
                    onClick={() => {
                      handleRejectClick(index)
                    }}
                  />
                  <CustomButton text={BUTTON_STRING.SAVE_AND_APPROVE} type="primary" className="rounded-3 border-0 w-180 h-49" htmlType="submit" />
                </>
              )}
            </div>
          </Form>
          <ActivityFeed />
        </div>
      </div>
    </>
  )
}

export default ViewTimeCard

import React from 'react'

const LocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M18 4.97991C16.4087 3.38861 14.2504 2.49463 12 2.49463C9.74957 2.49463 7.5913 3.38861 6.00001 4.97991C4.40871 6.57121 3.51472 8.72947 3.51472 10.9799C3.51472 13.2303 4.40871 15.3886 6.00001 16.9799L11.27 22.2599C11.363 22.3536 11.4736 22.428 11.5954 22.4788C11.7173 22.5296 11.848 22.5557 11.98 22.5557C12.112 22.5557 12.2427 22.5296 12.3646 22.4788C12.4864 22.428 12.597 22.3536 12.69 22.2599L18 16.9299C19.5847 15.3452 20.4749 13.196 20.4749 10.9549C20.4749 8.71386 19.5847 6.56459 18 4.97991ZM16.57 15.4999L12 20.0899L7.43001 15.4999C6.52715 14.5962 5.91248 13.4452 5.6637 12.1922C5.41492 10.9392 5.54319 9.64066 6.03231 8.46059C6.52143 7.28052 7.34942 6.27196 8.41164 5.56239C9.47385 4.85281 10.7226 4.47409 12 4.47409C13.2774 4.47409 14.5262 4.85281 15.5884 5.56239C16.6506 6.27196 17.4786 7.28052 17.9677 8.46059C18.4568 9.64066 18.5851 10.9392 18.3363 12.1922C18.0875 13.4452 17.4729 14.5962 16.57 15.4999ZM9.00001 7.90991C8.19272 8.71968 7.7394 9.81648 7.7394 10.9599C7.7394 12.1033 8.19272 13.2001 9.00001 14.0099C9.59977 14.6107 10.3636 15.021 11.1957 15.1893C12.0277 15.3576 12.891 15.2765 13.6771 14.9561C14.4632 14.6356 15.1372 14.0902 15.6145 13.3882C16.0918 12.6861 16.3511 11.8588 16.36 11.0099C16.3645 10.4431 16.2553 9.88117 16.0389 9.3573C15.8225 8.83343 15.5032 8.35827 15.1 7.95991C14.7037 7.55449 14.2311 7.23145 13.7094 7.00938C13.1878 6.7873 12.6273 6.67059 12.0604 6.66594C11.4935 6.66129 10.9312 6.76881 10.406 6.9823C9.88075 7.19579 9.40293 7.51104 9.00001 7.90991ZM13.69 12.5899C13.311 12.9747 12.8102 13.2158 12.2731 13.2722C11.736 13.3285 11.1961 13.1966 10.7455 12.8988C10.295 12.6011 9.9618 12.1562 9.80301 11.64C9.64422 11.1238 9.66966 10.5685 9.87496 10.0691C10.0803 9.56958 10.4527 9.15693 10.9286 8.90165C11.4045 8.64637 11.9543 8.56432 12.4839 8.66953C13.0136 8.77474 13.4903 9.06067 13.8325 9.47844C14.1747 9.89621 14.3612 10.4199 14.36 10.9599C14.3455 11.5772 14.0865 12.1634 13.64 12.5899H13.69Z"
        fill="#919195"
      />
    </svg>
  )
}

export default LocationIcon

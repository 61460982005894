import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { DownIcon, ForgotPasswordIcon, GmailLogo, LogoIcon, OutlookLogo } from 'assets/svgs'
import { COMMON, LOGIN } from 'constants/Constants'

import './login.scss'
import '../SignUp/SignUp.scss'

const ForgotPassword = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/login')
  }
  return (
    <div className="bg-neutral-50 justify-start h-screen relative">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={handleBackClick}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="bg-neutral-50 mx-auto max-w-400 w-full flex flex-col gap-10 justify-center items-center pt-140">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <div className="flex flex-col items-center gap-1">
            <p className="desktop-heading-h3 font-medium">{COMMON.CHECK_EMAIL}</p>
            <p className="text-neutral-500 text-center para-p2">
              {LOGIN.CHECK_MAIL_SUB}
              <span>{email}</span>
            </p>
          </div>
        </div>

        <ForgotPasswordIcon />

        <div className="flex gap-7 w-full">
          <Link to="https://mail.google.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <GmailLogo />
              </span>
              {COMMON.OPEN_MAIL}
            </Button>
          </Link>
          <Link to="https://outlook.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <OutlookLogo />
              </span>
              {COMMON.OPEN_OUTLOOK}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

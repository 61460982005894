import React, { useState } from 'react'
import { Button } from 'antd'
import { DownIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import ChangePwdForm from 'component/ChangePassword/ChangePassword'
import CustomModal from 'component/CustomModal/CustomModal'
import { BUTTON_STRING, COMMON, FACILITY_USER_PROFILE, PRIVACY_POLICY_DATA, TERM_CONDITION_DATA } from 'constants/Constants'
import { type IProfileData } from 'types'

const ManageAccount = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedProfileData, setUpdatedProfileData] = useState<IProfileData>()
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false)
  const [showTermsCondition, setShowTermsCondision] = useState<boolean>(false)

  const hideModal = () => {
    setIsPasswordModalOpen(false)
  }
  const showModal = () => {
    setIsPasswordModalOpen(true)
  }

  const handlePrivacyPolicyClick = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy)
  }
  const handleTermsConditionClick = () => {
    setShowTermsCondision(!showTermsCondition)
  }

  return (
    <div className="w-full px-5 flex justify-center">
      {!(showPrivacyPolicy || showTermsCondition) && (
        <div className="flex w-full flex-col items-start gap-6 max-w-800">
          <p className="text-neutral-800 desktop-heading-h3 font-medium w-full">{FACILITY_USER_PROFILE.MANAGE_ACCOUNT}</p>
          <div className="flex flex-col items-start gap-4 self-stretch">
            <div className="cursor-pointer flex justify-between items-center self-stretch">
              <div className="flex flex-col items-start gap-1">
                <p className="text-neutral-800 text-20 font-medium">{COMMON.PASSWORD}</p>
                <p className="text-neutral-500 para-p1  font-normal">{FACILITY_USER_PROFILE.PASSWORD_SUB}</p>
              </div>
              <CustomButton type="default" text={BUTTON_STRING.CHANGE_PASS} onClick={showModal} className="h-49 w-185 para-p2 " />
            </div>
            <div className="max-w-800 w-full bg-stroke h-1" />
            <div className="cursor-pointer flex justify-between items-center self-stretch" onClick={handlePrivacyPolicyClick}>
              <div className="flex flex-col items-start gap-1">
                <p className="text-neutral-800 text-20 font-medium">{FACILITY_USER_PROFILE.PRIVACY_POLI}</p>
                <p className="text-neutral-500 para-p1  font-normal">{FACILITY_USER_PROFILE.PRI_POL_SUB}</p>
              </div>
              <DownIcon className="down-icon" />
            </div>
            <div className="max-w-800 w-full bg-stroke h-1" />
            <div className="cursor-pointer flex justify-between items-center self-stretch" onClick={handleTermsConditionClick}>
              <div className="flex flex-col items-start gap-1">
                <p className="text-neutral-800 text-20 font-medium">{FACILITY_USER_PROFILE.TERM_AND_COND}</p>
                <p className="text-neutral-500 para-p1  font-normal">{FACILITY_USER_PROFILE.TERM_AND_COND_SUB}</p>
              </div>
              <DownIcon className="down-icon" />
            </div>
          </div>
        </div>
      )}
      {isPasswordModalOpen && (
        <CustomModal
          open={isPasswordModalOpen}
          onCancel={hideModal}
          title={FACILITY_USER_PROFILE.CHANGE_PASSWORD}
          showCancelBtn="none"
          footer={null}
          content={<ChangePwdForm setIsModalOpen={setIsPasswordModalOpen} isModalOpen={isPasswordModalOpen} email={updatedProfileData?.email ?? ''} />}
        />
      )}
      {(showPrivacyPolicy || showTermsCondition) && (
        <div className=" flex flex-col w-full items-center gap-10 flex-grow self-stretch">
          {(showPrivacyPolicy ? PRIVACY_POLICY_DATA : TERM_CONDITION_DATA).map((data) => {
            return (
              <>
                <div className="relative flex  justify-center w-full rounded-9 head-wrapper" key={data.text}>
                  <div className="flex flex-col  w-full items-center justify-center gap-1">
                    <p className="text-white font-bold head-text ">{showPrivacyPolicy ? FACILITY_USER_PROFILE.PRIVACY_POLI : FACILITY_USER_PROFILE.TERM_AND_COND}</p>
                    <p className="text-neutral-200 para-p3 font-normal">Last Updated : December 28, 2023</p>
                    <Button
                      icon={<DownIcon className="back-icon" />}
                      onClick={showPrivacyPolicy ? handlePrivacyPolicyClick : handleTermsConditionClick}
                      className="back-btn absolute justify-center flex items-center self-stretch h-49 rounded-3 border-0 text-white para-p2 font-medium "
                    >
                      {BUTTON_STRING.BACK}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col px-10 items-start gap-9 ">
                  <p className="text-neutral-600  para-p1 font-normal max-w-800">{data.text}</p>
                  {data.data.map((item) => {
                    return (
                      <div className="flex flex-col items-start gap-1 self-stretch max-w-800" key={item.id}>
                        <p className="text-neutral-800 font-medium mobile-heading-h4">{item.title}</p>
                        <p className="text-neutral-600  para-p1 font-normal">{item.text}</p>
                      </div>
                    )
                  })}
                </div>
              </>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ManageAccount

import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { AwardIcon, ClockIcon, CloseIcon, EditPenIcon, FacilityIcon, RefreshIcon, StethoscopeIcon } from 'assets/svgs'
import { ScheduleIcon } from 'assets/svgs/sidebar/SidebarMenuIcons'
import CustomButton from 'component/Button/CustomButton'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import CustomSelect from 'component/Select/CustomSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import TimePickerField from 'component/TimePicker/TimePicker'
import { BUTTON_STRING, COMMON, SHIFTS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { EDIT_POSTED_SHIFT } from 'pages/Shifts/api'
import type { IShiftDetailsTab, IUpdateShift } from 'pages/Shifts/types'
import { GET_CERTIFICATE, GET_FACILITY, GET_SPECIALITY } from 'service/api/Common'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFacility, IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonSelectValidation } from 'utils/Validations'

import ActivityFeed from '../ActivityFeed'

const ShiftDetailTab = ({ data, isEdit, setIsCreateShiftModalOpen, setIsEdit, status, isActivity, setIsCancelModal, setIsDetailShiftOpen }: IShiftDetailsTab) => {
  const [facilityData, setFacilityData] = useState<IFacility[]>([])
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [error, setError] = useState({
    facility: '',
    date: '',
    licence: '',
    specialty: '',
    startTime: '',
    endTime: '',
    shiftTime: '',
    repeat_on: '',
    end_date: '',
  })
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      facility: data?.facility?.id,
      certificate: data?.certificate?.id,
      speciality: data?.speciality?.id,
      start_date: dayjs(data?.start_date),
      start_time: dayjs(data?.start_time, 'HH:mm:ss'),
      end_time: dayjs(data?.end_time, 'HH:mm:ss'),
      description: data?.description,
      floor: 'Floor A',
    })
  }, [data])

  useEffect(() => {
    void getFacility()
    void getCertificate()
    void getSpeciality()
  }, [])

  const getFacility = async () => {
    try {
      const response = await GET_FACILITY()
      if (response?.status === 200) {
        setFacilityData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getCertificate = async () => {
    try {
      const response = await GET_CERTIFICATE()
      if (response?.status === 200) {
        setCertificateData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getSpeciality = async () => {
    try {
      const response = await GET_SPECIALITY()
      if (response?.status === 200) {
        setSpecialityData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const onFinish = async (values: IUpdateShift) => {
    dispatch(setGlobalLoader(true))
    try {
      const payload = {
        facility: values?.facility,
        certificate: values?.certificate,
        speciality: values?.speciality,
        follower: data?.follower?.id,
        start_time: dayjs(values?.start_time).format('HH:mm:ss'),
        end_time: dayjs(values?.end_time).format('HH:mm:ss'),
        description: values?.description,
        start_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(values?.end_date).format('YYYY-MM-DD'),
      }
      const response = await EDIT_POSTED_SHIFT(data?.id, payload)
      if (response?.data?.statusCode === 1) {
        setIsEdit(false)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!isEdit) {
      setIsEdit(true)
    }
  }

  const handleRepostReopen = () => {
    setIsDetailShiftOpen(false)
    setIsCreateShiftModalOpen(true)
  }

  const handleReopen = () => {
    console.log('first')
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
      className="h-full"
    >
      <div className="wrapper-details-btn">
        <div className="detail-container flex item-start justify-center flex-1 self-stretch w-full h-full overflow-x-scroll  ">
          <div className="tracking-container flex p-6 flex-col items-start gap-4 flex-1 overflow-y-scroll self-stretch">
            <div className="form-container flex flex-col items-start self-stretch">
              <CustomSelect
                disabled={!isEdit}
                prefixIcon={<FacilityIcon />}
                error={error.facility}
                label={COMMON.FACILITY_ACC}
                isSearch={facilityData?.length >= 10}
                searchPlaceholder={COMMON.SEARCH_FACILITY}
                options={facilityData?.map((item: { name: string; id: string }) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                name="facility"
                rules={commonSelectValidation('facility access')}
                value={getValue('facility')}
              />
              <CustomDatePickerField
                disabled={!isEdit}
                form={form}
                name="start_date"
                placeholder={COMMON.DATE}
                icon={<ScheduleIcon />}
                error={error.date}
                rules={commonSelectValidation('date')}
                mainClass="w-full"
                value={getValue('start_date')}
              />
              <div className={`grid grid-cols-2 gap-4 w-full`}>
                <CustomSelect
                  disabled={!isEdit}
                  label={COMMON.LICENSE}
                  options={certificateData?.map((item: { name: string; id: string }) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  name="certificate"
                  isSearch
                  searchPlaceholder={COMMON.SEARCH_LICENCE}
                  prefixIcon={<AwardIcon />}
                  rules={commonSelectValidation('licence')}
                  error={error.licence}
                  value={getValue('certificate')}
                />
                <CustomSelect
                  disabled={!isEdit}
                  label={COMMON.SPECIALITY}
                  options={specialityData?.map((item: { name: string; id: string }) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  name="speciality"
                  isSearch
                  searchPlaceholder={COMMON.SEARCH_SPECIALITY}
                  prefixIcon={<StethoscopeIcon />}
                  rules={commonSelectValidation('specialty')}
                  error={error.specialty}
                  value={getValue('speciality')}
                />
              </div>
              <div className={`grid grid-cols-2 gap-4 w-full`}>
                <TimePickerField
                  disabled={!isEdit}
                  form={form}
                  name="start_time"
                  error={error.startTime}
                  icon={<ClockIcon />}
                  placeholder={COMMON.START_TIME}
                  format="h:mm a"
                  rules={commonSelectValidation('start time')}
                  value={getValue('start_time')}
                />
                <TimePickerField
                  disabled={!isEdit}
                  form={form}
                  name="end_time"
                  error={error.endTime}
                  icon={<ClockIcon />}
                  placeholder={COMMON.END_TIME}
                  format="h:mm a"
                  rules={commonSelectValidation('end time')}
                  value={getValue('end_time')}
                />
              </div>
              <CustomSelect
                disabled={!isEdit}
                label={COMMON.FLOOR}
                options={[
                  {
                    label: '1st',
                    value: '1st',
                  },
                ]}
                name="floor"
                value={getValue('floor')}
              />
              <div className="flex flex-col items-start gap-2 self-stretch">
                <div className="w-full">
                  {getValue('description') || isEdit ? (
                    <CommonTextArea disabled={!isEdit} name="description" placeholder={SHIFTS.SHIFT_DESC} rows={4} defaultValue={getValue('description')} />
                  ) : (
                    <CommonTextArea disabled={!isEdit} name="des" placeholder={SHIFTS.SHIFT_DESC} rows={4} defaultValue="No Shift Description found" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {isActivity && <ActivityFeed shiftStatus={status} />}
        </div>
        <div
          className={`${status === SHIFT_STATUS.UNSUBMITTED || status === SHIFT_STATUS.ON_GOING || status === SHIFT_STATUS.VOID ? '' : 'flex py-4 px-6 justify-end items-center self-stretch border-t-stroke'}`}
        >
          <div className="flex gap-4">
            {status === SHIFT_STATUS.OPEN || status === SHIFT_STATUS.SCHEDULE ? (
              <>
                {!isEdit ? (
                  <>
                    <CustomButton
                      isIcon
                      onClick={() => {
                        setIsCancelModal(true)
                      }}
                      svgIcon={<CloseIcon fill="#D1293D" />}
                      isDanger={true}
                      className="w-160 user-icon danger-btn"
                      type="default"
                      text={BUTTON_STRING.CANCEL_SHIFT}
                    />
                    <CustomButton
                      isIcon
                      svgIcon={<EditPenIcon fill="#fff" />}
                      text={isEdit ? BUTTON_STRING.SAVE : BUTTON_STRING.EDIT}
                      type="primary"
                      htmlType="button"
                      className="w-160"
                      onClick={handleEditClick}
                    />
                  </>
                ) : (
                  <>
                    <CustomButton
                      isIcon
                      onClick={() => {
                        setIsEdit(false)
                      }}
                      type="default"
                      text={BUTTON_STRING.CANCEL}
                      className="w-160 user-icon"
                    />
                    <CustomButton isIcon text={BUTTON_STRING.SAVE} htmlType="submit" type="primary" className="w-160" />
                  </>
                )}
              </>
            ) : status === SHIFT_STATUS.CANCELLED || status === SHIFT_STATUS.COMPLETED ? (
              <CustomButton
                isIcon
                svgIcon={<RefreshIcon className="svg" />}
                type="default"
                text={status === SHIFT_STATUS.CANCELLED ? BUTTON_STRING.RE_OPEN : BUTTON_STRING.RE_POST}
                className="w-160 user-icon "
                onClick={status === SHIFT_STATUS.CANCELLED ? handleReopen : handleRepostReopen}
              />
            ) : status === SHIFT_STATUS.UNSUBMITTED || status === SHIFT_STATUS.ON_GOING || status === SHIFT_STATUS.VOID ? null : (
              <CustomButton
                onClick={() => {
                  setIsCancelModal(true)
                }}
                isIcon
                svgIcon={<CloseIcon />}
                type="default"
                text={BUTTON_STRING.CANCEL_SHIFT}
                className="w-160"
              />
            )}
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ShiftDetailTab

import React, { type ChangeEventHandler, type MouseEvent, useEffect, useState } from 'react'
import { Drawer, Input, Pagination, Select, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DownIcon, FilterIcon, SearchIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import { BUTTON_STRING, COMMON } from 'constants/Constants'

import './Table.scss'

interface ISelectLabel {
  label: string
  value: string
}

interface CustomTableProps<T extends Record<string, unknown>> {
  column: ColumnsType<T>
  data: T[] | undefined
  total?: number
  current?: number
  limit?: number
  search?: string
  onChangePage?: (page: number, pageSize?: number) => void
  setModalOpen?: (record: any) => void
  onChange?: (pagination: any, filters: any, sorter: any) => void
  name?: string
  className?: string
  pagination?: boolean
  rowSelected?: any
  isSearch?: boolean
  filterOption?: any
  onSearchChange?: ChangeEventHandler<HTMLInputElement>
  searchPlaceholder?: string
  tableButton?: any
  isWithoutTable?: boolean
  onSearch?: (value: string) => void
  isFilter?: boolean
  isTab?: boolean
  option?: ISelectLabel[]
  setSelectedValue?: any
  selectedValue?: string | any
  onDrawerOpenChange?: (isOpen: boolean) => void
  showFilterDrawer?: boolean
  filterSelected?: Record<string, string | number | boolean | undefined | null | string[]>
  handleReset?: () => void
}

interface IDataRow {
  id: string | number
}

const { Search } = Input

const CustomTable = <T extends Record<string, unknown>>({
  name,
  column,
  data,
  total,
  current,
  limit,
  onChangePage,
  onChange,
  setModalOpen,
  rowSelected,
  className,
  onSearch,
  pagination = true,
  isSearch = false,
  onSearchChange,
  searchPlaceholder,
  filterOption,
  tableButton,
  isWithoutTable = false,
  isTab = false,
  option,
  isFilter = false,
  setSelectedValue,
  selectedValue,
  onDrawerOpenChange,
  showFilterDrawer,
  filterSelected,
  handleReset,
}: CustomTableProps<T>): JSX.Element => {
  const columns = column as any[]
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isTab && option && option.length > 0) {
      setSelectedValue(option[0].value)
    }
  }, [])

  useEffect(() => {
    if (onDrawerOpenChange) {
      onDrawerOpenChange(open)
    }
  }, [open])

  useEffect(() => {
    const maskElement = document.getElementsByClassName('ant-drawer-mask')[0]
    const handleMaskClick = () => {
      handleReset?.()
    }
    if (maskElement) {
      maskElement.addEventListener('click', handleMaskClick)
    }
    return () => {
      if (maskElement) {
        maskElement.removeEventListener('click', handleMaskClick)
      }
    }
  }, [open, handleReset])

  useEffect(() => {
    if (showFilterDrawer === false) {
      setOpen(false)
    }
  }, [showFilterDrawer])

  const handleRowClick = (_record: any, event: MouseEvent<HTMLTableRowElement>) => {
    if ((event.target && (event.target as HTMLElement).closest('.action-cell')) ?? (event.target as HTMLElement).closest('.flag-cell')) {
      return
    }
    if (setModalOpen) setModalOpen(_record)
  }

  const onRowProps = (record: any) => ({
    onClick: (event: MouseEvent<HTMLTableRowElement>) => {
      handleRowClick(record, event)
    },
  })

  // Modify the last column to prevent row selection when clicked
  const modifiedColumns = columns.map((col, index) => {
    if (index === columns.length - 1 || index === columns.length - 2) {
      // Assuming the last column is the "action" column
      return {
        ...col,
        onCell: (record: IDataRow) => ({
          onClick: (event: MouseEvent<HTMLTableRowElement>) => {
            // Prevent row selection event when the last column is clicked
            event.stopPropagation()
          },
        }),
      }
    }
    return col
  })

  const startRecord = !data?.length ? 0 : ((current ?? 1) - 1) * (limit ?? 0) + 1
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endRecord = Math.min(current! * limit!, total!)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleSelectChange = (value: { label: string; value: string }) => {
    setSelectedValue(value?.value)
  }

  const handlePageChange = (page: number, pageSize?: number) => {
    onChangePage?.(page, pageSize)
    const tableWrapper = document.querySelector('.wrapper-content')
    if (tableWrapper) {
      tableWrapper.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const handlePageSizeChange = (pageSize?: number) => {
    onChangePage?.(current ?? 1, pageSize ?? 10)
  }

  const hasValue = filterSelected
    ? Object.values(filterSelected).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0 // Check if the array is not empty
        }
        return value !== undefined && value !== null && value !== '' // Check for other types
      })
    : false

  return (
    <div className={`common-table-wrapper ${isSearch ? 'border-stroke' : ''} ${isWithoutTable ? 'border-0 ' : ''}`}>
      {isSearch && (
        <div className={`filter-wrapper ${isWithoutTable ? 'without-table border-0 ' : ''}`}>
          <div className="search-container">
            <Search
              placeholder={searchPlaceholder}
              prefix={<SearchIcon />}
              onSearch={onSearch}
              allowClear
              className="custom-searchbar table-searchBar-width"
              onChange={onSearchChange}
            />
            {isFilter && (
              <>
                <CustomButton
                  isIcon
                  svgIcon={<FilterIcon hideRedDot={hasValue} />}
                  onClick={showDrawer}
                  type="default"
                  className="flex flex-col justify-center items-center self-stretch rounded-3 bg-neutral-50 box-shadow filter-btn"
                />
                <Drawer title={COMMON.FILTER} onClose={onClose} open={open} className="table-filter-drawer">
                  {filterOption}
                </Drawer>
              </>
            )}
            {isTab && <Select labelInValue onChange={handleSelectChange} options={option} className="select-table" suffixIcon={<DownIcon />} value={selectedValue} />}
          </div>
          {tableButton}
        </div>
      )}
      <Table
        onChange={onChange}
        columns={modifiedColumns}
        dataSource={data}
        className={`common-table ${!isSearch ? 'non-filter-table' : ''} ${isWithoutTable ? 'non-filter-table' : ''} ${className ?? ''}`}
        rowKey="id"
        onRow={onRowProps}
        rowSelection={rowSelected}
        pagination={false} // Disable default pagination
        scroll={{ x: 'min-content' }}
        footer={() =>
          pagination ? (
            <div className="table-footer ">
              <Select
                options={[
                  { value: 10, label: '10 / page' },
                  { value: 20, label: '20 / page' },
                  { value: 50, label: '50 / page' },
                  { value: 100, label: '100 / page' },
                ]}
                value={limit}
                className="page-size-select"
                onChange={(value) => {
                  handlePageSizeChange(value)
                }}
              />
              <div className="para-p3 font-medium">{`Showing ${startRecord} - ${endRecord} of ${total as number} ${name as string}`}</div>
              <Pagination
                total={total}
                showQuickJumper={false}
                current={current}
                showSizeChanger={false}
                pageSize={limit}
                onChange={handlePageChange}
                itemRender={(
                  page: number | undefined,
                  type: string,
                  originalElement:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined
                ) => {
                  if (type === 'prev') {
                    return <p className="bg-none h-full w-full border-0 para-p3 font-medium flex justify-center items-center">{BUTTON_STRING.PREVIOUS}</p>
                  }
                  if (type === 'next') {
                    return <p className="bg-none h-full w-full border-0 para-p3 font-medium flex justify-center items-center">{BUTTON_STRING.NEXT}</p>
                  }
                  return null
                }}
              />
            </div>
          ) : null
        }
      />
    </div>
  )
}

export default CustomTable

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import { HeaderBack, NotificationIcon, SupportRequest } from 'assets/svgs'
import Breadcrumbs from 'component/Breadcrumb'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectCommon } from 'store/slice/CommonSlice'
import { type ILayoutProps } from 'types'

import NotificationDrawer from './NotificationDrawer'
import Sidebar from './Sidebar'

import './Layout.scss'

const { Header, Sider, Content } = Layout

const AppLayout = ({ children }: ILayoutProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const common = useAppSelector(selectCommon)

  const handleBack = () => {
    navigate(-1)
  }

  const handleNotificationDrawerOpen = () => {
    setIsNotificationDrawerOpen(true)
  }

  return (
    <>
      <Layout className="wrapper-layout">
        <Sider trigger={null} collapsible collapsed={collapsed} className={`${collapsed ? 'collapsed-sidebar' : 'sider-layout'}  bg-white`}>
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
        <Layout className="ml-6">
          <Header className="header-wrapper flex justify-between items-center">
            <div className="header-content-div flex items-center gap-6">
              {common?.isBack && (
                <div onClick={handleBack} className="div-handle-back">
                  <HeaderBack />
                </div>
              )}
              <p className="desktop-heading-h6 text-neutral-800 font-semi-bold">{common?.pageTitle}</p>
            </div>

            <div className="flex gap-6">
              <div className="header-icon">
                <SupportRequest />
              </div>
              <div className="header-icon" onClick={handleNotificationDrawerOpen}>
                <NotificationIcon />
              </div>
            </div>
          </Header>
          <Content>
            {location?.pathname !== '/' && <Breadcrumbs />}
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            <div className={`wrapper-content ${location?.pathname === '/' && 'mt-6'}`}>{children}</div>
          </Content>
        </Layout>
      </Layout>

      {isNotificationDrawerOpen && <NotificationDrawer isNotificationDrawerOpen={isNotificationDrawerOpen} setIsNotificationDrawerOpen={setIsNotificationDrawerOpen} />}
    </>
  )
}

export default AppLayout

import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Image, Input, message, Select } from 'antd'
import type { SearchProps } from 'antd/es/input'
import { profile } from 'assets/imgs/index'
import { DownIcon, SearchIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import { BUTTON_STRING, SHIFTS } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'

import { GET_ALL_PROVIDER } from '../api'
import type { IInviteProviderProps, IProviderInvite } from '../types'

const preferredOption = [
  {
    id: 1,
    label: 'All',
    value: 'all',
  },
  {
    id: 2,
    label: 'AI',
    value: 'ai',
  },
  {
    id: 3,
    label: 'Preferred',
    value: 'preferred',
  },
  {
    id: 4,
    label: 'Past Workers',
    value: 'past',
  },
]

const InviteProvider = ({ setIsModalOpen, selectedCheckboxes, setSelectedCheckboxes, certificate, speciality, isEditInvitedData, setIsEditInvitedData }: IInviteProviderProps) => {
  const [preferredTag, setPreferredTag] = useState<any>(preferredOption[0].value)
  const [selectedProviders, setSelectedProviders] = useState<IProviderInvite[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [providerList, setProviderList] = useState<IProviderInvite[]>([])

  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const dispatch = useAppDispatch()

  useEffect(() => {
    void handleGetAllProviderList(searchValue)
  }, [searchValue, preferredTag, certificate, speciality])

  useEffect(() => {
    setSelectedProviders(selectedCheckboxes)
  }, [selectedCheckboxes])

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchValue(value.trim().toLowerCase())
  }

  const handlePreferredChange = (selectedValue: IProviderInvite[]) => {
    setPreferredTag(selectedValue)
    setProviderList([])
    setSelectedProviders([])
  }

  const handleChange = (value: string) => {
    if (value?.length === 0) {
      setSearchValue(value)
    }
  }

  const handleCheckboxChange = (checked: boolean, item: any) => {
    if (checked) {
      setSelectedProviders((prev: any) => [...prev, item])
    } else {
      setSelectedProviders((prev: any) => prev.filter((i: any) => i.id !== item.id))
    }
  }

  const onFinish = (values: IProviderInvite) => {
    const payload: any = {
      selectedProviders: selectedProviders?.map((item: any) => ({
        id: item?.id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        profile_image: item?.profile_image,
        base_url: item?.base_url,
      })),
    }

    setSelectedCheckboxes(payload?.selectedProviders)

    setIsModalOpen(false)
  }

  const handleGetAllProviderList = async (search: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_ALL_PROVIDER(preferredTag, facilityId, search, certificate, speciality)
      if (response?.data?.statusCode === 1) {
        setProviderList(response?.data?.data)
      } else {
        void message.error(response?.data?.message ?? VALIDATION_ERROR.SOMETHING)
      }
    } catch (error: any) {
      console.error(error ?? VALIDATION_ERROR.SOMETHING)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSearch(e.currentTarget.value)
    }
  }

  const handleSelectAll = () => {
    if (selectedProviders.length === providerList.length) {
      setSelectedProviders([])
    } else if (selectedProviders.length === 0) {
      setSelectedProviders(providerList)
    } else {
      setSelectedProviders(providerList)
    }
  }

  return (
    <div className="flex flex-col items-center gap-4 pb-8">
      <Form className="flex px-4 flex-col items-center gap-4 self-stretch" onFinish={onFinish}>
        <div className="inv-content-wrapper wrapper-form-height flex flex-col items-start gap-8 self-stretch rounded-3">
          <div className="inv-search-box-wrapper flex items-start gap-6 self-stretch">
            <Input.Search
              placeholder={SHIFTS.SEARCH_PRO}
              prefix={<SearchIcon />}
              allowClear
              onSearch={onSearch}
              onChange={(e) => {
                handleChange(e.target.value)
              }}
              onKeyDown={handleKeyDown}
              className="custom-searchBar searchBar-width p-0"
            />
            <Select
              options={preferredOption}
              defaultValue={preferredTag}
              suffixIcon={<DownIcon />}
              className="preferred-dropdown rounded-3 flex border-0 items-center self-stretch"
              onChange={handlePreferredChange}
            />
          </div>
          <div className="flex flex-col items-start gap-6 self-stretch">
            <div className="select-btn-wrapper flex justify-between items-center self-stretch">
              <p className="text-neutral-800 para-p2 font-normal ">
                {selectedProviders?.length} {SHIFTS.SELECTED}
              </p>
              <span className="cursor-pointer" onClick={handleSelectAll}>
                <p className="text-primary-500 para-p2 font-medium">{SHIFTS.SELECT_ALL}</p>
              </span>
            </div>
            <span className="w-full overflow-y-scroll flex flex-col items-start gap-6 self-stretch max-h-300px h-full min-h-300">
              {providerList?.map((item: any) => (
                <div className="select-nurses-container flex justify-between items-center self-stretch" key={item.id}>
                  <div className="select-content-wrapper flex justify-center items-center gap-4 ">
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <Form.Item name={`checkbox-${item?.id}`}>
                      <Checkbox
                        onChange={(e) => {
                          handleCheckboxChange(e.target.checked, item)
                        }}
                        checked={selectedProviders.some((provider) => provider.id === item.id)}
                      />
                    </Form.Item>
                    <div className="select-text-wrapper flex items-center gap-3">
                      <Image
                        src={item?.profile_image && item?.base_url ? `${item?.base_url as string}${item?.profile_image as string}` : profile}
                        alt="profile"
                        className="select-profile-img object-cover rounded-50 "
                      />

                      <div className="text-wrapper flex flex-col items-start gap-1">
                        <p className="head-text text-neutral-800 para-p2 font-normal">
                          {item.first_name} {item.last_name}
                        </p>
                        <p className="sub-text text-neutral-500 para-p4 ">{item?.certificate.name}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="badge-wrapper flex items-center rounded bg-warning-50">
                    <GoldStar />
                    <p className="text-warning-700 para-p3">4.9</p>
                  </div> */}
                </div>
              ))}
            </span>
          </div>
        </div>
        <div className="flex gap-4 w-full justify-end items-end">
          <CustomButton
            type="default"
            text={BUTTON_STRING.CANCEL}
            className="w-160 h-49"
            onClick={() => {
              setIsModalOpen(false)
              if (!isEditInvitedData) {
                setSelectedCheckboxes([])
                setIsEditInvitedData(false)
              }

              // Reset other states if needed
            }}
          />
          <CustomButton disabled={selectedProviders?.length === 0} text={BUTTON_STRING.INVITE} type="primary" htmlType="submit" className="w-160 h-49" />
        </div>
      </Form>
    </div>
  )
}

export default InviteProvider

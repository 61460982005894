import React, { useEffect, useState } from 'react'
import { Drawer, message } from 'antd'
import { CalenderCheckIcon, CalenderCrossIcon, DoubleCheckIcon, NotificationUnreadIcon } from 'assets/svgs'
import { SettingsIconColor } from 'assets/svgs/sidebar/SidebarMenuIcons'
import { LAYOUT_DATA } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useAppDispatch } from 'hooks/reduxHooks'
import { GET_NOTIFICATION } from 'service/api/Common'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { INotificationDrawer } from 'types'

import './Layout.scss'

dayjs.extend(relativeTime)

const NotificationDrawer = ({ isNotificationDrawerOpen, setIsNotificationDrawerOpen }: INotificationDrawer) => {
  const [notificationData, setNotificationData] = useState([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    void handleGetAllFacility()
  }, [])

  const onClose = () => {
    setIsNotificationDrawerOpen(false)
  }

  const handleGetAllFacility = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_NOTIFICATION()
      if (response?.data?.statusCode === 1) {
        setNotificationData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Drawer placement="right" closable={false} onClose={onClose} open={isNotificationDrawerOpen} className="notification-drawer" rootClassName="notification-drawer-root">
      <div className="head-notifications border-b-stroke flex">
        <div className="flex w-full items-center justify-between ">
          <p className="text-neutral-800 desktop-heading-h5 font-bold ">{LAYOUT_DATA.NOTIFICATION}</p>
          {/* <div className="flex gap-4">
            <div className="icon-div-notification cursor-pointer">
              <DoubleCheckIcon className="flex" />
            </div>
            <div className="icon-div-notification cursor-pointer">
              <SettingsIconColor className="flex" />
            </div>
          </div> */}
        </div>
      </div>

      <div className="scroll-wrapper-notification">
        {notificationData?.map((item: any) => {
          const formattedDate = dayjs(item?.notification_data?.created_at).fromNow()
          return (
            <div key={item?.id} className={`notification-list border-b-stroke ${item?.isRead ? 'bg-neutral-50' : 'bg-white'}`}>
              <div className="flex gap-3">
                <div className="flex flex-col gap-2 items-center">
                  <div className="icon-notification">
                    {item?.notification_data?.shift_status === SHIFT_STATUS?.CANCELLED ? (
                      <CalenderCrossIcon className="w-full h-full flex justify-center items-center" />
                    ) : (
                      <CalenderCheckIcon className="w-full h-full flex justify-center items-center" />
                    )}
                  </div>
                  {!item?.isRead && <NotificationUnreadIcon />}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-neutral-800 para-p2">{item?.title}</p>
                  <p className="text-neutral-700 font-small para-p3" dangerouslySetInnerHTML={{ __html: item.text }} />
                  <p className="text-neutral-600 para-p4 font-small">{formattedDate}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Drawer>
  )
}

export default NotificationDrawer

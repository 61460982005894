import React from 'react'

const StarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.0005 9.66966C21.9373 9.48674 21.8224 9.32608 21.6698 9.20716C21.5171 9.08823 21.3333 9.01615 21.1405 8.99966L15.4505 8.16966L12.9005 2.99966C12.8186 2.83059 12.6907 2.688 12.5316 2.58823C12.3724 2.48846 12.1883 2.43555 12.0005 2.43555C11.8126 2.43555 11.6286 2.48846 11.4694 2.58823C11.3102 2.688 11.1824 2.83059 11.1005 2.99966L8.55047 8.15966L2.86047 8.99966C2.67539 9.02596 2.50139 9.10362 2.35822 9.22381C2.21504 9.34401 2.10843 9.50193 2.05047 9.67966C1.99741 9.85333 1.99265 10.0382 2.03669 10.2143C2.08074 10.3905 2.17192 10.5514 2.30047 10.6797L6.43047 14.6797L5.43047 20.3597C5.39477 20.5471 5.41346 20.7409 5.48434 20.9181C5.55522 21.0953 5.67532 21.2485 5.83047 21.3597C5.98168 21.4678 6.16004 21.5316 6.34551 21.5439C6.53099 21.5563 6.71624 21.5167 6.88047 21.4297L12.0005 18.7597L17.1005 21.4397C17.2408 21.5188 17.3993 21.5602 17.5605 21.5597C17.7723 21.5604 17.9789 21.4939 18.1505 21.3697C18.3056 21.2585 18.4257 21.1053 18.4966 20.9281C18.5675 20.7509 18.5862 20.5571 18.5505 20.3697L17.5505 14.6897L21.6805 10.6897C21.8248 10.5673 21.9316 10.4066 21.9882 10.226C22.0448 10.0455 22.0491 9.85253 22.0005 9.66966ZM15.8505 13.6697C15.7332 13.7831 15.6454 13.9235 15.5949 14.0786C15.5444 14.2338 15.5325 14.3989 15.5605 14.5597L16.2805 18.7497L12.5205 16.7497C12.3758 16.6726 12.2144 16.6323 12.0505 16.6323C11.8865 16.6323 11.7251 16.6726 11.5805 16.7497L7.82047 18.7497L8.54047 14.5597C8.5684 14.3989 8.55658 14.2338 8.50603 14.0786C8.45548 13.9235 8.36774 13.7831 8.25047 13.6697L5.25047 10.6697L9.46047 10.0597C9.62246 10.0371 9.77646 9.9752 9.90896 9.87931C10.0415 9.78342 10.1484 9.65649 10.2205 9.50966L12.0005 5.69966L13.8805 9.51966C13.9525 9.66649 14.0595 9.79342 14.192 9.88931C14.3245 9.9852 14.4785 10.0471 14.6405 10.0697L18.8505 10.6797L15.8505 13.6697Z"
        fill="#2A2A2B"
      />
    </svg>
  )
}

export default StarIcon

import { message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import CustomButton from 'component/Button/CustomButton'
import CustomTable from 'component/Table/CustomTable'
import TableImage from 'component/Table/TableImage'
import { SHIFTS } from 'constants/Constants'
import { SHIFT_INVITATION_STATUS, SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { INVITE_PROVIDER_AGAIN, WITHDRAWN_INVITATION } from 'pages/Shifts/api'
import { setGlobalLoader } from 'store/slice/CommonSlice'

const InvitesTab = ({ data, status, handlePageChange, paginationInfo, isWithdrawn, setIsWithdrawn }: any) => {
  const dispatch = useAppDispatch()

  const handleInviteProviderAgain = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await INVITE_PROVIDER_AGAIN(id)
      if (response?.data?.statusCode === 1) {
        setIsWithdrawn(!isWithdrawn)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleWithdrawnInvitation = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await WITHDRAWN_INVITATION(id)
      if (response?.data?.statusCode === 1) {
        setIsWithdrawn(!isWithdrawn)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.INVITE_TABLE.INVITEE,
      dataIndex: 'requestTo',
      key: 'requestTo',
      align: 'center',
      width: 250,
      render: (_value, record) => {
        return (
          <div className="cursor-pointer">
            <TableImage
              baseUrl={record?.provider?.base_url}
              image={record?.provider?.profile_image}
              name={`${record?.provider?.first_name as string} ${record?.provider?.last_name as string}`}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.REQUIRE_CREDENTIAL,
      dataIndex: 'requireCredential',
      key: 'requireCredential',
      align: 'center',
      width: 281,
      render: (_value, record) => {
        return <div>4/4</div>
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.LICENSE,
      dataIndex: ['shift', 'certificate', 'name'],
      key: 'certificate',
      align: 'center',
      width: 375,
    },
    {
      title: SHIFTS.INVITE_TABLE.INVITED_ON,
      dataIndex: 'invitedOn',
      key: 'invitedOn',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return <div>{dayjs(record?.created_at).format('MM/DD/YYYY, hh:mm A')}</div>
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.ACTION,
      dataIndex: '',
      key: 'requestTo',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return (
          <div className="flex items-center justify-center gap-2 cursor-pointer">
            {[SHIFT_INVITATION_STATUS?.REJECTED, SHIFT_INVITATION_STATUS.WITHDRAWN].includes(record?.status) ? (
              <CustomButton
                disabled={status === SHIFT_STATUS.SCHEDULE}
                type="default"
                className="assign-btn"
                text="Invite Again"
                onClick={() => {
                  void handleInviteProviderAgain(record?.id)
                }}
              />
            ) : (
              <CustomButton
                disabled={status === SHIFT_STATUS.SCHEDULE}
                type="default"
                text="Withdraw Request"
                className="assign-btn"
                onClick={() => {
                  void handleWithdrawnInvitation(record?.id)
                }}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="request-table-wrapper flex p-6 flex-col items-start gap-4 h-full">
      <CustomTable
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={data?.data}
        name={SHIFTS.INVITE}
      />
    </div>
  )
}

export default InvitesTab

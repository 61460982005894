const CalenderSolid = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M19.5 2.89999H18.5V1.89999C18.5 1.29999 18.1 0.899994 17.5 0.899994C16.9 0.899994 16.5 1.29999 16.5 1.89999V2.89999H8.5V1.89999C8.5 1.29999 8.1 0.899994 7.5 0.899994C6.9 0.899994 6.5 1.29999 6.5 1.89999V2.89999H5.5C3.8 2.89999 2.5 4.19999 2.5 5.89999V19.9C2.5 21.6 3.8 22.9 5.5 22.9H19.5C21.2 22.9 22.5 21.6 22.5 19.9V5.89999C22.5 4.29999 21.2 2.89999 19.5 2.89999ZM7.5 18.9C6.9 18.9 6.5 18.5 6.5 17.9C6.5 17.3 6.9 16.9 7.5 16.9C8.1 16.9 8.5 17.3 8.5 17.9C8.5 18.5 8.1 18.9 7.5 18.9ZM7.5 14.9C6.9 14.9 6.5 14.5 6.5 13.9C6.5 13.3 6.9 12.9 7.5 12.9C8.1 12.9 8.5 13.3 8.5 13.9C8.5 14.5 8.1 14.9 7.5 14.9ZM12.5 18.9C11.9 18.9 11.5 18.5 11.5 17.9C11.5 17.3 11.9 16.9 12.5 16.9C13.1 16.9 13.5 17.3 13.5 17.9C13.5 18.5 13.1 18.9 12.5 18.9ZM12.5 14.9C11.9 14.9 11.5 14.5 11.5 13.9C11.5 13.3 11.9 12.9 12.5 12.9C13.1 12.9 13.5 13.3 13.5 13.9C13.5 14.5 13.1 14.9 12.5 14.9ZM17.5 18.9C16.9 18.9 16.5 18.5 16.5 17.9C16.5 17.3 16.9 16.9 17.5 16.9C18.1 16.9 18.5 17.3 18.5 17.9C18.5 18.5 18.1 18.9 17.5 18.9ZM17.5 14.9C16.9 14.9 16.5 14.5 16.5 13.9C16.5 13.3 16.9 12.9 17.5 12.9C18.1 12.9 18.5 13.3 18.5 13.9C18.5 14.5 18.1 14.9 17.5 14.9ZM20.5 8.89999H4.5V5.89999C4.5 5.29999 4.9 4.89999 5.5 4.89999H6.5V5.89999C6.5 6.49999 6.9 6.89999 7.5 6.89999C8.1 6.89999 8.5 6.49999 8.5 5.89999V4.89999H16.5V5.89999C16.5 6.49999 16.9 6.89999 17.5 6.89999C18.1 6.89999 18.5 6.49999 18.5 5.89999V4.89999H19.5C20.1 4.89999 20.5 5.29999 20.5 5.89999V8.89999Z"
        fill="#252431"
      />
    </svg>
  )
}

export default CalenderSolid

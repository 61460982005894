import React from 'react'
import { Button, Modal } from 'antd'

import './DeleteCancelModal.scss'

interface IDeleteModal {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  mainHead?: string
  subHead?: string
  cancelText: string
  okClick: () => void
  okText: string
}

const DeleteCancelModal = ({ isModalOpen, setIsModalOpen, mainHead, subHead, cancelText, okClick, okText }: IDeleteModal) => {
  return (
    <Modal
      open={isModalOpen}
      centered
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
      closable={false}
      closeIcon={null}
      footer={null}
      className="delete-modal"
    >
      <p className="font-semi-bold desktop-heading-h5 text-neutral-800 text-center mb-6">{mainHead}</p>
      <p className="font-normal para-p1 text-neutral-700 mb-6 text-center">{subHead}</p>

      <div className="flex justify-end w-full gap-4">
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          className="common-btn"
        >
          {cancelText}
        </Button>
        <Button onClick={okClick} type="primary" className="common-btn">
          {okText}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteCancelModal

import { BillingIcon, ChatsIcon, DashBoardIcon, FacilityContactIcon, ProvidersIcon, ReportsIcon, ScheduleIcon, ShiftsIcon, TimeIcon } from 'assets/svgs/sidebar/SidebarMenuIcons'

export const siderMenu = [
  {
    id: 1,
    name: 'Dashboard',
    icon: <DashBoardIcon />,
    selectedIcon: <DashBoardIcon isActive={true} />,
    path: '/',
  },
  // {
  //   id: 2,
  //   name: 'Schedule',
  //   icon: <ScheduleIcon />,
  //   selectedIcon: <ScheduleIcon isActive={true} />,
  //   path: '/schedule',
  // },
  {
    id: 3,
    name: 'Shifts',
    icon: <ShiftsIcon />,
    selectedIcon: <ShiftsIcon isActive={true} />,
    path: '/shifts',
  },
  {
    id: 4,
    name: 'Reports',
    icon: <ReportsIcon />,
    selectedIcon: <ReportsIcon isActive={true} />,
    path: '/reports',
  },
  {
    id: 5,
    name: 'Providers',
    icon: <ProvidersIcon />,
    selectedIcon: <ProvidersIcon isActive={true} />,
    path: '/providers',
  },
  {
    id: 6,
    name: 'Chats',
    icon: <ChatsIcon />,
    selectedIcon: <ChatsIcon isActive={true} />,
    path: '/chats',
  },
  {
    id: 7,
    name: 'Time & Attendance',
    icon: <TimeIcon />,
    selectedIcon: <TimeIcon isActive={true} />,
    path: '/time-and-attendance',
  },
  {
    id: 8,
    name: 'Billing',
    icon: <BillingIcon />,
    selectedIcon: <BillingIcon isActive={true} />,
    path: '/admin-settings',
  },
  {
    id: 9,
    name: 'Facility Contacts',
    icon: <FacilityContactIcon />,
    selectedIcon: <FacilityContactIcon isActive={true} />,
    path: '/facility-contacts',
  },
  // {
  //   id: 10,
  //   name: 'Downloads',
  //   icon: <DownloadIcon />,
  //   selectedIcon: <DownloadIcon isActive={true} />,
  //   path: '/admin-settings',
  // },
]

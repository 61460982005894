import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { HomeIcon, SeperatorBreadcrumbs } from 'assets/svgs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectBreadcrumbData, setBreadcrumbMap } from 'store/slice/BreadcrumbSlice'
import { resolveBreadcrumbLabel } from 'utils/helper'

const breadcrumbAbout = {
  '/': 'Home',
  '/about': 'About',
  '/facility-contacts': 'Facility Contacts',
  '/shifts': 'Shifts',
  '/facility-profile': 'Facility Profile',
  '/time-and-attendance': 'Time & Attendance',
  '/my-profile': 'My Profile',
  '/providers': 'Providers',
  '/providers/provider-profile': 'Catherine James',
}

const Breadcrumbs: React.FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const breadcrumbMap = useAppSelector(selectBreadcrumbData)

  useEffect(() => {
    dispatch(setBreadcrumbMap(breadcrumbAbout))
  }, [dispatch])

  const pathSnippets = location.pathname.split('/')?.filter((i) => i)

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      if (!breadcrumbMap[url]) {
        return null
      }

      const { url: resolvedUrl, label } = resolveBreadcrumbLabel(url, location, breadcrumbMap)

      return (
        <Breadcrumb.Item key={resolvedUrl}>
          <Link to={resolvedUrl}>{label}</Link>
        </Breadcrumb.Item>
      )
    })
    ?.filter((item): item is React.ReactElement => item !== null) // Filter out null values

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeIcon />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <Breadcrumb
      className="mt-4 mb-4"
      separator={
        <div className="flex justify-center items-center h-full">
          <SeperatorBreadcrumbs />
        </div>
      }
    >
      {breadcrumbItems}
    </Breadcrumb>
  )
}

export default Breadcrumbs

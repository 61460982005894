const Chat = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.5 7.09999C13.5 6.69999 14.5 6.49999 15.5 6.49999C16.3 6.49999 17.1 6.59999 17.8 6.79999C16 2.69999 11.3 0.899988 7.3 2.59999C3.3 4.39999 1.4 9.09999 3.2 13.2C3.5 13.8 3.8 14.4 4.2 14.9L2.8 16.3C2.4 16.7 2.4 17.3 2.8 17.7C3 17.9 3.2 18 3.5 18H7.6C5.9 13.6 8.1 8.69999 12.5 7.09999ZM22.2 20.3L21.1 19.2C23.4 16.1 22.8 11.7 19.7 9.39999C16.6 7.09999 12.2 7.69999 9.9 10.8C7.6 13.9 8.2 18.3 11.3 20.6C12.5 21.5 14 22 15.5 22H21.5C22.1 22 22.5 21.6 22.5 21C22.5 20.7 22.4 20.5 22.2 20.3Z"
        fill="#252431"
      />
    </svg>
  )
}

export default Chat

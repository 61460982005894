// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}
.loader-wrapper .ant-zoom {
  height: 160px;
  width: 160px;
}

.ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: #220eb1 !important;
}`, "",{"version":3,"sources":["webpack://./src/component/Loader/Loader.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;AADF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;;AAMI;EACE,oCAAA;AAHN","sourcesContent":["@import '../../styles/variables';\n\n.loader-wrapper {\n  background: rgb(0 0 0 / 50%);\n  backdrop-filter: blur(3px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10000;\n\n  .ant-zoom {\n    height: 160px;\n    width: 160px;\n  }\n}\n.ant-spin {\n  .ant-spin-dot {\n    .ant-spin-dot-item {\n      background-color: $primary-500 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

const CheckSolidIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M16.2077 6C15.8744 5.66667 15.3743 5.66667 15.041 6L8.79102 12.25L6.20768 9.66667C5.87435 9.33333 5.37435 9.33333 5.04102 9.66667C4.70768 10 4.70768 10.5 5.04102 10.8333L8.20768 14C8.37435 14.1667 8.54102 14.25 8.79102 14.25C9.04102 14.25 9.20768 14.1667 9.37435 14L16.2077 7.16667C16.541 6.83333 16.541 6.33333 16.2077 6Z"
        fill="black"
      />
    </svg>
  )
}

export default CheckSolidIcon

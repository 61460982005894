export const INTERNAL_SERVER_ERROR = 500
export const UNAUTHORIZED_REQUEST = 401
export const PARAGRAPH_LENGTH = 100

export const LAYOUT_DATA = {
  MY_PROFILE: 'My Profile',
  LOGOUT: 'Log out',
  NOTIFICATION: 'Notifications',
}

export const LOGIN = {
  WELCOME_BACK: 'Welcome back!',
  ARE_YOU_FACILITY_USER: 'Are you a facility user?',
  ARE_YOU_FACILITY: 'Are you a facility?',
  DONT_HAVE: 'Don’t have an account?',
  CHECK_MAIL_SUB: 'We have sent an email with password reset link on ',
}

export const LINK_EXPIRY = {
  LINK_EXPIRE: 'The link is expired!',
  SORRY_TEXT: 'Sorry, this link is either expired or is used once.',
  LOGIN: 'Go To Login',
}

export const SIGNUP = {
  LETS_START: 'Let’s get started!',
  ALREADY: 'Already have an account?',
  SIGN_UP_AGREE: 'By signing up you are agree to nursesnow’s',
  TERM: 'Terms',
  AND: 'and',
  PRIVACY_POLICY: 'Privacy Policy',
  SET_PASSWORD: 'Set password',
  SET_PASSWORD_SUB: 'Set a strong password to complete your account',
  COUNTRY_CODE: '+1',
  FACILITY_NAME_AND_: 'Facility Name & Number',
  EMAIL_VERI_SUB: 'Enter facility name and contact number. Our support member will contact you for verification.',
  SEND_LINK: 'We sent a link to',
  VERIFY_EMAIL: 'to verify your email.',
  FACILITY_SIGNUP_SUB: 'Confirm your details and set a strong password',
  WELCOME: 'Welcome,',
}

export const COMMON = {
  SELECT_REASON: 'Select Reason',
  ADDITIONAL_DETAIL: 'Additional details',
  INVITE: 'Invite',
  FORGOT: 'Forgot Password',
  CHECK_EMAIL: 'Check your mail',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CONTINUE: 'Continue',
  LOGIN: 'Login',
  SIGNUP: 'Sign up',
  OR: 'Or',
  HELLO: 'Hello, ',
  ACTION: 'ACTIONS',
  LICENSE: 'License',
  SEARCH_LICENCE: 'Search Licence',
  SEARCH_SPECIALITY: 'Search Speciality',
  SEARCH_FACILITY: 'Search Facility',
  SEARCH_SHIFT: 'Search with shift ID',
  SEARCH_PROVIDER: 'Search Provider',
  SPECIALITY: 'Specialty',
  BACK: 'Back',
  NEW_PASSWORD: 'New Password',
  CON_NEW_PASS: 'Confirm New Password',
  FACILITY_NAME: 'Facility Name ',
  MOBILE_NO: 'Mobile No',
  OPEN_MAIL: 'Open Gmail',
  OPEN_OUTLOOK: 'Open Outlook',
  NAME_OF_FACILITY: 'Name of Facility',
  FACILITY_TYPE: 'Facility Type',
  NO_OF_BED: 'Number of Beds',
  GET_STARTED: 'Get Started',
  STREET: 'Street Address *',
  APARTMENT: 'Apartment/Suite',
  CITY: 'City *',
  ZIP: 'Zip / Postal Code *',
  COUNTRY: 'Country',
  STATE: 'State/Province *',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  USER_NAME: 'User Name',
  SET_STRONG_PASS: 'Set a Strong Password',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  DESCRIPTION: 'Description',
  YES: 'Yes',
  NO: 'No',
  SELECT_DATE: 'Select Date',
  NOTE: 'Note',
  CMA: 'CMA',
  CNA: 'CNA',
  LPN: 'LPN',
  RN: 'RN',
  SAVE: 'Save',
  CURRENT_PASSWORD: 'Current Password',
  TODAY: 'Today',
  TIME_ACTI: 'Time Activity',
  FACILITY_ACC: 'Facility Access',
  DATE: 'Date',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  FLOOR: 'Floor',
  SEARCH: 'Search',
  IN_TIME: 'In Time',
  OUT_TIME: 'Out Time',
  BREAK_DOWN_DUR: 'Break Duration',
  REJECT_REASON: 'Reject Reason',
  WRITE_YOUR_REVIEW: 'Write your review',
  FULL_NAME: 'Full Name',
  FILTER: 'Filter',
  REQUEST: 'Request',
  FOR_WORK: 'For Work',
  FLAG: 'Flag',
}

export const FACILITY_CONTACTS = {
  BILLING_CONTACTS: 'Billing Contacts',
  CONTACTS: 'Contacts',
  MEMBERS: 'Members',
  ACTIVE: 'Active',
  ADD_CONTACT: 'Add A Contact',
  EDIT_CONTACT: 'Edit contact',
  COUNTRY_CODE: '+1',
  Select_PERMISSION: 'Select Permissions',
  PERMISSIONS: 'PERMISSIONS',
  FACILITY_ACCESS: 'Facility Access',
  USER_STATUS: 'User Status',
  TABLE: {
    TEAM_MEMBER: 'TEAM MEMBER',
    EMAIL: 'EMAIL',
    STATUS: 'STATUS',
    INVITATION_STATUS: 'INVITATION STATUS',
    FACILITY_ACCESS: 'FACILITY ACCESS',
    LAST_MODIFIED: 'LAST MODIFIED',
    ACTIONS: 'ACTIONS',
  },
}

export const SHIFTS = {
  VIEW_TIMECARD: 'View Timecard',
  REPOST: 'Repost',
  VIEW_REQUEST: 'View Request',
  VIEW_DETAILS: 'View Details',
  EDIT_SHIFT: 'Edit Shift',
  MAIN_HEAD_DELETE: 'Are you sure you want to cancel?',
  SUB_HEAD_DELETE: 'Shifts created will be deleted if you click on cancel',
  YES_CANCEL: 'Yes, Cancel',
  GO_BACK: 'Go back',
  UPDATE: 'Update',
  CANCEL_SHIFT: 'Cancel Shift?',
  SHIFTS: 'Shifts',
  SELECT_FACILITY: 'Select Facility',
  SHIFT_TYPE: 'Shift Type',
  DATE: 'Date',
  DETAILS: 'Details',
  INVITE: 'Invite',
  CREATE_SHIFT: 'Create Shift',
  PER_DIEM: 'Per Diem',
  LONG_TERM: 'Long Term',
  TRAVEL: 'Travel',
  DRAFT: 'Draft',
  NO_FACILITY: 'No Facility',
  NO_DATE: 'No Date',
  PROVIDER: 'Provider',
  INVITE_INSTRUCTION: 'Please select a license and specialty before inviting the provider.',
  CONTACT: 'Contact',
  END_TIME: 'End Time',
  START_TIME: 'Start Time',
  OPENINGS: 'Openings',
  FLOOR: 'Floor',
  SHIFT_DETAILS: 'Shift Details',
  RESET: 'Reset',
  CREATE: 'Create',
  POST: 'Post',
  INVITE_PROVIDER: 'Invite Providers',
  SEARCH_PRO: 'Search Providers',
  SELECTED: 'Selected',
  SELECT_ALL: 'Select All',
  REPEAT_ON: 'Repeat On',
  SELECT_DATES: 'Select Dates',
  CARD_HEAD: 'Your created shift will appear here',
  CARD_SUBHEAD: 'When you create a shift it will appear here. You can edit, clone or delete the created shift from here',
  DELETE_SHIFT_HEAD: 'Are you sure to want to delete shift?',
  ACTIVITY: 'Activity',
  NO_ACTIVITY: 'No Activity',
  NO_ACTIVITY_SUB: 'No activity in this shift currently.',
  SHIFT_DESC: 'Shift Description',
  REQUESTED: 'Requests',
  INVITED: 'Invitees',
  CANCEL_BY: 'Cancelled by',
  CANCEL_ON: 'Cancel On',
  CANCEL_REASON: 'Cancellation Reason',
  SELECTED_DATES: 'Select Dates',
  TABLE: {
    SHIFT_ID: 'SHIFT ID',
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    STATUS: 'STATUS',
    NO_OF_REQUEST: 'NO. OF REQUEST',
  },
  REQUEST_TABLE: {
    REQUESTED_BY: 'REQUESTED BY',
    LICENSE: 'LICENSE',
    REQUESTED_ON: 'REQUESTED ON',
    ACTION: 'ACTION',
    STATUS: 'STATUS',
  },
  INVITE_TABLE: {
    INVITEE: 'INVITEE',
    REQUIRE_CREDENTIAL: 'REQUIRE CREDENTIAL',
    LICENSE: 'LICENSE',
    INVITED_ON: 'INVITED ON',
    ACTION: 'ACTION',
    STATUS: 'STATUS',
  },
}

export const WELCOME = {
  WELCOME: 'Welcome!',
  WELCOME_SUB: 'Welcome to nursesnow - Empowering Healthcare Excellence',
  COMP_YOUR_PROFILE: 'Complete your profile',
  COMP_YOUR_PROFILE_SUB: 'Just few steps to complete your profile and you are ready to post shifts.',
  SET_PROFILE_PIC: 'Set Profile Pic',
  ADD_FACILITY_ADDRESS: 'Add Facility Address',
}

export const PAGE_TITLE_STRING = {
  FACILITY_PROFILE: 'My Facility',
  FACILITY_CONTACTS: 'Facility Contacts',
  TIME_AND_ATTENDANCE: 'Time & Attendance',
  MY_PROFILE: 'My Profile',
  SHIFTS: 'Shifts',
  PROVIDERS: 'Providers',
  PROVIDER_PROFILE: 'Provider Profile',
}

export const BUTTON_STRING = {
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  EDIT_profile: 'Edit Profile',
  BACK: 'BACK',
  CHANGE_PASS: 'Change Password',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ADD_HOLIDAY: 'Add Holiday',
  ADD: 'Add',
  CANCEL: 'Cancel',
  UPLOAD_DOCUMENT: 'Upload Document',
  UPDATE: 'Update',
  CANCEL_SHIFT: 'Cancel Shift',
  RE_OPEN: 'Re-Open',
  RE_POST: 'Re-Post',
  ASSIGN: 'Assign',
  INVITE: 'Invite',
  ADD_USER: 'Add User',
  SEND_INVITATION: 'Send Invitation',
  VIEW_ACTIVITY: 'View Activity',
  REJECT: 'Reject',
  SAVE_AND_APPROVE: 'Save and Approve',
  SUBMIT_REVIEW: 'Submit Review',
  APPROVE_NOW: 'Approve Now',
  RATE_PROVIDER: 'Rate Provider',
  VIEW_TIMECARD: 'View Timecard',
  ADD_EVALUATION: 'Add Evaluation',
  REQ_TO_WORK: 'Request to Work',
  CREATE_NEW_SHIFT: 'Create New Shift',
  SEND_REQUEST: 'Send Request',
  DOWNLOAD_PDF: 'Download PDF',
  SAVE_EVALUATION: 'Save Evaluation',
  RESET: 'Reset',
  APPLY: 'Apply',
  VIEW_CREDENTIALS: 'View Credentials',
  WORK_HISTORY: 'Work History',
  CHAT: 'Chat',
}

export const FACILITY_USER_PROFILE = {
  NOTIFICATION: 'Notifications',
  PUSH: 'Push',
  EMAIL: 'Email',
  MANAGE_ACCOUNT: 'Manage Account',
  PASSWORD_SUB: 'Set a unique password to protect your account',
  PRIVACY_POLI: 'Privacy Policy',
  PRI_POL_SUB: 'Our privacy policy for you',
  TERM_AND_COND: 'Terms & Conditions',
  TERM_AND_COND_SUB: 'Terms and conditions to use this platform',
  HOLIDAYS: 'Holidays',
  SELECT_CREATE_HOLIDAY: 'Select or create holiday',
  BILL_HOLIDAY: 'Bill This Holiday?',
  DOCUMENT: 'Documents',
  UPLOAD_DOCUMENT: 'Upload Document',
  DRAG_TO_UPLOAD: 'Drag and drop or click here to upload document.',
  DRAG_UPLOAD_MSG: 'Should be in Jpg, png or pdf format and not exceed 5 MB',
  DOCUMENT_CATEGORY: 'Document Category',
  DATE_PERIOD_END: 'Date Period Ending',
  CHANGE_PASSWORD: 'Change Password',
  SWITCH_FACILITY: 'SWITCH FACILITY',
  HOLIDAY_TABLE: {
    NAME: 'NAME',
    DESCRIPTION: 'DESCRIPTION',
    BILLING: 'BILLING',
    DATE: 'DATE',
    ACTION: 'ACTION',
  },
  ADD_DOCUMENT: {
    DOCUMENT_NAME: 'DOCUMENT NAME',
    DOCUMENT_CATEGORY: 'DOCUMENT CATEGORY',
    Date_END: 'DATE PERIOD ENDING',
    NOTES: 'NOTES',
    USER: 'USER',
    DATE_UPLOADED: 'DATE UPLOADED',
  },
  RATE_SHEET: {
    SPECIALITY: 'SPECIALITY',
    PAY_BILL: 'PAY BILL',
    WEEK_DAY: 'WEEK DAY',
    WEEK_END: 'WEEK END',
    SHIFT: 'Shift',
  },
}

export const PRIVACY_POLICY_DATA = [
  {
    text: 'Welcome to NursesNow! This Privacy Policy outlines how we collect, use, share, and protect your personal information. By using our platform, you agree to the terms outlined in this policy.',
    data: [
      {
        id: 1,
        title: 'Information We Collect',
        text: 'We collect information you provide during the registration process, including your name, contact details, professional credentials, and preferences. Additionally, we may collect usage data, such as IP addresses, device information, and interactions with our platform.',
      },
      {
        id: 2,
        title: 'How We Use Your Information',
        text: 'We use your information to facilitate the connection between healthcare facilities and nursing professionals, personalize your experience, process transactions, and improve our services. We may also use your data for communication, account management, and to provide relevant updates.',
      },
      {
        id: 3,
        title: ' Data Security',
        text: 'We prioritize the security of your information. Our platform employs industry-standard measures to protect against unauthorized access, disclosure, alteration, and destruction of your personal data. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.',
      },
    ],
  },
]

export const TERM_CONDITION_DATA = [
  {
    text: 'Welcome to NursesNow. These Terms and Conditions outline the rules and regulations governing your use of our platform. By accessing and using NursesNow, you agree to comply with these terms. If you do not agree with any part of these terms, please do not use our platform.',
    data: [
      {
        id: 1,
        title: 'Eligibility',
        text: 'You must be at least 18 years old to use our platform. By using the platform, you confirm that you meet this eligibility requirement.',
      },
      {
        id: 2,
        title: 'Account Registration',
        text: 'To access certain features of the platform, you must create an account. You agree to provide accurate and complete information during the registration process and to keep your account information updated.',
      },
      {
        id: 3,
        title: 'Platform Use',
        text: 'You agree to use [Platform Name] solely for its intended purpose: connecting healthcare facilities with nursing professionals. You agree not to use the platform for any unlawful or unauthorized purpose or in a manner that violates any applicable laws or regulations.',
      },
    ],
  },
]

export const TIME_ATTENDANCE_STRING = {
  APPROVE_SEL: 'Approve Selected',
  ENTRIES: 'Entries',
  REJECT_TIME_CARD: 'Reject Time Card?',
  APPROVED_ON: 'Approved on',
  REJECTED_ON: 'Rejected on',
  TABLE: {
    SHIFT_ID: 'SHIFT ID',
    PROVIDER: 'PROVIDER',
    SHIFT_DATE: 'SHIFT DATE',
    IN_TIME: 'IN TIME',
    OUT_TIME: 'OUT TIME',
    BREAK_TIME: 'BREAK TIME',
    HOURS: 'HOURS',
    TIME_IMAGE: 'TIMECARD IMAGE',
    FLOOR: 'FLOOR',
    STATUS: 'STATUS',
    ACTION: 'ACTION',
  },
}

export const TIME_CARD_DATA = {
  SHIFT_ID: 'Shift ID',
  SHIFT_DATE: 'Shift Date',
  HARD_WORKED: 'Hours Worked',
  PROVIDER_SIGN: 'PROVIDER’S SIGNATURE',
  AUTHOR_SIGN: 'AUTHORITY SIGNATURE',
  CLICK_TO_SIGN: 'Click here to Sign',
  HOW_YOUR_EXP: 'How was your experience working with',
  PUNCTUALITY: 'Punctuality',
  CLINICAL_COM: 'Clinical Competence',
  CO_AND_CO: 'Communication and Collaboration',
  REPORT_ISSUE: 'Report Issue ?',
  REPORT_ISSUE_DESC: 'If you report this as issue, It will be submitted to Nurses Now team. The team will look into the issue.',
}

export const USER_PROFILE_STRING = {
  AVATAR: 'Avatar',
  BASIC_DETAIL: 'Basic Details',
  SIGNATURE: 'Signature',
  RE_DRAW_SIGNATURE: 'Re-draw Signature',
}

export const TABLE_COLUMNS = {
  PROVIDERS: {
    PROVIDER: 'PROVIDERS',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    LAST_SCHEDULED: 'LAST SCHEDULED',
    NEXT_SCHEDULED: 'NEXT SCHEDULED',
    SHIFT_WORK: 'SHIFT WORKED',
    FLAG: 'FLAG',
    ACTION: 'ACTION',
  },
  REQ_PROVIDER_WORK: {
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    FACILITY: 'FACILITY',
    MATCH: 'MATCH',
  },
  CREDENTIAL: {
    CREDENTIAL: 'CREDENTIAL',
    NAME: 'NAME',
    ISSUE_DATE: 'ISSUE DATE',
    EXPIRY_DATE: 'EXPIRY DATE',
    VERIFIED: 'VERIFIED',
    DAYS: 'DAYS',
    LICENCSE_NO: 'LICENCSE NO.',
    ACTION: 'ACTION',
  },
  SHIFTS_HISTORY: {
    SHIFT_ID: 'SHIFT ID',
    FACILITY: 'FACILITY',
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    STATUS: 'STATUS',
    TYPE: 'TYPE',
    CREATED_BY: 'CREATED BY',
  },
  EVALUATION: {
    EVAL_DATE: 'EVAL. DATE',
    EVALUATION: 'EVALUATION',
    CLIENT: 'CLIENT',
    SERVICE_PERIOD: 'SERVICE PERIOD',
    RESULT: 'RESULT',
    ACTION: 'ACTION',
  },
  COMPLETE_NEW_EVALUATION: {
    PERFORMANCE: 'Performance Evaluation',
    EXCEED: 'Often Exceeds Expectation',
    EXPECTATION: 'Meets Expectation',
    NOT_EXPECTED: 'Does not Meet Expectation',
    NA: 'N/A',
  },
}

export const PROVIDER_STRING = {
  OPEN_SHIFT: 'Open Shifts',
  NEW_EVALUATION: 'New Evaluation',
  COMPLETE_EVALUATION: 'Completed Evaluation',
  EVALUATION: 'Evaluation',
  SHIFT: 'Shift',
  PROFESSIONAL_LICENCE: 'Professional Licences',
  REQUIRED_DOCUMENT: 'Required Documents',
  MEDICAL_DOCUMENT: 'Medical Documents',
  HR_DOCUMENT: 'HR Documents',
  LICENSE: 'LICENSE',
  SPECIALTY: 'SPECIALTY',
  BRIEF_INFO: 'BRIEF INFORMATION',
  BIRTH_DATE: 'BIRTH DATE',
  ADDRESS: 'ADDRESS',
  OVERALL_RATING: 'Overall ratings for this provider',
  SORT_BY: 'Sort By',
  ATTENDANCE_SCORE: 'Attendance Score',
  HOUSE_WORKED: 'Hours Worked',
  ON_TIME_RATE: 'On-time Rate',
  SHIFT_COMPLETED: 'Shifts Completed',
  SHOW_RATE: 'Show Rate',
  LOCATION: 'LOCATION',
  MOBILE_EMAIL: 'MOBILE AND EMAIL',
  WORK_EXPERIENCE_WITH: 'How was your experience working with',
  SELECT_SHIFT: 'Select Shift to review',
  PUNCTUALITY: 'Punctuality',
  CLIENT_COM: 'Clinical Competence',
  CO_AND_CO: 'Communication and Collaboration',
  REPORT_ISSUES: 'Report Issue ?',
  ISSUE_DES: 'If you report this as issue, It will be submitted to Nurses Now team. The team will look into the issue.',
  CLINICAL: 'Clinical',
  PROFESSIONAL: 'Professional',
  ALL: 'All',
  DNR: 'DNR',
  CAUTION: 'Caution',
  PREFERRED: 'Preferred',
  FLAG_DNR: 'Flag as DNR',
  FLAG_GREEN: 'Flag as Preferred',
  FLAG_YELLOW: 'Flag as Caution',
  REASON_FOR_DNR: 'What is the main Reason for DNR',
  CLINICAL_VALUE: 'clinical',
  PROFESSIONAL_VALUE: 'professional',
  SELECT_CLINICAL: 'Select Clinical Reasons',
  SELECT_PROFESSIONAL: 'Select Professional Reasons',
  CLINICAL_REASON: 'CLINICAL REASON',
  PROFESSIONAL_REASON: 'PROFESSIONAL REASON',
}

export const SHIFT_STRING = {
  LVN: 'LVN',
  CNA: 'CNA',
  LPN: 'LPN',
  GNA: 'GNA',
  ICU: 'ICU',
  RN: 'RN',
  MS: 'M/S',
  OR: 'OR',
  TELE: 'TELE',
  LTC: 'LTC',
  BURN: 'BURN',
  MED_SUG: 'Med Surg',
  ER: 'ER',
}

export const PER_DIEM_STATUS = {
  OPEN: 'open',
  CANCELLED: 'cancelled',
  REQUESTED: 'requested',
  UNCONFIRMED: 'unconfirmed',
  UNSUBMITTED: 'un_submitted',
  SCHEDULE: 'scheduled',
  COMPLETED: 'completed',
}

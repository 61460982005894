import React from 'react'

const RequestIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none">
      <path
        d="M11.675 9.22C12.2086 8.75813 12.6366 8.18688 12.9299 7.54502C13.2232 6.90316 13.375 6.20571 13.375 5.5C13.375 4.17392 12.8482 2.90215 11.9105 1.96447C10.9729 1.02678 9.70108 0.5 8.375 0.5C7.04892 0.5 5.77715 1.02678 4.83947 1.96447C3.90178 2.90215 3.375 4.17392 3.375 5.5C3.37499 6.20571 3.5268 6.90316 3.82013 7.54502C4.11345 8.18688 4.54142 8.75813 5.075 9.22C3.67514 9.85388 2.48747 10.8775 1.65398 12.1685C0.820495 13.4596 0.376472 14.9633 0.375 16.5C0.375 16.7652 0.480357 17.0196 0.667893 17.2071C0.85543 17.3946 1.10978 17.5 1.375 17.5C1.64022 17.5 1.89457 17.3946 2.08211 17.2071C2.26964 17.0196 2.375 16.7652 2.375 16.5C2.375 14.9087 3.00714 13.3826 4.13236 12.2574C5.25758 11.1321 6.7837 10.5 8.375 10.5C9.9663 10.5 11.4924 11.1321 12.6176 12.2574C13.7429 13.3826 14.375 14.9087 14.375 16.5C14.375 16.7652 14.4804 17.0196 14.6679 17.2071C14.8554 17.3946 15.1098 17.5 15.375 17.5C15.6402 17.5 15.8946 17.3946 16.0821 17.2071C16.2696 17.0196 16.375 16.7652 16.375 16.5C16.3735 14.9633 15.9295 13.4596 15.096 12.1685C14.2625 10.8775 13.0749 9.85388 11.675 9.22ZM8.375 8.5C7.78166 8.5 7.20164 8.32405 6.70829 7.99441C6.21494 7.66476 5.83042 7.19623 5.60336 6.64805C5.3763 6.09987 5.31689 5.49667 5.43264 4.91473C5.5484 4.33279 5.83412 3.79824 6.25368 3.37868C6.67324 2.95912 7.20779 2.6734 7.78973 2.55764C8.37167 2.44189 8.97487 2.5013 9.52305 2.72836C10.0712 2.95542 10.5398 3.33994 10.8694 3.83329C11.1991 4.32664 11.375 4.90666 11.375 5.5C11.375 6.29565 11.0589 7.05871 10.4963 7.62132C9.93371 8.18393 9.17065 8.5 8.375 8.5Z"
        fill="#2A2A2B"
      />
      <path
        d="M18.043 11.5524C17.7379 11.8326 17.2635 11.8124 16.9833 11.5073L14.5726 8.88231C14.3091 8.59544 14.3091 8.15459 14.5726 7.86771L16.9833 5.24271C17.2635 4.93761 17.7379 4.91744 18.043 5.1976C18.3481 5.4778 18.3683 5.95221 18.0881 6.25731L16.8321 7.62501H21.875C22.2892 7.62501 22.625 7.96079 22.625 8.37501C22.625 8.78924 22.2892 9.12501 21.875 9.12501H16.8321L18.0881 10.4927C18.3683 10.7978 18.3481 11.2722 18.043 11.5524Z"
        fill="black"
      />
    </svg>
  )
}

export default RequestIcon

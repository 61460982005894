import React from 'react'

const SeperatorBreadcrumbs = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66667 4L10.1953 7.5286C10.4556 7.78894 10.4556 8.21106 10.1953 8.4714L6.66667 12" stroke="#919195" strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  )
}

export default SeperatorBreadcrumbs

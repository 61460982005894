export const shiftTypeData = [
  {
    id: 1,
    label: 'Per Diem',
    value: 'per_diem',
  },
  {
    id: 2,
    label: 'Long Term',
    value: 'long_term',
  },
  {
    id: 3,
    label: 'Travel',
    value: 'travel',
  },
]

export const isRepeatOnData = [
  {
    label: 'Same Day',
    value: 'same_day',
  },
  {
    label: 'Consecutive Days',
    value: 'consecutive_days',
  },
  {
    label: 'Consecutive Weeks',
    value: 'consecutive_weeks',
  },
  {
    label: 'Specific Dates',
    value: 'specific_dates',
  },
]

export const consecutiveWeeksData = [
  {
    label: 'S',
    value: 0,
  },
  {
    label: 'M',
    value: 1,
  },
  {
    label: 'T',
    value: 2,
  },
  {
    label: 'W',
    value: 3,
  },
  {
    label: 'T',
    value: 4,
  },
  {
    label: 'F',
    value: 5,
  },
  {
    label: 'S',
    value: 6,
  },
]

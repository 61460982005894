import React from 'react'

const AwardIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.87 17.25L18.16 12.57C18.7145 11.5515 19.0034 10.4097 19 9.25C19 7.39348 18.2625 5.61301 16.9497 4.30025C15.637 2.9875 13.8565 2.25 12 2.25C10.1435 2.25 8.363 2.9875 7.05025 4.30025C5.73749 5.61301 5 7.39348 5 9.25C4.99655 10.4097 5.28545 11.5515 5.84 12.57L3.13 17.25C3.04206 17.4023 2.99584 17.5751 2.99602 17.751C2.99619 17.9269 3.04275 18.0996 3.13099 18.2517C3.21923 18.4039 3.34603 18.53 3.4986 18.6175C3.65118 18.705 3.82412 18.7507 4 18.75H6.87L8.33 21.21C8.37921 21.2915 8.43983 21.3656 8.51 21.43C8.69537 21.6087 8.94253 21.709 9.2 21.71H9.34C9.49097 21.6893 9.63522 21.6345 9.76174 21.5495C9.88825 21.4646 9.99369 21.3519 10.07 21.22L12 17.9L13.93 21.25C14.0074 21.38 14.1134 21.4908 14.2398 21.5739C14.3663 21.6571 14.5099 21.7104 14.66 21.73H14.8C15.0609 21.7316 15.3121 21.6311 15.5 21.45C15.5673 21.3893 15.6246 21.3184 15.67 21.24L17.13 18.78H20C20.1762 18.7807 20.3495 18.7348 20.5023 18.647C20.655 18.5592 20.7819 18.4326 20.87 18.28C20.9634 18.1245 21.0128 17.9464 21.0128 17.765C21.0128 17.5836 20.9634 17.4055 20.87 17.25ZM9.19 18.78L8.3 17.29C8.21236 17.1422 8.08811 17.0194 7.93922 16.9336C7.79033 16.8478 7.62184 16.8018 7.45 16.8H5.72L7.15 14.32C8.13472 15.2689 9.37581 15.9085 10.72 16.16L9.19 18.78ZM12 14.25C11.0111 14.25 10.0444 13.9568 9.22215 13.4073C8.3999 12.8579 7.75904 12.077 7.3806 11.1634C7.00216 10.2498 6.90314 9.24445 7.09607 8.27455C7.289 7.30464 7.7652 6.41373 8.46446 5.71447C9.16373 5.0152 10.0546 4.539 11.0245 4.34607C11.9945 4.15315 12.9998 4.25216 13.9134 4.6306C14.827 5.00904 15.6079 5.6499 16.1573 6.47215C16.7068 7.29439 17 8.26109 17 9.25C17 10.5761 16.4732 11.8479 15.5355 12.7855C14.5978 13.7232 13.3261 14.25 12 14.25ZM16.55 16.8C16.3782 16.8018 16.2097 16.8478 16.0608 16.9336C15.9119 17.0194 15.7876 17.1422 15.7 17.29L14.81 18.78L13.29 16.13C14.6295 15.8734 15.866 15.2343 16.85 14.29L18.28 16.77L16.55 16.8Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default AwardIcon

import { useState } from 'react'
import { Form, message, Upload, type UploadProps } from 'antd'
import { UploadOutline } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import CustomSelect from 'component/Select/CustomSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import { BUTTON_STRING, COMMON, FACILITY_USER_PROFILE } from 'constants/Constants'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonSelectValidation } from 'utils/Validations'

const { Dragger } = Upload
const AddDocument = ({ isModalOpen }: any) => {
  const [error, setError] = useState({
    category: '',
    date: '',
  })
  const [form] = Form.useForm()
  const handleCancel = () => {
    isModalOpen(false)
  }
  const onFinish = (values: any) => {
    console.log(values)
  }

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        void message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        void message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  return (
    <Form
      className="flex flex-col items-start self-stretch add-document-wrapper"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <Dragger {...props}>
        <UploadOutline />
        <div className="flex flex-col items-center justify-center">
          <p className="text-neutral-500 para-p3 font-light">{FACILITY_USER_PROFILE.DRAG_TO_UPLOAD}</p>
          <p className="text-neutral-500 para-p3 font-light">{FACILITY_USER_PROFILE.DRAG_UPLOAD_MSG}</p>
        </div>
      </Dragger>
      {/* eslint-disable-next-line */}
      <div className={`grid grid-col-2 gap-4 w-full mt-6 ${error.category ?? error.date ? '' : 'mb-6'} `}>
        <div className="flex flex-col">
          <CustomSelect
            label={FACILITY_USER_PROFILE.DOCUMENT_CATEGORY}
            options={[{ value: 'contract', label: 'Contract' }]}
            name="category"
            error={error.category}
            rules={commonSelectValidation('document category')}
          />
        </div>
        <div className="flex flex-col">
          <CustomDatePickerField
            form={form}
            name="date"
            placeholder={FACILITY_USER_PROFILE.DATE_PERIOD_END}
            rules={commonSelectValidation('period ending date')}
            error={error.date}
          />
        </div>
      </div>
      <div className={`w-full`}>
        <CommonTextArea name="note" placeholder={COMMON.NOTE} rows={4} />
      </div>
      <div className="flex gap-4 w-full justify-end">
        <CustomButton type="default" text={BUTTON_STRING.CANCEL} className="w-160 h-49" onClick={handleCancel} />
        <CustomButton text={BUTTON_STRING.ADD} type="primary" htmlType="submit" className="h-49 w-160" />
      </div>
    </Form>
  )
}

export default AddDocument

import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import CustomButton from 'component/Button/CustomButton'
import CustomSelect from 'component/Select/CustomSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import { COMMON } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonSelectValidation } from 'utils/Validations'

import { CANCEL_SHIFT, GET_CANCEL_REASON } from '../api'
import type { ICancelReason, ICancelShift, ICancelShiftModal } from '../types'

const CancelShift = ({ id, setIsModalOpen, isCancel, setIsCancel }: ICancelShiftModal) => {
  const [error, setError] = useState({
    cancel_reason: '',
  })
  const [cancelReasons, setCancelReasons] = useState<ICancelReason[]>([])
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  useEffect(() => {
    void getCancelReasonList()
  }, [])

  const onFinish = async (values: ICancelShift) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await CANCEL_SHIFT(id, values)
      if (response?.data?.statusCode === 1) {
        setIsModalOpen(false)
        setIsCancel(!isCancel)
        void message.success(response?.data?.message)
      } else {
        void message.success(response?.data?.message)
      }
    } catch (error) {}
    dispatch(setGlobalLoader(false))
  }

  const getCancelReasonList = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_CANCEL_REASON()
      if (response?.data?.statusCode === 1) {
        setCancelReasons(response?.data?.data)
      } else {
        void message.error(response?.data?.message[0])
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleBackClick = () => {
    setIsModalOpen(false)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <CustomSelect
        label={COMMON.SELECT_REASON}
        options={cancelReasons?.map((item: { reason: string; id: string }) => ({
          label: item?.reason,
          value: item?.id,
        }))}
        name="cancel_reason"
        error={error.cancel_reason}
        rules={commonSelectValidation('cancel reason')}
      />
      <div className="w-full">
        <CommonTextArea name="cancel_reason_description" placeholder={COMMON.ADDITIONAL_DETAIL} rows={4} />
      </div>
      <div className="w-full flex justify-end pt-2 gap-4">
        <CustomButton type="default" text={COMMON.BACK} className="w-160 h-49" onClick={handleBackClick} />
        <CustomButton text={COMMON.CANCEL} type="text" htmlType="submit" className="cancel-shift-btn w-160 h-49" />
      </div>
    </Form>
  )
}

export default CancelShift

import React from 'react'

const HomeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 14V10H9.5V14H12.55V8H14.5L8 2L1.5 8H3.45V14H6.5Z" fill="#919195" />
    </svg>
  )
}

export default HomeIcon

import React from 'react'

const MinusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8333 9.1665H4.16659C3.94557 9.1665 3.73361 9.2543 3.57733 9.41058C3.42105 9.56686 3.33325 9.77882 3.33325 9.99984C3.33325 10.2209 3.42105 10.4328 3.57733 10.5891C3.73361 10.7454 3.94557 10.8332 4.16659 10.8332H15.8333C16.0543 10.8332 16.2662 10.7454 16.4225 10.5891C16.5788 10.4328 16.6666 10.2209 16.6666 9.99984C16.6666 9.77882 16.5788 9.56686 16.4225 9.41058C16.2662 9.2543 16.0543 9.1665 15.8333 9.1665Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default MinusIcon

import React, { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { CloseIcon, EditPenIcon, PlusIcon, RefreshIcon, RequestIcon, ThreeDotIcon, TimeCardIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, SHIFTS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/helper'

import { GET_SHIFTS } from './api'
import CancelShift from './CancelModal'
import CreateShift from './CreateShift'
import DetailShift from './DetailShift'

dayjs.extend(customParseFormat)

const Shifts = () => {
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [shiftData, setShiftData] = useState([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>('')
  const [tabId, setTabId] = useState<string>('')
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isOpenCancelModal, setIsCancelModal] = useState<boolean>(false)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  // eslint-disable-next-line
  const [selectedRowData, setSelectedRowData] = useState<any>()
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)
  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const [search, setSearch] = useState<string>('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.SHIFTS,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetShiftData(current, limit, sortTable?.order, sortTable?.field)
  }, [current, limit, sortTable, facilityId, search, isCancel, !isCancel, isCreateShiftModalOpen, !isEdit, selectedValue === 'per_diem'])

  const handleGetShiftData = async (page: number, pageSize: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    try {
      let response
      if (selectedValue === 'per_diem') response = await GET_SHIFTS(facilityId, page, pageSize, search, order, field)

      setShiftData(response?.data?.data)
      setTotal(selectedValue === 'per_diem' ? response?.data?.total : 0)
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCancelClick = (id: string) => {
    setIsCancelModal(true)
    setRowId(id)
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleDetailModal = (record: { id: string }) => {
    setRowId(record?.id)
    setIsDetailModalOpen(true)
  }

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (record.status === SHIFT_STATUS.REQUESTED ||
            record.status === SHIFT_STATUS.SCHEDULE ||
            record.status === SHIFT_STATUS.UNSUBMITTED ||
            record.status === SHIFT_STATUS.ON_GOING) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsDetailModalOpen(true)
                setRowId(record?.id)
                setTabId('4')
              }}
            >
              <p className="flex items-center gap-2">
                <RequestIcon />{' '}
                <span className="mobile-heading-h6 font-normal text-neutral-800">
                  {record.status === SHIFT_STATUS.UNSUBMITTED || record.status === SHIFT_STATUS.ON_GOING ? SHIFTS.VIEW_DETAILS : SHIFTS.VIEW_REQUEST}
                </span>
              </p>
            </div>
          ),
        },
        {
          label: record.status !== SHIFT_STATUS.OPEN &&
            record.status !== SHIFT_STATUS.REQUESTED &&
            record.status !== SHIFT_STATUS.UNCONFIRMED &&
            record.status !== SHIFT_STATUS.AUTO_SCHEDULING &&
            record.status !== SHIFT_STATUS.SCHEDULE &&
            record.status !== SHIFT_STATUS.UNSUBMITTED &&
            record.status !== SHIFT_STATUS.ON_GOING && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  setRowId(record?.id)
                  setIsCreateShiftModalOpen(true)
                }}
              >
                <p className="flex items-center gap-2">
                  <RefreshIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.REPOST}</span>{' '}
                </p>
              </div>
            ),
          key: 2,
        },
        {
          label: record.status === SHIFT_STATUS.COMPLETED && (
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center gap-2">
                <TimeCardIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.VIEW_TIMECARD}</span>
              </p>
            </div>
          ),
          key: 3,
        },
        {
          label: record.status !== SHIFT_STATUS.CANCELLED &&
            record.status !== SHIFT_STATUS.COMPLETED &&
            record.status !== SHIFT_STATUS.UNCONFIRMED &&
            record.status !== SHIFT_STATUS.AUTO_SCHEDULING &&
            record.status !== SHIFT_STATUS.UNSUBMITTED &&
            record.status !== SHIFT_STATUS.ON_GOING && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  setIsEdit(true)
                  setIsDetailModalOpen(true)
                  setRowId(record?.id)
                }}
              >
                <p className="flex items-center gap-2">
                  <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.EDIT_SHIFT}</span>
                </p>
              </div>
            ),
          key: 4,
        },
        {
          label: record.status !== SHIFT_STATUS.CANCELLED &&
            record.status !== SHIFT_STATUS.COMPLETED &&
            record.status !== SHIFT_STATUS.UNSUBMITTED &&
            record.status !== SHIFT_STATUS.ON_GOING && (
              <div
                className="p-4 cursor-pointer w-full"
                onClick={() => {
                  handleCancelClick(record?.id)
                }}
              >
                <p className="flex items-center gap-2">
                  <CloseIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.CANCEL_SHIFT}</span>
                </p>
              </div>
            ),
          key: 5,
        },
      ],
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.TABLE.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 's.shift_id',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: SHIFTS.TABLE.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 's.start_date',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <p>{dayjs(record?.start_date).format('MM/DD/YYYY')}</p>
      },
    },
    {
      title: SHIFTS.TABLE.SHIFT_TIME,
      dataIndex: 's.start_time',
      key: 's.start_time',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 350,
      render: (_value, record) => {
        // eslint-disable-next-line
        return <p className="status-text">{`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}</p>
      },
    },
    {
      title: SHIFTS.TABLE.LICENSE,
      dataIndex: 'c.name',
      key: 'c.name',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: record?.certificate?.background_color, color: record?.certificate?.text_color }}
              text={record?.certificate?.name}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.SPECIALTIES,
      dataIndex: 'sp.name',
      key: 'sp.name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }} classString="status" text={record?.speciality?.name} />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.STATUS,
      dataIndex: 's.status',
      key: 's.status',
      align: 'center',
      width: 250,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <div className="justify-center flex">
            <HeadingText
              classString={`common-tags ${record.status === SHIFT_STATUS.OPEN ? 'tertiary' : record.status === SHIFT_STATUS.CANCELLED ? 'error' : record.status === SHIFT_STATUS.COMPLETED ? 'neutral-tag' : record.status === SHIFT_STATUS.SCHEDULE ? 'success' : 'warning'}`}
              text={formatTitle(record?.status)}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.TABLE.NO_OF_REQUEST,
      dataIndex: 'total_requests',
      key: 'total_requests',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: COMMON.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <Dropdown
              overlayClassName="action-menu-dropdown"
              menu={getMenu(record)}
              trigger={['click']}
              onOpenChange={() => {
                setSelectedRowData(record)
              }}
            >
              <div className="btn-padding-8 three-dot-icon flex  border-action-menu">
                <ThreeDotIcon className="cursor-pointer svg" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]
  const handleCreateShift = () => {
    setIsCreateShiftModalOpen(true)
  }

  const tableButton = !isFacility && (
    <CustomButton isIcon svgIcon={<PlusIcon />} text={SHIFTS.CREATE_SHIFT} onClick={handleCreateShift} type="primary" className="rounded-3 border-0" />
  )

  const tabData = [
    { value: 'per_diem', label: 'Per Diem' },
    { value: 'long_term', label: 'Long Term' },
  ]

  return (
    <div>
      <CustomTable
        option={tabData}
        isTab
        isFilter
        isSearch
        searchPlaceholder={COMMON.SEARCH_SHIFT}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        tableButton={tableButton}
        column={columns}
        setModalOpen={handleDetailModal}
        data={shiftData}
        total={total}
        current={current}
        limit={limit}
        onChangePage={handleChangePage}
        className="shift-table"
        onChange={handleTableChange}
        name={SHIFTS.SHIFTS}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
      />

      {isDetailModalOpen && (
        <DetailShift
          setTabId={setTabId}
          tabId={tabId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isCancel={isCancel}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setIsCancel={setIsCancel}
          id={rowId}
          setRowId={setRowId}
          isDetailShiftOpen={isDetailModalOpen}
          setIsDetailShiftOpen={setIsDetailModalOpen}
        />
      )}
      {isOpenCancelModal && (
        <CustomModal
          onCancel={() => {
            setIsCancelModal(false)
          }}
          footer={null}
          closable={false}
          width={600}
          open={isOpenCancelModal}
          title={SHIFTS.CANCEL_SHIFT}
          content={<CancelShift id={rowId} isCancel={isCancel} setIsCancel={setIsCancel} setIsModalOpen={setIsCancelModal} />}
        />
      )}
      {isCreateShiftModalOpen && (
        <CreateShift rowId={rowId} setRowId={setRowId} isCreateShiftModalOpen={isCreateShiftModalOpen} setIsCreateShiftModalOpen={setIsCreateShiftModalOpen} />
      )}
    </div>
  )
}

export default Shifts

const CalenderCounterMinus = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6665 7.3335H3.33317C2.93317 7.3335 2.6665 7.60016 2.6665 8.00016C2.6665 8.40016 2.93317 8.66683 3.33317 8.66683H12.6665C13.0665 8.66683 13.3332 8.40016 13.3332 8.00016C13.3332 7.60016 13.0665 7.3335 12.6665 7.3335Z"
        fill="#919195"
      />
    </svg>
  )
}

export default CalenderCounterMinus

import React, { useState } from 'react'
import { Checkbox, type CheckboxProps, Form } from 'antd'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import { type ColumnsType } from 'antd/es/table'
import CustomButton from 'component/Button/CustomButton'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, PROVIDER_STRING, TABLE_COLUMNS } from 'constants/Constants'

const tableData = [
  {
    id: 1,
    shiftDate: '12/03/2023',
    shiftTime: '07:00 AM - 03:00 PM',
    certification: 'CNA',
    specialties: 'Assisted Living LTC Rehab',
    facility: 'Autumn Lane Health and Rehabilitation',
    match: '99%',
  },
  {
    id: 2,
    shiftDate: '12/04/2023',
    shiftTime: '03:00 PM - 11:00 PM',
    certification: 'LPN',
    specialties: 'Skilled Nursing LTC',
    facility: 'Maple Grove Nursing Home',
    match: '95%',
  },
]

const RequestProviderToWork = ({ setIsModalOpen }: any) => {
  const [form] = Form.useForm()
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = (values: any) => {
    console.log(checkedIds, values)
  }

  const onChange: CheckboxProps['onChange'] = (event: CheckboxChangeEvent) => {
    const id = event.target.value
    const checked = event.target.checked

    if (id === 'selectAll') {
      const allIds = tableData.map((item) => item.id.toString())
      if (checked) {
        setCheckedIds(allIds)
      } else {
        setCheckedIds([])
      }
      setSelectAll(checked)
    } else {
      setCheckedIds((prevIds) => (checked ? [...prevIds, id] : prevIds.filter((prevId) => prevId !== id)))
      const allIds = tableData.map((item) => item.id.toString())
      setSelectAll(allIds.length === checkedIds.length + (checked ? 1 : -1))
    }
  }

  const columns: ColumnsType = [
    {
      title: <Checkbox onChange={onChange} value="selectAll" checked={selectAll} />,
      dataIndex: 'check',
      key: 'id',
      align: 'center',
      width: 52,
      render(value, record) {
        return <Checkbox onChange={onChange} value={record.id.toString()} checked={checkedIds.includes(record.id.toString())} />
      },
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.SHIFT_DATE,
      dataIndex: 'shiftDate',
      key: 'shiftDate',
      align: 'center',
      width: 123,
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.SHIFT_TIME,
      dataIndex: 'shiftTime',
      key: 'shiftTime',
      align: 'center',
      width: 164,
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.LICENSE,
      dataIndex: 'certification',
      key: 'certification',
      align: 'center',
      width: 127,
      render: (_value, record) => {
        return (
          <div className="">
            <HeadingText classString={`status-text`} text={record.certification} />
          </div>
        )
      },
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.SPECIALTIES,
      dataIndex: 'specialties',
      key: 'specialties',
      align: 'center',
      width: 131,
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.FACILITY,
      dataIndex: 'facility',
      key: 'facility',
      align: 'center',
      width: 317,
    },
    {
      title: TABLE_COLUMNS.REQ_PROVIDER_WORK.MATCH,
      dataIndex: 'match',
      key: 'match',
      align: 'center',
      width: 85,
      render(value, record) {
        return (
          <div className="flex justify-center">
            <HeadingText classString={` shift-status scheduled`} text={record.match} />
          </div>
        )
      },
    },
  ]

  return (
    <Form className="req-for-work-container" form={form} onFinish={onFinish}>
      <div className="work-wrapper w-full">
        <div className="work-table-wrapper">
          <p className="para-p2 text-neutral-800 font-medium">{PROVIDER_STRING.OPEN_SHIFT}</p>
          <CustomTable column={columns} data={tableData} pagination={false} />
        </div>
      </div>
      <div className="flex justify-end gap-6 w-full">
        <CustomButton type="default" text={BUTTON_STRING.CREATE_NEW_SHIFT} className="w-200 h-49" onClick={onCancel} />
        <CustomButton text={BUTTON_STRING.SEND_REQUEST} type="primary" htmlType="submit" className="w-200 h-49" disabled={checkedIds.length === 0} />
      </div>
    </Form>
  )
}

export default RequestProviderToWork

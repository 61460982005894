import React from 'react'

const LogoIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.5736 14.0084H30.8893C26.6907 14.0084 22.8683 15.6132 20.0015 18.2464C23.2111 21.1921 25.2225 25.418 25.2225 30.1172C25.2225 26.9895 27.7586 24.4533 30.8863 24.4533H38.5706C39.3108 24.4533 39.9084 23.8557 39.9084 23.1155V15.3493C39.9084 14.6091 39.3108 14.0114 38.5706 14.0114L38.5736 14.0084Z"
        fill="#6111B9"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M20.0015 18.2435C17.1347 15.6133 13.3123 14.0055 9.11367 14.0055H1.42939C0.689177 14.0055 0.0915527 14.6031 0.0915527 15.3433V23.1095C0.0915527 23.8497 0.689177 24.4474 1.42939 24.4474H9.11367C12.2414 24.4474 14.7775 26.9835 14.7775 30.1112C14.7775 25.4121 16.7888 21.1862 19.9985 18.2405L20.0015 18.2435Z"
          fill="#59D1F4"
        />
      </g>
      <path
        d="M20.0015 18.2434C16.7919 21.1891 14.7806 25.415 14.7806 30.1141V37.398C14.7806 37.7681 14.9292 38.1018 15.1719 38.3445C15.2326 38.4052 15.2993 38.4598 15.3691 38.5083C15.5815 38.6539 15.8393 38.7358 16.1184 38.7358H23.8846C24.1607 38.7358 24.4185 38.6509 24.6339 38.5083C24.7037 38.4598 24.7704 38.4052 24.8311 38.3445C25.0738 38.1018 25.2225 37.7681 25.2225 37.398V30.1141C25.2225 25.415 23.2111 21.1891 20.0015 18.2434Z"
        fill="#220EB1"
      />
      <path
        d="M20.0015 1.26404C17.1043 1.26404 14.4863 2.69896 12.6084 5.01365C12.4416 5.21993 12.4052 5.52936 12.5144 5.78723L14.4529 9.83111C14.5591 10.0829 14.7836 10.2255 15.0141 10.1952C16.6523 9.97673 18.3178 9.86144 20.0045 9.86144C21.6912 9.86144 23.3567 9.97673 24.9949 10.1952C25.2255 10.2255 25.45 10.0829 25.5561 9.83111L27.4946 5.78723C27.6038 5.5324 27.5644 5.21993 27.4006 5.01365C25.5227 2.70199 22.9047 1.26404 20.0045 1.26404H20.0015Z"
        fill="#220EB1"
      />
    </svg>
  )
}

export default LogoIcon

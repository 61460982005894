import React, { useState } from 'react'
import { Checkbox, type CheckboxProps } from 'antd'
import { DownIcon } from 'assets/svgs'
import { FACILITY_USER_PROFILE } from 'constants/Constants'

const notificationData = [
  {
    id: 1,
    name: 'Shift',
    menu: [
      {
        id: 1,
        name: 'Shift cancellation',
        push: true,
        email: true,
      },
      {
        id: 2,
        name: 'New shift approval',
        push: true,
        email: true,
      },
      {
        id: 3,
        name: 'Shift rejected',
        push: true,
        email: true,
      },
    ],
  },
  {
    id: 2,
    name: 'Providers',
    menu: [
      {
        id: 1,
        name: 'Provider suggestion',
        push: true,
        email: true,
      },
      {
        id: 2,
        name: 'Time card saved & approved alert',
        push: true,
        email: true,
      },
      {
        id: 3,
        name: 'Provider flagged red/green alert',
        push: true,
        email: true,
      },
    ],
  },
  {
    id: 3,
    name: 'Billing',
    menu: [
      {
        id: 1,
        name: 'When the credits are going to over before the month end',
        push: true,
        email: true,
      },
      {
        id: 2,
        name: 'When the facility receives credits',
        push: true,
        email: true,
      },
      {
        id: 3,
        name: 'Client contract created',
        push: true,
        email: true,
      },
    ],
  },
  {
    id: 4,
    name: 'Facility Notification',
    menu: [
      {
        id: 1,
        name: 'Facility contacts created',
        push: true,
        email: true,
      },
      {
        id: 2,
        name: 'Facility contacts updated',
        push: true,
        email: true,
      },
    ],
  },
]

const Notification = () => {
  const [openMenus, setOpenMenus] = useState<Record<number, boolean>>({})

  const handleMenuToggle = (id: number) => {
    setOpenMenus((prevOpenMenus: Record<number, boolean>) => ({
      ...prevOpenMenus,
      [id]: !prevOpenMenus[id],
    }))
  }

  const onChange: CheckboxProps['onChange'] = (e) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.log(`checked = ${e.target.checked}`)
  }

  return (
    <div className=" w-full flex justify-center items-center px-5">
      <div className="flex w-full flex-col justify-center items-start gap-6 max-w-600">
        <p className="text-neutral-800 desktop-heading-h3 font-medium w-full">{FACILITY_USER_PROFILE.NOTIFICATION}</p>
        {notificationData.map((data) => {
          return (
            <div className="flex p-4 flex-col justify-center items-start gap-2 self-stretch rounded-4 bg-neutral-50" key={data.id}>
              <div
                className="cursor-pointer flex pr-4 justify-between items-center self-stretch "
                onClick={() => {
                  handleMenuToggle(data.id)
                }}
              >
                <div className="flex items-center gap-2">
                  <DownIcon />
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{data.name}</p>
                </div>
                <div className="flex items-center gap-4">
                  <p className="para-p3 text-neutral-800 font-normal">{FACILITY_USER_PROFILE.PUSH}</p>
                  <p className="para-p3 text-neutral-800 font-normal">{FACILITY_USER_PROFILE.EMAIL}</p>
                </div>
              </div>
              <div className={`menu-content overflow-hidden w-full ${openMenus[data.id] ? 'open' : 'closed'}`}>
                <div className="flex p-4 flex-col items-start gap-4 rounded-4 self-stretch bg-white">
                  {data.menu.map((item) => {
                    return (
                      <div className="flex justify-between items-center w-full" key={item.id}>
                        <p className="text-neutral-800 para-p3 font-normal">{item.name}</p>
                        <div className="flex items-center gap-8 pr-11">
                          <Checkbox onChange={onChange} checked={item.push} />
                          <Checkbox onChange={onChange} checked={item.email} />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Notification

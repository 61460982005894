import { message } from 'antd'
import axios, { type AxiosError } from 'axios'
import { UNAUTHORIZED_REQUEST } from 'constants/Constants'
import { type ApiErrorData } from 'service/Service'

import { type IUpdateProfileData } from '../types'

const config = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${String(process.env.REACT_APP_STATIC_TOKEN)}`,
  },
  timeout: 5000,
}

export const mediaUpload = async (media: FormData) => {
  const config = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // timeout: 5000,
  }
  try {
    const response = axios.post('/upload/image', media, config)
    return await response
  } catch (error: any) {
    void message.error(error?.response?.data?.message)
    if (error?.response.status === UNAUTHORIZED_REQUEST) {
      localStorage.clear()
      window.location.reload()
    }
  }
}
export const getFacilityProfile = async (id: string) => {
  try {
    const response = axios.get(`/auth/get-profile/facility?id=${id}`, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const updateFacilityProfile = async (id: string, payload: IUpdateProfileData) => {
  try {
    const response = axios.patch(`/facility/complete-profile/${id}`, payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}

export const searchCity = async (search: string) => {
  try {
    const response = axios.get(`/dropdown/search-city?search=${search}`, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}

const Dollar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M14.5 11H10.5C9.4 11 8.5 10.1 8.5 9C8.5 7.9 9.4 7 10.5 7H15.5C16.1 7 16.5 7.4 16.5 8C16.5 8.6 16.9 9 17.5 9C18.1 9 18.5 8.6 18.5 8C18.5 6.3 17.2 5 15.5 5H13.5V3C13.5 2.4 13.1 2 12.5 2C11.9 2 11.5 2.4 11.5 3V5H10.5C8.3 5 6.5 6.8 6.5 9C6.5 11.2 8.3 13 10.5 13H14.5C15.6 13 16.5 13.9 16.5 15C16.5 16.1 15.6 17 14.5 17H9.5C8.9 17 8.5 16.6 8.5 16C8.5 15.4 8.1 15 7.5 15C6.9 15 6.5 15.4 6.5 16C6.5 17.7 7.8 19 9.5 19H11.5V21C11.5 21.6 11.9 22 12.5 22C13.1 22 13.5 21.6 13.5 21V19H14.5C16.7 19 18.5 17.2 18.5 15C18.5 12.8 16.7 11 14.5 11Z"
        fill="#252431"
      />
    </svg>
  )
}

export default Dollar

import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, Form, Input, Select } from 'antd'
import { DownIcon, SearchIcon } from 'assets/svgs'

const CustomMultiSelect = ({ Options, placeholder, handleChange, icon, value, rules, name, isIcon, updatedTag, error, searchPlaceholder, isSearch }: any) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string>('')
  const [selected, setSelected] = useState<any[]>(value || [])
  const [filteredOptions, setFilteredOptions] = useState<any[]>(Options || [])

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectRef])

  useEffect(() => {
    if (updatedTag) {
      setSelected(updatedTag)
    }
  }, [updatedTag])

  useEffect(() => {
    setSelected(value || [])
  }, [value])

  const handleClickOutside = (event: { target: any }) => {
    const { current: selectRefCurrent } = selectRef

    if (selectRefCurrent && !selectRefCurrent.contains(event.target)) {
      const isElementClick = (classNames: string[]) => classNames.some((className) => event.target.classList.contains(className))
      const isSvg = event.target.className?.baseVal === ''

      const shouldCloseDropdown = ![
        'custom-searchbar',
        'anchor-search-wrapper',
        'ant-select-item-option-content',
        'ant-select-item',
        'ant-select-selector',
        'para-p3',
        'para-p2-regular',
        'ant-checkbox-input',
        'placeholder',
        'ant-checkbox-wrapper',
      ].some((className) => isElementClick([className]))

      if (shouldCloseDropdown && !isSvg) {
        setOpen(false)
      }
    }
  }

  const handleClickInside = (event: any) => {
    event.stopPropagation()
  }

  const onSearch = (value: string) => {
    setSearch(value.trim().toLowerCase())
    filterOptions(value.trim().toLowerCase())
  }

  const filterOptions = (input: string) => {
    const filtered = Options.filter((option: { name: string }) => option.name.toLowerCase().includes(input.toLowerCase()))
    setFilteredOptions(filtered)
  }

  const onInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  const handleSelectChange = (selectedValues: any) => {
    setSelected(selectedValues)
    handleChange(selectedValues)
  }

  return (
    <>
      <div ref={selectRef} className="dropdown-menu">
        <Form.Item rules={selected.length === 0 ? rules : []} name={name}>
          <Select
            showSearch={false}
            placeholder={
              <span className="flex g-10 items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2">
                {isIcon && icon} {placeholder}
              </span>
            }
            rootClassName={`dropdown-option-checkbox`}
            suffixIcon={<DownIcon />}
            maxTagCount={0}
            menuItemSelectedIcon={null}
            mode="multiple"
            variant="borderless"
            dropdownStyle={{ border: 'none', padding: '0px' }}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`text-neutral-800 w-full para-p3 text-neutral-700 rounded-3  ${Options?.some((e: { value: any }) => value?.includes(e?.value)) && 'active h-16'} ${selected?.length === 0 && error && 'status-error'} `}
            onChange={handleSelectChange}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setOpen(false)
              }
            }}
            value={selected}
            open={open}
            onClick={() => {
              setOpen(true)
              // Initially filter options based on current search input
              filterOptions(search)
            }}
            dropdownRender={(menu) => (
              <div>
                {isSearch && (
                  <div className="anchor-search-wrapper flex py-4 h-49 items-center">
                    <Input
                      prefix={<SearchIcon />}
                      placeholder={searchPlaceholder}
                      onPressEnter={() => {
                        onSearch(search)
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value)
                      }}
                      allowClear
                      className="custom-searchbar"
                      onKeyDown={onInputKeyDown}
                      onClick={handleClickInside}
                    />
                  </div>
                )}
                <div>{menu}</div>
              </div>
            )}
          >
            {search === ''
              ? Options.map((item: { value: React.Key | null | undefined; name: string }) => (
                  <Select.Option key={item?.value} value={item?.value} className="">
                    <div className="flex w-full gap-3">
                      <span className="flex justify-start gap-2 items-start">
                        <Checkbox checked={selected?.includes(item?.value)} />
                        <p style={{ paddingRight: '5px' }} className="para-p2-regular text-hidden whitespace-normal word-wrap break-words">
                          {item?.name}
                        </p>
                      </span>
                    </div>
                  </Select.Option>
                ))
              : filteredOptions?.map((item: { value: React.Key | null | undefined; name: string }) => (
                  <Select.Option key={item?.value} value={item?.value} className="">
                    <div className="flex w-full gap-3">
                      <span className="flex justify-start gap-2 items-start">
                        <Checkbox checked={selected?.includes(item?.value)} />
                        <p style={{ paddingRight: '5px' }} className="para-p2-regular text-hidden whitespace-normal word-wrap break-words">
                          {item?.name}
                        </p>
                      </span>
                    </div>
                  </Select.Option>
                ))}
          </Select>
          {placeholder && (
            <span
              className="common-input flex g-10 items-center items-center placeholder show-placeholder"
              onClick={() => {
                setOpen(true)
              }}
            >
              {isIcon && icon} {placeholder}
            </span>
          )}
        </Form.Item>
      </div>
      {updatedTag?.length === 0 && error && <p className="error-container mb-0">{error}</p>}
    </>
  )
}

export default CustomMultiSelect

// External
import { message } from 'antd'
import axios, { type AxiosError, type InternalAxiosRequestConfig } from 'axios'
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_REQUEST } from 'constants/Constants'
import { userToken } from 'utils/utils'

export interface ApiErrorData {
  message: string
}

// Internal

// Variable
export const CMS = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

CMS.interceptors.request.use((config) => {
  if (config.headers) {
    // if (accessToken) {
    config.headers.Authorization = `Bearer ${String(userToken())}`
    // } else {
    //   delete config.headers.Authorization
    // }
    config.headers['Content-Type'] = 'application/json'

    return config
  }
  return config
})

export const methods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  PUT: 'PUT',
}

// Function
export const service = async ({ url = '', data = {}, method = '', params = {} }) => {
  try {
    const res: any = await CMS({
      method,
      url,
      data,
      params,
    })
    return res
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if ((error?.response.status === UNAUTHORIZED_REQUEST || error?.response?.status === INTERNAL_SERVER_ERROR) && url !== '/auth/local') {
        localStorage.clear()
        window.location.reload()
        return ''
      }
      throw error?.response?.data?.error?.message
    }
  }
}

const generateRequestToken = (config: InternalAxiosRequestConfig) => {
  const { method, url, params, data } = config
  return `${String(method)}-${String(url)}-${JSON.stringify(params)}-${JSON.stringify(data)}`
}

const abortControllers = new Map<string, AbortController>()

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// Create a request interceptor for the instance and get accessToken
http.interceptors.request.use(
  async (config) => {
    // Attach an AbortController to the request
    const requestToken = generateRequestToken(config)
    const abortController = new AbortController()
    abortControllers.set(requestToken, abortController)
    config.signal = abortController.signal

    // Set timeout for the request
    config.timeout = 5000

    // Set Authorization header
    config.headers.Authorization = `Bearer ${String(userToken())}`
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiErrorData>) => {
    if (axios.isAxiosError(error) && error.response) {
      if (error?.response.status === UNAUTHORIZED_REQUEST) {
        localStorage.clear()
        window.location.reload()
        return ''
      } else if (error?.response.status === INTERNAL_SERVER_ERROR) {
        void message.error(error?.response?.data?.message)
      }
      throw error
    }
  }
)

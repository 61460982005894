import React, { useRef, useState } from 'react'
import { AutoComplete, Form } from 'antd'

import './AutoComplete.scss'

const CustomAutoComplete = ({ options, onSelect, onSearch, labelClass, error, placeholder, wrapperClass, inputClass, name, rules, textClass, onChange, value, ...props }: any) => {
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<any>(null)

  const handleFocus = () => {
    setFocused(true)
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(e.target.value !== '')
    if (props.onBlur) props.onBlur(e)
  }
  return (
    <>
      <div className={`${wrapperClass as string} autoComplete-field-wrapper ${!error ? 'mb-6' : ''}`}>
        <div className={`floatingLabelInput ${inputClass as string}`}>
          <label htmlFor={name} className={`label-float ${focused ? 'actives' : ''} ${labelClass as string}`}>
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules}>
            <AutoComplete
              value={value}
              options={options}
              onChange={onChange}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder=""
              ref={inputRef}
              className={`common-input ${textClass as string}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </>
  )
}

export default CustomAutoComplete

import React from 'react'

const HistoryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.4396 2C8.87596 2.00731 6.41306 2.99891 4.55957 4.77V3C4.55957 2.73478 4.45421 2.48043 4.26668 2.29289C4.07914 2.10536 3.82479 2 3.55957 2C3.29435 2 3.04 2.10536 2.85246 2.29289C2.66493 2.48043 2.55957 2.73478 2.55957 3V7.5C2.55957 7.76522 2.66493 8.01957 2.85246 8.20711C3.04 8.39464 3.29435 8.5 3.55957 8.5H8.05957C8.32479 8.5 8.57914 8.39464 8.76668 8.20711C8.95421 8.01957 9.05957 7.76522 9.05957 7.5C9.05957 7.23478 8.95421 6.98043 8.76668 6.79289C8.57914 6.60536 8.32479 6.5 8.05957 6.5H5.65957C6.57128 5.53701 7.70808 4.81576 8.9677 4.40114C10.2273 3.98651 11.5703 3.89152 12.8757 4.1247C14.1812 4.35788 15.4082 4.91193 16.4463 5.73699C17.4845 6.56205 18.3013 7.63226 18.8233 8.85133C19.3452 10.0704 19.5559 11.4001 19.4365 12.7208C19.317 14.0415 18.8711 15.3118 18.1388 16.4174C17.4066 17.5231 16.411 18.4293 15.2416 19.0547C14.0722 19.68 12.7657 20.0049 11.4396 20C11.1744 20 10.92 20.1054 10.7325 20.2929C10.5449 20.4804 10.4396 20.7348 10.4396 21C10.4396 21.2652 10.5449 21.5196 10.7325 21.7071C10.92 21.8946 11.1744 22 11.4396 22C14.0917 22 16.6353 20.9464 18.5106 19.0711C20.386 17.1957 21.4396 14.6522 21.4396 12C21.4396 9.34784 20.386 6.8043 18.5106 4.92893C16.6353 3.05357 14.0917 2 11.4396 2ZM11.4396 8C11.1744 8 10.92 8.10536 10.7325 8.29289C10.5449 8.48043 10.4396 8.73478 10.4396 9V12C10.4396 12.2652 10.5449 12.5196 10.7325 12.7071C10.92 12.8946 11.1744 13 11.4396 13H13.4396C13.7048 13 13.9591 12.8946 14.1467 12.7071C14.3342 12.5196 14.4396 12.2652 14.4396 12C14.4396 11.7348 14.3342 11.4804 14.1467 11.2929C13.9591 11.1054 13.7048 11 13.4396 11H12.4396V9C12.4396 8.73478 12.3342 8.48043 12.1467 8.29289C11.9591 8.10536 11.7048 8 11.4396 8Z"
        fill="#2A2A2B"
      />
    </svg>
  )
}

export default HistoryIcon

import React from 'react'

const RatingStart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        d="M29.8337 13.4663C29.967 12.7996 29.4337 11.9996 28.767 11.9996L21.167 10.9329L17.7003 3.99959C17.567 3.73293 17.4337 3.59959 17.167 3.46626C16.5003 3.06626 15.7003 3.33293 15.3003 3.99959L11.967 10.9329L4.36699 11.9996C3.96699 11.9996 3.70033 12.1329 3.56699 12.3996C3.03366 12.9329 3.03366 13.7329 3.56699 14.2663L9.03366 19.5996L7.70033 27.1996C7.70033 27.4663 7.70033 27.7329 7.83366 27.9996C8.23366 28.6663 9.03366 28.9329 9.70033 28.5329L16.5003 24.9329L23.3003 28.5329C23.4337 28.6663 23.7003 28.6663 23.967 28.6663C24.1003 28.6663 24.1003 28.6663 24.2337 28.6663C24.9003 28.5329 25.4337 27.8663 25.3003 27.0663L23.967 19.4663L29.4337 14.1329C29.7003 13.9996 29.8337 13.7329 29.8337 13.4663Z"
        fill="#FFAD4F"
      />
    </svg>
  )
}

export default RatingStart

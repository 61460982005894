import React from 'react'

const ParagraphIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M10.8337 11.7502H2.50033C2.27931 11.7502 2.06735 11.838 1.91107 11.9942C1.75479 12.1505 1.66699 12.3625 1.66699 12.5835C1.66699 12.8045 1.75479 13.0165 1.91107 13.1728C2.06735 13.329 2.27931 13.4168 2.50033 13.4168H10.8337C11.0547 13.4168 11.2666 13.329 11.4229 13.1728C11.5792 13.0165 11.667 12.8045 11.667 12.5835C11.667 12.3625 11.5792 12.1505 11.4229 11.9942C11.2666 11.838 11.0547 11.7502 10.8337 11.7502ZM17.5003 7.5835H2.50033C2.27931 7.5835 2.06735 7.67129 1.91107 7.82757C1.75479 7.98385 1.66699 8.19582 1.66699 8.41683C1.66699 8.63784 1.75479 8.8498 1.91107 9.00609C2.06735 9.16237 2.27931 9.25016 2.50033 9.25016H17.5003C17.7213 9.25016 17.9333 9.16237 18.0896 9.00609C18.2459 8.8498 18.3337 8.63784 18.3337 8.41683C18.3337 8.19582 18.2459 7.98385 18.0896 7.82757C17.9333 7.67129 17.7213 7.5835 17.5003 7.5835Z"
        fill={props.className ?? '#494A4C'}
      />
    </svg>
  )
}

export default ParagraphIcon

import { methods, service } from 'service/Service'

// import { type IRateProviderData } from 'types'
import { type IApproveData, type IMultiApprove, type IRejectTimecardData } from '../types'

export const GET_ALL_TIME_CARD = async (facilityId: string, order: string, field: string, page = 1, limit = 10, search: string) => {
  const offset = (page - 1) * limit

  const url = `/shift/timecards/${facilityId}?offset=${offset}&limit=${limit}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`
  return await service({ url, method: methods.GET })
}

export const GET_TIME_CARD_DETAIL = async (id: string) => {
  const url = `/shift/timecard-details/${id}`
  return await service({ url, method: methods.GET })
}

export const APPROVE_TIME_CARD = async (id: string, payload: IApproveData) => {
  const url = `/shift/approve-timecard/${id}`
  return await service({ url, method: methods.PATCH, data: payload })
}

export const REJECT_TIME_CARD = async (id: string, payload: IRejectTimecardData) => {
  const url = `/shift/reject-timecard/${id}`
  return await service({ url, method: methods.PATCH, data: payload })
}

// export const RATE_PROVIDER = async (payload: IRateProviderData) => {
//   const url = `/provider-review`
//   return await service({ url, method: methods.POST, data: payload })
// }

export const GET_REJECT_REASON = async () => {
  return await service({ url: `/dropdown/timecard-reject-reason`, method: methods.GET })
}

export const APPROVE_MULTI_TIME_CARD = async (payload: IMultiApprove) => {
  return await service({ url: `/shift/approve-multiple-timecard`, method: methods.PATCH, data: payload })
}

import React, { useEffect, useState } from 'react'
import { Form, message, Radio, type RadioChangeEvent } from 'antd'
import { CloseIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import HeadingText from 'component/HeadingText'
import CustomMultiSelect from 'component/Select/CustomMultiSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import { BUTTON_STRING, COMMON, PROVIDER_STRING } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonSelectFieldValidation } from 'utils/Validations'

import { UPDATE_FLAG_AS_DNR } from '../api'

const clinicalOption = [
  {
    value: 'Clinical competency issues',
    name: 'Clinical competency issues',
  },
  {
    value: 'Medication-related issues',
    name: 'Medication-related issues',
  },
  {
    value: 'Documentation-related issues',
    name: 'Documentation-related issues ',
  },
  {
    value: 'Patient abuse',
    name: 'Patient abuse',
  },
  {
    value: 'Patient safety issues',
    name: 'Patient safety issues',
  },
  {
    value: 'Credentialing',
    name: 'Credentialing  ',
  },
  {
    value: 'Client or Physician complaints about Health Care Professionals clinical competence',
    name: 'Client or Physician complaints about Health Care Professionals clinical competence',
  },
  {
    value: 'Health Care Professional incidents related to clinical competence',
    name: 'Health Care Professional incidents related to clinical competence',
  },
  {
    value: 'Non-performance of clinical assignment/duties',
    name: 'Non-performance of clinical assignment/duties',
  },
  {
    value: 'Other',
    name: 'Other',
  },
]

const professionalOption = [
  {
    value: 'Professional conduct issues',
    name: 'Professional conduct issues',
  },
  {
    value: 'Tardiness',
    name: 'Tardiness',
  },
  {
    value: 'No Shows',
    name: 'No Shows',
  },
  {
    value: 'Late cancellations',
    name: 'Late cancellations',
  },
  {
    value: 'Job abandonment',
    name: 'Job abandonment',
  },
  {
    value: 'Non-performance of job responsibilities',
    name: 'Non-performance of job responsibilities',
  },
  {
    value: 'Insubordination',
    name: 'Insubordination',
  },
  {
    value: 'Rule violation',
    name: 'Rule violation',
  },
  {
    value: 'Disturbed work unit balance',
    name: 'Disturbed work unit balance',
  },
  {
    value: 'Damaged business reputation',
    name: 'Damaged business reputation',
  },
  {
    value: 'Damaged property',
    name: 'Damaged property',
  },
  {
    value: 'Customer Service Issues',
    name: 'Customer Service Issues',
  },
  {
    value: 'Client or Physician complaints related to Health Care Professionals’ professional conduct',
    name: 'Client or Physician complaints related to Health Care Professionals’ professional conduct',
  },
  {
    value: 'Health Care Professional incidents related to professional conduct',
    name: 'Health Care Professional incidents related to professional conduct',
  },
  {
    value: 'Unlawful activities',
    name: 'Unlawful activities',
  },
  {
    value: 'Other',
    name: 'Other',
  },
]

const FlagAsDnr = ({
  isModalOpen,
  updateId,
  setFlagName,
}: {
  isModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateId: string
  setFlagName?: React.Dispatch<React.SetStateAction<string>>
}) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('clinical')
  const [clinicalTag, setClinicalTag] = useState<any[]>([])
  const [professionalTag, setProfessionalTag] = useState<any[]>([])
  const [error, setError] = useState({
    clinical: '',
    professional: '',
    description: '',
  })
  const [form] = Form.useForm()

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      clinical: clinicalTag?.length > 0 ? '' : prevError.clinical,
      professional: professionalTag?.length > 0 ? '' : prevError.professional,
    }))
  }, [clinicalTag, professionalTag])

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    setError({
      clinical: '',
      professional: '',
      description: '',
    })
  }

  const handleClinicalChange = (selectedValue: any[]) => {
    setClinicalTag(selectedValue)
  }

  const handleProfessionalChange = (selectedValue: any[]) => {
    setProfessionalTag(selectedValue)
  }

  const handleDeleteClinicalTag = (id: number) => {
    const updatedClinicalTag = [...clinicalTag]
    updatedClinicalTag.splice(id, 1)
    setClinicalTag(updatedClinicalTag)
  }

  const handleDeleteProfessionalTag = (id: number) => {
    const updatedProfessionalTag = [...professionalTag]
    updatedProfessionalTag.splice(id, 1)
    setProfessionalTag(updatedProfessionalTag)
  }

  const handleCancel = () => {
    isModalOpen(false)
  }

  const onFinish = (values: any) => {
    const updatedValues = {
      dnr_type: value,
      dnr_reason: value === 'clinical' ? clinicalTag : professionalTag,
      dnr_description: values?.description,
    }
    if (updateId) {
      void submitData(updatedValues)
    }
  }

  const submitData = async (updatedValues: any) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await UPDATE_FLAG_AS_DNR(updateId, updatedValues)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        if (setFlagName) setFlagName('dnr')
        isModalOpen(false)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error) {
      console.error('error')
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form
      className="flex flex-col w-full gap-2"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="flex flex-col w-full dnr-modal">
        <div className="radio-wrapper">
          <p className="para-p2 text-neutral-800 font-medium">{PROVIDER_STRING.REASON_FOR_DNR}</p>
          <div className="flex items-start gap-4 w-full mb-6 ">
            <Radio.Group onChange={onChange} value={value} rootClassName="flag-radio-button">
              <Radio value={PROVIDER_STRING.CLINICAL_VALUE} className="radio-placeholder">
                {PROVIDER_STRING.CLINICAL}
              </Radio>
              <Radio value={PROVIDER_STRING.PROFESSIONAL_VALUE} className="radio-placeholder">
                {PROVIDER_STRING.PROFESSIONAL}
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {value === PROVIDER_STRING.CLINICAL_VALUE && (
          <>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-full relative">
                <div className={`cursor-pointer relative`}>
                  <CustomMultiSelect
                    name="clinical"
                    handleChange={handleClinicalChange}
                    Options={clinicalOption}
                    placeholder={PROVIDER_STRING.SELECT_CLINICAL}
                    optionWidth={200}
                    updatedTag={clinicalTag}
                    rules={commonSelectFieldValidation('clinical reason')}
                    error={error?.clinical}
                  />
                </div>
              </div>
            </div>
            {clinicalTag?.length > 0 && (
              <div className="flex flex-col gap-4 w-full mb-6 mt-6">
                {clinicalTag?.length > 0 && <HeadingText text={PROVIDER_STRING.CLINICAL_REASON} classString="para-p4 text-neutral-400" />}
                <div className="flex gap-4 w-full flex-wrap">
                  {clinicalTag?.map((tagId: string, index: number) => {
                    const option = clinicalOption.find((item) => item.value === tagId)
                    return (
                      option?.name && (
                        <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                          {option.name}
                          <span
                            className="flex items-center cursor-pointer"
                            onClick={() => {
                              handleDeleteClinicalTag(index)
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      )
                    )
                  })}
                </div>
              </div>
            )}
          </>
        )}
        {value === PROVIDER_STRING.PROFESSIONAL_VALUE && (
          <>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-full relative">
                <div className={`cursor-pointer relative`}>
                  <CustomMultiSelect
                    name="professional"
                    handleChange={handleProfessionalChange}
                    Options={professionalOption}
                    placeholder={PROVIDER_STRING.SELECT_PROFESSIONAL}
                    updatedTag={professionalTag}
                    rules={commonSelectFieldValidation('professional reason')}
                    error={error?.professional}
                  />
                </div>
              </div>
            </div>
            {professionalTag?.length > 0 && (
              <div className="flex flex-col gap-4 w-full mb-6 mt-6">
                {professionalTag?.length > 0 && <HeadingText text={PROVIDER_STRING.PROFESSIONAL_REASON} classString="para-p4 text-neutral-400" />}
                <div className="flex gap-4 w-full flex-wrap">
                  {professionalTag?.map((tagId: string, index: number) => {
                    const option = professionalOption.find((item) => item.value === tagId)
                    return (
                      option?.name && (
                        <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                          {option?.name}
                          <span
                            className="flex items-center cursor-pointer"
                            onClick={() => {
                              handleDeleteProfessionalTag(index)
                            }}
                          >
                            {<CloseIcon />}
                          </span>
                        </div>
                      )
                    )
                  })}
                </div>
              </div>
            )}
          </>
        )}
        <div className={`w-full`}>
          <CommonTextArea name={'description'} rows={4} placeholder={COMMON.DESCRIPTION} />
        </div>
      </div>
      <div className="flex justify-end gap-4 w-full">
        <CustomButton type="default" onClick={handleCancel} text={BUTTON_STRING.CANCEL} className="w-160" />
        <CustomButton text={BUTTON_STRING.ADD} type="primary" htmlType="submit" className="w-160" />
      </div>
    </Form>
  )
}

export default FlagAsDnr

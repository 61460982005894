const CalenderCounterPlus = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6665 7.33317H8.6665V3.33317C8.6665 3.15636 8.59627 2.98679 8.47124 2.86177C8.34622 2.73674 8.17665 2.6665 7.99984 2.6665C7.82303 2.6665 7.65346 2.73674 7.52843 2.86177C7.40341 2.98679 7.33317 3.15636 7.33317 3.33317V7.33317H3.33317C3.15636 7.33317 2.98679 7.40341 2.86177 7.52843C2.73674 7.65346 2.6665 7.82303 2.6665 7.99984C2.6665 8.17665 2.73674 8.34622 2.86177 8.47124C2.98679 8.59627 3.15636 8.6665 3.33317 8.6665H7.33317V12.6665C7.33317 12.8433 7.40341 13.0129 7.52843 13.1379C7.65346 13.2629 7.82303 13.3332 7.99984 13.3332C8.17665 13.3332 8.34622 13.2629 8.47124 13.1379C8.59627 13.0129 8.6665 12.8433 8.6665 12.6665V8.6665H12.6665C12.8433 8.6665 13.0129 8.59627 13.1379 8.47124C13.2629 8.34622 13.3332 8.17665 13.3332 7.99984C13.3332 7.82303 13.2629 7.65346 13.1379 7.52843C13.0129 7.40341 12.8433 7.33317 12.6665 7.33317Z"
        fill="#919195"
      />
    </svg>
  )
}

export default CalenderCounterPlus

import { useEffect, useState } from 'react'
import { Button, Dropdown, Form, Image, type MenuProps, message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { type TableRowSelection } from 'antd/es/table/interface'
import { DatePickerIcon, ThreeDotIcon, TimeCardIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import HeadingText from 'component/HeadingText'
import CustomMultiSelect from 'component/Select/CustomMultiSelect'
import CustomTable from 'component/Table/CustomTable'
import TableImage from 'component/Table/TableImage'
import { BUTTON_STRING, PAGE_TITLE_STRING, SHIFTS, TIME_ATTENDANCE_STRING } from 'constants/Constants'
import { TIME_AND_ATTENDANCE_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/helper'
import { convertSecondsToHours } from 'utils/utils'

import RejectModal from './Modal/RejectTimeCard'
import ViewTimeCard from './Modal/ViewTimeCard'
import { APPROVE_MULTI_TIME_CARD, GET_ALL_TIME_CARD } from './api'
import { type IDataType } from './types'

import './TimeAndAttendance.scss'

const Signature = 'https://nurses-now-admin.solz.me/static/media/profile.d93c2f2f94cf3f1657d9.png'

const statusOption = [
  {
    value: 'approved',
    name: 'Approved',
  },
  {
    value: 'un_approved',
    name: 'Un Approved',
  },
  {
    value: 'rejected',
    name: 'Rejected',
  },
]

const TimeAndAttendance = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const [listData, setListData] = useState<IDataType[]>([])
  const [viewTimeCardData, setViewTimeCardData] = useState<IDataType[]>([])
  const [cardId, setCardId] = useState<string>('')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isSignature, setIsSignature] = useState(false)
  const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false)
  const [isSignatureErrorShow, setIsSignatureErrorShow] = useState<boolean>(false)
  const facilityId = useAppSelector((state: any) => state?.auth?.auth?.defaultFacility)
  const [current, setCurrent] = useState(1)
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [signatureUrl, setSignatureUrl] = useState('')

  const firstIndex = viewTimeCardData?.findIndex((item) => item?.timecard_status === TIME_AND_ATTENDANCE_STATUS.UNAPPROVED)
  const lastIndex = viewTimeCardData ? viewTimeCardData.map((item) => item?.timecard_status).lastIndexOf(TIME_AND_ATTENDANCE_STATUS.UNAPPROVED) : -1

  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [filterTable, setFilterTable] = useState<any>({
    status: [],
    from_date: '',
    to_date: '',
  })
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [statusTag, setStatusTag] = useState<string[]>([])

  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.TIME_AND_ATTENDANCE,
      })
    )
  }, [])

  const isButtonDisabled = selectedRows?.length === 0

  useEffect(() => {
    void handleGetAllTimeCard(current, limit, sortTable?.order, sortTable?.field, search, filterTable?.status, filterTable?.from_date, filterTable?.to_date)
  }, [isModalOpen, sortTable, facilityId, current, limit, isRejectOpen, search, filterTable])
  //   void handleGetAllTimeCard(sortTable?.order, sortTable?.field, current, limit)
  // }, [isModalOpen, sortTable, facilityId, current, limit, isRatingOpen, isRejectOpen, selectedRows])

  useEffect(() => {
    void handleGetAllTimeCardDetails(0, 0, sortTable?.order, sortTable?.field, search)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const modalWidth = screenWidth * 0.9

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleGetAllTimeCard = async (page: number, pageSize: number, order: string, field: string, search: string, status: string[], fromDate: string, toDate: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const res = await GET_ALL_TIME_CARD(facilityId, order, field, page, pageSize, search, status, fromDate, toDate)
      if (res?.status === 200) {
        setListData(res.data?.data)
        setTotal(res?.data?.total)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetAllTimeCardDetails = async (page: number, pageSize: number, order: string, field: string, search: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const res = await GET_ALL_TIME_CARD(facilityId, order, field, page, pageSize, search, [TIME_AND_ATTENDANCE_STATUS.UNAPPROVED], filterTable?.from_date, filterTable?.to_date)
      if (res?.status === 200) {
        setViewTimeCardData(res.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleReject = (index: number) => {
    // setIsModalOpen(!isSignature)
    setCurrentIndex(index)
    setIsModalOpen(false)
    setIsRejectOpen(true)
  }

  const rowSelection: TableRowSelection<IDataType> = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys.map((key) => String(key)))
    },
    getCheckboxProps: (record) => ({
      disabled: record.timecard_status === TIME_AND_ATTENDANCE_STATUS.APPROVED,
      style: { padding: '10px' },
    }),
  }

  const handleApproveClick = async () => {
    dispatch(setGlobalLoader(true))
    const payload = {
      shift_ids: selectedRows,
      authority_signature: Signature,
    }
    try {
      const response = await APPROVE_MULTI_TIME_CARD(payload)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        void handleGetAllTimeCard(current, limit, sortTable?.order, sortTable?.field, search, filterTable?.status, filterTable?.from_date, filterTable?.to_date)
        // Filter out approved rows from selectedRows
        const approvedRows = payload.shift_ids
        setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => !approvedRows.includes(row)))
        // setSelectedRows([])
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
    // setSelectedRows([])
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }
  // const handleRateClick = () => {
  //   setIsRatingOpen(true)
  // }

  const handleNextClick = () => {
    let nextIndex = currentIndex + 1
    // Continue to increment nextIndex until an unapproved card is found or end of list
    while (nextIndex < viewTimeCardData?.length && viewTimeCardData[nextIndex]?.timecard_status !== 'un_approved') {
      nextIndex++
    }
    // Check if the next index is within bounds and update state accordingly
    if (nextIndex < viewTimeCardData?.length) {
      setCurrentIndex(nextIndex)
      setCardId(viewTimeCardData[nextIndex]?.id)
      setStatus(viewTimeCardData[nextIndex]?.timecard_status)
    }
  }

  const handlePreviousClick = () => {
    let prevIndex = currentIndex - 1
    // Continue to decrement prevIndex until an unapproved card is found or start of list
    while (prevIndex >= 0 && viewTimeCardData[prevIndex]?.timecard_status !== 'un_approved') {
      prevIndex--
    }
    // Check if the previous index is within bounds and update state accordingly
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex)
      setCardId(viewTimeCardData[prevIndex]?.id)
      setStatus(viewTimeCardData[prevIndex]?.timecard_status)
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const getMenu = (record: IDataType, index: number): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper w-full p-1"
              key="1"
              onClick={() => {
                setCurrentIndex(index)
                setIsModalOpen(true)
                setCardId(record?.id)
                setStatus(record?.timecard_status)
                // setProviderId(record?.provider_id)
              }}
            >
              <p className="flex items-center gap-2 p-4">
                <TimeCardIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.VIEW_TIMECARD}</span>
              </p>
            </div>
          ),
        },
        // {
        //   key: 2,
        //   label: record.timecard_status === TIME_AND_ATTENDANCE_STATUS.APPROVED && (
        //     <div className="option-wrapper w-full p-1" key="2" onClick={handleRateClick}>
        //       <p className="flex items-center gap-2 p-4">
        //         <StarIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.RATE_PROVIDER}</span>
        //       </p>
        //     </div>
        //   ),
        // },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: TIME_ATTENDANCE_STRING.TABLE.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 'shift_id',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.shift_id - b.shift_id,
      align: 'center',
      width: 200,
      render(value, record) {
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={record?.shift_id} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.PROVIDER,
      dataIndex: 'provider',
      key: 'id',
      align: 'center',
      width: 220,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const name = record?.first_name + ' ' + record?.last_name
        return (
          <div className="flex">
            <TableImage name={name} email={record?.certificate_name} baseUrl={record?.base_url} image={record?.profile_image} imageClass={'rounded-full'} />
          </div>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.start_date - b.start_date,
      width: 120,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.start_date).format('MM/DD/YYYY')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.IN_TIME,
      dataIndex: 'clock_in',
      key: 'clock_in',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.clock_in - b.clock_in,
      width: 120,
      render: (_value, record) => {
        const formattedTime = dayjs(record?.clock_in, 'HH:mm:ss').format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.OUT_TIME,
      dataIndex: 'clock_out',
      key: 'clock_out',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.clock_out - b.clock_out,
      width: 130,
      render: (_value, record) => {
        const formattedTime = dayjs(record?.clock_out, 'HH:mm:ss').format('hh:mm A')
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={formattedTime} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.BREAK_TIME,
      dataIndex: 'break_duration',
      key: 'break_duration',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.break_duration - b.break_duration,
      width: 119,
      render: (_value, record) => {
        const formattedTime = convertSecondsToHours(record?.break_duration)

        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            <HeadingText classString="text-neutral-800 para-p3" text={`${formattedTime}`} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.HOURS,
      dataIndex: 'total_worked',
      key: 'total_worked',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.total_worked - b.total_worked,
      width: 100,
      render: (_value, record) => {
        const formattedTime = convertSecondsToHours(record?.total_worked)
        return (
          <span className="flex flex-col justify-center items-center gap-1 w-125">
            <HeadingText classString="text-neutral-800 para-p3" text={`${formattedTime}`} />
          </span>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.TIME_IMAGE,
      dataIndex: 'time_sheet',
      key: 'time_sheet',
      align: 'center',
      width: 160,
      render: (_value, record) => {
        const timesheetImages = record?.time_sheet?.map((path: string) => `${record?.base_url as string}${path}`)
        const extraCount = timesheetImages?.length - 3

        return (
          <div className="flex justify-center min-w-120">
            {record?.time_sheet ? (
              <Image.PreviewGroup items={timesheetImages}>
                {timesheetImages?.slice(0, 3).map((src: string, index: number) => (
                  <div key={index} className="image-wrapper">
                    <Image width={40} height={40} className="rounded" src={src} />
                    {index === 2 && extraCount > 0 && <div className="overlay">+{extraCount}</div>}
                  </div>
                ))}
              </Image.PreviewGroup>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.FLOOR,
      dataIndex: 'fl.name',
      key: 'fl.name',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.floor_name - b.floor_name,
      width: 200,
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.STATUS,
      dataIndex: 'timecard_status',
      key: 'timecard_status',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.timecard_status - b.timecard_status,
      width: 130,
      render: (_value, record) => {
        return (
          <div className=" justify-center flex item-center w-full min-w-120">
            <HeadingText
              classString={`status-text ${record?.timecard_status === TIME_AND_ATTENDANCE_STATUS.REJECTED ? 'rejected' : record?.timecard_status === TIME_AND_ATTENDANCE_STATUS.APPROVED ? 'approved' : 'unapproved'} `}
              text={formatTitle(record?.timecard_status)}
            />
          </div>
        )
      },
    },
    {
      title: TIME_ATTENDANCE_STRING.TABLE.ACTION,
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 200,
      render: (_value, record: any, index: number) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName="table-action-dropdown action-dropdown"
              menu={getMenu(record, index)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
              trigger={['click']}
            >
              <CustomButton isIcon svgIcon={<ThreeDotIcon className="cursor-pointer user-icon" />} text="" className="p-1-imp w-fit h-fit three-dot-icon" type={'default'} />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const TableButton = (
    <Button type="primary" className="rounded-3 border-0 h-49" disabled={isButtonDisabled} onClick={handleApproveClick}>
      {TIME_ATTENDANCE_STRING.APPROVE_SEL}
    </Button>
  )

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const handleDrawerOpenChange = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen)
  }

  const applyFilter = (value?: { license?: string; speciality?: string; from_date?: string; to_date?: string }, isReset?: boolean) => {
    isReset && setStatusTag([])
    setFilterTable({
      status: isReset ? [] : (statusTag ?? []),
      from_date: value?.from_date ? (dayjs(value?.from_date).format('YYYY-MM-DD') ?? '') : '',
      to_date: value?.to_date ? (dayjs(value?.to_date).format('YYYY-MM-DD') ?? '') : '',
    })
    setIsDrawerOpen(false)
  }

  const handleStatusChange = (selectedValue: any[]) => {
    setStatusTag(selectedValue)
  }

  const filterOptions = (
    <div className="h-full">
      <Form form={form} onFinish={applyFilter} className="h-full flex flex-col justify-between">
        <div className="flex flex-col gap-5">
          <CustomMultiSelect name="status" handleChange={handleStatusChange} Options={statusOption} placeholder={SHIFTS.STATUS} optionWidth={200} updatedTag={statusTag} />
          <CustomDatePickerField name="from_date" placeholder={SHIFTS.FORM_DATE} icon={<DatePickerIcon />} form={form} value={getValue('from_date')} wrapperClass="mt-6" />
          <CustomDatePickerField name="to_date" placeholder={SHIFTS.TO_DATE} icon={<DatePickerIcon />} form={form} value={getValue('to_date')} />
        </div>
        <div className="flex gap-2">
          <CustomButton
            onClick={() => {
              applyFilter({}, true)
            }}
            text={BUTTON_STRING.RESET}
            className="bg-primary-50 text-primary-600 border-0 h-49 w-160"
            htmlType="reset"
            type="default"
          />
          <CustomButton text={BUTTON_STRING.APPLY} htmlType="submit" className="h-49 w-160" />
        </div>
      </Form>
    </div>
  )

  const handleReset = () => {
    form.resetFields()
    setStatusTag([])
    setFilterTable({
      status: [],
      from_date: '',
      to_date: '',
    })
  }

  return (
    <div className="time-and-attendance-wrapper flex flex-col items-start gap-6 self-stretch">
      <CustomTable
        column={columns}
        data={listData}
        rowSelected={rowSelection}
        total={total}
        limit={limit}
        search={search}
        isSearch
        isFilter
        current={current}
        onChange={handleTableChange}
        onChangePage={handleChangePage}
        name={TIME_ATTENDANCE_STRING.ENTRIES}
        tableButton={TableButton}
        searchPlaceholder={SHIFTS.SEARCH_PRO}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        showFilterDrawer={isDrawerOpen}
        filterOption={filterOptions}
        filterSelected={filterTable}
        onDrawerOpenChange={handleDrawerOpenChange}
        handleReset={handleReset}
      />
      {isModalOpen && (
        <CustomModal
          width={modalWidth}
          open={isModalOpen}
          footer={null}
          className="hide-closeicon-modal timecard-modal"
          onCancel={() => {
            setCurrentIndex(0)
            setIsModalOpen(false)
          }}
          content={
            <ViewTimeCard
              signatureUrl={signatureUrl}
              setSignatureUrl={setSignatureUrl}
              setIsModalOpen={setIsModalOpen}
              // setIsRating={setIsRatingOpen}
              handleRejectClick={handleReject}
              cardId={cardId}
              status={status}
              next={handleNextClick}
              previous={handlePreviousClick}
              isSignature={isSignature}
              setIsSignature={setIsSignature}
              index={currentIndex}
              isSignatureErrorShow={isSignatureErrorShow}
              setIsSignatureErrorShow={setIsSignatureErrorShow}
              firstIndex={firstIndex}
              lastIndex={lastIndex}
            />
          }
        />
      )}
      {isRejectOpen && (
        <CustomModal
          width={600}
          open={isRejectOpen}
          footer={null}
          title={TIME_ATTENDANCE_STRING.REJECT_TIME_CARD}
          className="hide-closeicon-modal timecard-modal"
          onCancel={() => {
            setIsRejectOpen(false)
          }}
          content={<RejectModal setIsViewTimecardOpen={setIsModalOpen} signatureUrl={signatureUrl} setIsModalOpen={setIsRejectOpen} cardId={cardId} />}
        />
      )}
      {/* {isRatingOpen && (
        <CustomModal
          width={600}
          open={isRatingOpen}
          footer={null}
          className="hide-closeicon-modal rate-provider-modal"
          onCancel={() => {
            setIsRatingOpen(false)
          }}
          content={<RateProvider setIsModalOpen={setIsRatingOpen} cardId={cardId} providerID={providerID} />}
        />
      )} */}
    </div>
  )
}

export default TimeAndAttendance

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Progress } from 'antd'
import { setGlobalLoader } from 'store/slice/CommonSlice'

import { getFacilityProfile } from './api/index'
import CompleteProfile from './Steps/CompleteProfile'
import FacilityAddress from './Steps/FacilityAddress'
import ProfilePic from './Steps/ProfilePic'
import WelcomeCard from './Steps/WelcomeCard'

import './Welcome.scss'

const WelcomeScreen = () => {
  const [currentPage, setCurrentPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [facilityData, setFacilityData] = useState<any>()
  const totlaPage = 3
  const totalCase = 4
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') ?? ''
  const dispatch = useDispatch()

  useEffect(() => {
    void getFacilityData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleBack = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
    if (currentPage - 1 === totlaPage) {
      setCurrentPage(currentPage)
    }
  }

  const getFacilityData = async (ids: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFacilityProfile(ids)
    if (response?.status === 200) {
      setFacilityData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const progressPercentage = Math.floor((currentPage / totalCase) * 100)

  const renderFormPage = () => {
    switch (currentPage) {
      case 1:
        return <WelcomeCard onClick={handleNext} pageNo={currentPage} />
      case 2:
        return <CompleteProfile onClick={handleNext} pageNo={currentPage} back={handleBack} totalPageNo={totlaPage} facilityData={facilityData} />
      case 3:
        return <ProfilePic baseUrl={facilityData?.base_url} onClick={handleNext} pageNo={currentPage} back={handleBack} totalPageNo={totlaPage} />
      case 4:
        return <FacilityAddress onClick={handleNext} pageNo={currentPage} back={handleBack} totalPageNo={totlaPage} />
      default:
        return null
    }
  }

  return (
    <div className="min-h-screen bg-neutral-50 relative pb-10">
      <div className="w-full flex flex-col items-center h-full">
        <Progress percent={progressPercentage} showInfo={false} rootClassName="welcome-progress-bar" />
        {renderFormPage()}
      </div>
    </div>
  )
}

export default WelcomeScreen

// builtin
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import DashBoard from 'pages/DashBoard'
import FacilityContacts from 'pages/FacilityContact'
import FacilityProfile from 'pages/FacilityProfile/FacilityProfile'
import Login from 'pages/Login'
import ForgotPassword from 'pages/Login/ForgotPassword'
import LinkExpiry from 'pages/Login/LinkExpiry'
import Providers from 'pages/Provider'
import ProviderProfile from 'pages/Provider/ProviderProfile'
import Shifts from 'pages/Shifts'
import SignUp from 'pages/SignUp'
import EmailVerification from 'pages/SignUp/EmailVarification/EmailVarification'
import VerifyScreen from 'pages/SignUp/EmailVarification/VerifyScreen'
import FacilityUserSignUp from 'pages/SignUp/FacilityUserSignUp'
import SetPassword from 'pages/SignUp/SetPassword'
import TimeAndAttendance from 'pages/TimeAndAttendance'
import UserProfile from 'pages/UserProfile/UserProfile'
import WelcomeScreen from 'pages/Welcome/Welcome'

import PrivateRoute from './PrivateRoutes'
import PublicRoute from './PublicRoutes'

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/facility-user-login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verify" element={<VerifyScreen />} />
        <Route path="/facility-user-register" element={<FacilityUserSignUp />} />
        <Route path="/welcome" element={<WelcomeScreen />} />
        <Route path="/link-expiry" element={<LinkExpiry />} />
      </Route>

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<DashBoard />} />
        <Route path="/facility-contacts" element={<FacilityContacts />} />
        <Route path="/shifts" element={<Shifts />} />
        <Route path="/facility-profile" element={<FacilityProfile />} />
        <Route path="/time-and-attendance" element={<TimeAndAttendance />} />
        <Route path="/my-profile" element={<UserProfile />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/providers/provider-profile" element={<ProviderProfile />} />
      </Route>

      {/* page not found */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default AppRoutes

import { message } from 'antd'
import axios, { type AxiosError } from 'axios'
import { type ApiErrorData } from 'service/Service'

import { type IFacilityUserSignUp, type ISignupForm } from '../types'

const config = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${String(process.env.REACT_APP_STATIC_TOKEN)}`,
  },
  timeout: 5000,
}

export const signUp = async (payload: ISignupForm) => {
  try {
    const response = await axios.post('/auth/facility/signup', payload, config)
    return response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred')
    }
    return undefined
  }
}

export const getProfileFacilityUser = async (id: string) => {
  try {
    const response = axios.get(`/auth/get-profile/facility_user?id=${id}`, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      console.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const updateProfileFacilityUser = async (payload: IFacilityUserSignUp, id: string) => {
  try {
    const response = axios.patch(`/auth/accept-invitation?table=facility_user&id=${id}`, payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

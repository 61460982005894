import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import CustomButton from 'component/Button/CustomButton'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, PROVIDER_STRING, SHIFTS, TABLE_COLUMNS } from 'constants/Constants'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { GET_SHIFTS } from 'pages/Shifts/api'
import CreateShiftFromProvider from 'pages/Shifts/CreateShift/CreateShiftFromProvider'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IShift, type IShiftProvider } from 'types'
import { formatQuerry } from 'utils/utils'

import { requestToWork } from '../api'

const RequestProviderToWork = ({ setIsModalOpen, selectedRowData }: { setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>; selectedRowData: IShiftProvider | null }) => {
  const [form] = Form.useForm()
  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)
  const [shiftData, setShiftData] = useState<IShift[]>([])
  const dispatch = useAppDispatch()
  // checkbox
  const [openCheckedIds, setOpenCheckedIds] = useState<React.Key[]>([])
  const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState(false)

  const onFinish = () => {
    if (selectedRowData?.provider?.id) {
      void handleRequestToWork(selectedRowData?.provider?.id, [...openCheckedIds])
    }
  }

  const handleRequestToWork = async (id: string, shift: React.Key[]) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await requestToWork(id, { shift })
      if (response?.data?.statusCode === 1) {
        void message?.success(response?.data?.message)
        setIsModalOpen(false)
      }
    } catch (err) {
      console.log(err)
    }
    dispatch(setGlobalLoader(false))
  }
  const handleGetShiftData = async () => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))
      try {
        const params = {
          [`status[0]`]: SHIFT_STATUS.OPEN,
        }
        const query = formatQuerry({ ...params })
        const response = await GET_SHIFTS(facilityId, 1, 0, '', '', '', '', '', '', '', [], query)
        if (response?.data?.statusCode === 1) {
          setShiftData(response?.data?.data)
        }
      } catch (err) {
        console.log(err)
      }
      dispatch(setGlobalLoader(false))
    }
  }
  useEffect(() => {
    void handleGetShiftData()
    // eslint-disable-next-line
  }, [])

  const getColumns = useCallback(() => {
    const columns: ColumnsType = [
      {
        title: SHIFTS.TABLE.SHIFT_DATE,
        dataIndex: 'start_date',
        key: 's.start_date',
        align: 'center',
        width: 200,
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        className: 'center-align',
        render: (_value, record) => {
          return <p>{dayjs(record?.start_date).format('MM/DD/YYYY')}</p>
        },
      },
      {
        title: SHIFTS.TABLE.SHIFT_TIME,
        dataIndex: 's.start_time',
        key: 's.start_time',
        align: 'center',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        width: 350,
        className: 'center-align',
        render: (_value, record) => {
          // eslint-disable-next-line
          return <p className="status-text">{`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}</p>
        },
      },
      {
        title: SHIFTS.TABLE.LICENSE,
        dataIndex: 'c.name',
        key: 'c.name',
        align: 'center',
        width: 200,
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        className: 'center-align',
        render: (_value, record) => {
          return (
            <div className="flex justify-center">
              <HeadingText
                classString="status"
                style={{ background: record?.certificate?.background_color, color: record?.certificate?.text_color }}
                text={record?.certificate?.name}
              />
            </div>
          )
        },
      },
      {
        title: SHIFTS.TABLE.SPECIALTIES,
        dataIndex: 'sp.name',
        key: 'sp.name',
        align: 'center',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        width: 200,
        className: 'center-align',
        render: (_value, record) => {
          return (
            <div className="flex justify-center">
              <HeadingText
                style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }}
                classString="status"
                text={record?.speciality?.name}
              />
            </div>
          )
        },
      },
      {
        title: TABLE_COLUMNS.REQ_PROVIDER_WORK.FACILITY,
        dataIndex: ['facility', 'name'],
        key: 'facility.name',
        align: 'center',
        width: 317,
      },
      {
        title: TABLE_COLUMNS.REQ_PROVIDER_WORK.MATCH,
        dataIndex: 'match',
        key: 'match',
        align: 'center',
        width: 85,
        render(value, record) {
          return (
            <div className="flex justify-center">
              <HeadingText classString={` shift-status scheduled`} text={record?.match ?? '-'} />
            </div>
          )
        },
      },
    ]
    return columns
  }, [])

  const memoizedColumns = useMemo(() => getColumns(), [getColumns])

  const getRowSelection = (checkedIds: React.Key[], setCheckedIds: React.Dispatch<React.SetStateAction<React.Key[]>>) => ({
    selectedRowKeys: checkedIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      setCheckedIds(selectedRowKeys)
    },
    getCheckboxProps: (record: IShift) => ({
      disabled: ![SHIFT_STATUS.OPEN, SHIFT_STATUS.UNCONFIRMED, SHIFT_STATUS.AUTO_SCHEDULING, SHIFT_STATUS.REQUESTED]?.includes(record?.status), // Customize based on conditions
    }),
  })

  const renderShiftTable = (data: IShift[], title: string, checkedIds: React.Key[], setCheckedIds: React.Dispatch<React.SetStateAction<React.Key[]>>) => (
    <div className="w-full">
      <p className="para-p2 text-neutral-800 font-medium mb-2">{title}</p>
      <div className="work-wrapper w-full">
        <div className="work-table-wrapper">
          <CustomTable rowSelected={getRowSelection(checkedIds, setCheckedIds)} column={memoizedColumns} data={data} pagination={false} />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Form className="req-for-work-container" form={form}>
        {shiftData?.length > 0 && renderShiftTable(shiftData, PROVIDER_STRING.OPEN_SHIFT, openCheckedIds, setOpenCheckedIds)}
        <div className="flex justify-end gap-6 w-full">
          {!isFacility && (
            <CustomButton
              type="default"
              text={BUTTON_STRING.CREATE_NEW_SHIFT}
              className="w-200 h-49"
              onClick={() => {
                setIsCreateShiftModalOpen(true)
              }}
            />
          )}
          <CustomButton text={BUTTON_STRING.SEND_REQUEST} type="primary" onClick={onFinish} className="w-200 h-49" disabled={openCheckedIds?.length === 0} />
        </div>
      </Form>
      {isCreateShiftModalOpen && (
        <CreateShiftFromProvider
          data={selectedRowData}
          isCreateShiftModalOpen={isCreateShiftModalOpen}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          updateShiftList={() => {
            void handleGetShiftData()
          }}
        />
      )}
    </>
  )
}

export default RequestProviderToWork

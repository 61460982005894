import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { DownIcon, ForgotPasswordIcon, GmailLogo, LogoIcon, OutlookLogo } from 'assets/svgs'
import { COMMON, SIGNUP } from 'constants/Constants'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectSignUp } from 'store/slice/SignupDataSlice'

const VerifyScreen = () => {
  const navigate = useNavigate()
  const formData = useAppSelector(selectSignUp)

  useEffect(() => {
    if (formData?.email === '') {
      navigate('/signup')
    }
  }, [formData, navigate])

  const handleBackClick = () => {
    navigate('/email-verification')
  }

  return (
    <div className="bg-neutral-50 h-screen relative">
      <div className="back-btn-wrapper flex justify-center items-center p-2" onClick={handleBackClick}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="bg-neutral-50 mx-auto max-w-400 w-full pt-140 flex flex-col gap-10 items-center">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <div className="flex flex-col items-center gap-1">
            <p className="desktop-heading-h3 font-medium">{COMMON.CHECK_EMAIL}</p>
            <p className="text-neutral-500 text-center para-p2">
              {SIGNUP.SEND_LINK} <span className="text-neutral-800">{formData?.email}</span> {SIGNUP.VERIFY_EMAIL}
            </p>
          </div>
        </div>

        <ForgotPasswordIcon />

        <div className="flex gap-7 w-full">
          <Link to="https://mail.google.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <GmailLogo />
              </span>
              {COMMON.OPEN_MAIL}
            </Button>
          </Link>
          <Link to="https://outlook.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <OutlookLogo />
              </span>
              {COMMON.OPEN_OUTLOOK}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default VerifyScreen

import { useEffect, useState } from 'react'
import { message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import TableImage from 'component/Table/TableImage'
import { PER_DIEM_STATUS, PROVIDER_STRING, SHIFTS, TABLE_COLUMNS } from 'constants/Constants'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/helper'

import { GET_SHIFT_HISTROY } from '../api'

const ShiftHistory = ({ providerId, tabNo }: { providerId: string; tabNo: string }) => {
  const dispatch = useAppDispatch()
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [listData, setListData] = useState([])

  useEffect(() => {
    if (providerId && tabNo === '4') {
      void getShiftHistoryData(current, limit, sortTable?.order, sortTable?.field)
    }
  }, [providerId, tabNo, sortTable, current, limit])

  const getShiftHistoryData = async (page: number, pageSize: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_SHIFT_HISTROY(providerId, page, pageSize, order, field)
      if (response?.status === 200) {
        setListData(response?.data?.data)
        setTotal(response?.data?.total)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 'shift_id',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 110,
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.FACILITY,
      dataIndex: 'f.name',
      key: 'f.name',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 293,
      render: (_value, record) => {
        return <HeadingText text={record?.facility?.name ?? ''} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 118,
      render: (_value, record) => {
        return <HeadingText text={`${dayjs(record?.start_date).format('YYYY-MM-DD')}`} classString={'text-neutral-800 activity-text font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.SHIFT_TIME,
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 159,
      render: (_value, record) => {
        return (
          <HeadingText
            text={`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}
            classString={'text-neutral-800 activity-text font-normal'}
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.STATUS,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 127,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString={`font-medium shift-status ${record.status === PER_DIEM_STATUS.CANCELLED ? 'cancelled' : record.status === PER_DIEM_STATUS.COMPLETED ? 'scheduled' : 'warning'} `}
              text={formatTitle(record.status)}
            />
          </div>
        )
      },
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.TYPE,
      dataIndex: 'shift_type',
      key: 'shift_type',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 160,
      render: (_value, record) => {
        return (
          <HeadingText
            text={record?.shift_type === 'per_diem' ? SHIFTS.PER_DIEM : record.shift_type === 'long_term' ? SHIFTS.LONG_TERM : SHIFTS.TRAVEL}
            classString={'text-neutral-800 activity-text font-normal'}
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.SHIFTS_HISTORY.CREATED_BY,
      dataIndex: 'created_by',
      key: 'created_by',
      align: 'center',
      sorter: (a, b) => {
        if (a.created_by?.name < b.created_by?.name) return -1
        if (a.created_by?.name > b.created_by?.name) return 1
        return 0
      },
      sortDirections: ['ascend', 'descend'],
      className: 'center-align-sortedfield',
      width: 185,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <TableImage baseUrl={record?.created_by?.base_url} image={record?.created_by?.image} name="" imageToolTip={record?.created_by?.name} />
          </div>
        )
      },
    },
  ]

  return (
    <div className="shift-history w-full px-10 pb-10">
      <CustomTable
        column={columns}
        total={total}
        current={current}
        limit={limit}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        data={listData}
        name={PROVIDER_STRING.SHIFT}
      />
    </div>
  )
}

export default ShiftHistory

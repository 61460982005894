/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd'
import axios, { type AxiosError } from 'axios'

import type { ILoginForm } from '../types'

import { type ApiErrorData } from './Service'

const config = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${String(process.env.REACT_APP_STATIC_TOKEN)}`,
  },
  timeout: 5000,
}

export const FACILITY_LOGIN = async (apiLink: string, payload: ILoginForm) => {
  try {
    const response = axios.post(apiLink, payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<any>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message ?? 'Something Went Wrong')
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const FORGOT_PASSWORD = async (payload: { email: string }, type: string) => {
  try {
    const response = axios.patch(`/auth/forgot-password/${type}`, payload, config)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message ?? 'Something Went Wrong')
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}

import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { PARAGRAPH_LENGTH, PROVIDER_STRING } from 'constants/Constants'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'

import { GET_PROVIDER_DEATILS } from '../api'

const Detail = ({ profileDetailId, tabNo }: { profileDetailId: string; tabNo: string }) => {
  const dispatch = useAppDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const [profileData, setProfileData] = useState({
    speciality: '',
    certificate: '',
    bio: '',
    birthDate: '',
    street: '',
    apartment: '',
    zipcode: '',
    city: '',
    state: '',
  })

  useEffect(() => {
    if (profileDetailId && tabNo === '1') {
      void getDetailsProfileData(profileDetailId)
    }
  }, [tabNo])

  const getDetailsProfileData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_PROVIDER_DEATILS(id)
      if (response?.status === 200) {
        // eslint-disable-next-line no-unsafe-optional-chaining, @typescript-eslint/naming-convention
        const { speciality, certificate, bio, birth_date, address } = response?.data?.data
        setProfileData({
          speciality: speciality?.name ?? '',
          certificate: certificate?.name ?? '',
          bio: bio ?? '',
          birthDate: birth_date ?? '',
          street: address[0]?.street ?? '',
          apartment: address[0]?.apartment ?? '',
          zipcode: address[0]?.zip_code ?? '',
          city: address[0]?.city?.name ?? '',
          state: address[0]?.state?.name ?? '',
        })
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded)
  }

  const expandBio = (bio: string) => {
    if (isExpanded) {
      return (
        <>
          {bio}
          <span className="read-more" onClick={toggleExpansion}>
            Read Less
          </span>
        </>
      )
    } else {
      const truncatedBio = bio.length > PARAGRAPH_LENGTH ? bio.substring(0, PARAGRAPH_LENGTH) + '...' : bio
      return (
        <>
          {truncatedBio}
          {bio.length > PARAGRAPH_LENGTH && (
            <span className="read-more" onClick={toggleExpansion}>
              Read more
            </span>
          )}
        </>
      )
    }
  }

  return (
    <div className="detail-box-wrapper">
      <div className="detail-box">
        <div className="text-wrapper">
          <p className="text-neutral-500 para-p2 font-normal">{PROVIDER_STRING.LICENSE}</p>
          <p className="text-neutral-800 mobile-heading-h6 font-normal">{profileData?.certificate ? profileData?.certificate : '-'}</p>
        </div>
        <div className="text-wrapper">
          <p className="text-neutral-500 para-p2 font-normal">{PROVIDER_STRING.SPECIALTY}</p>
          <p className="text-neutral-800 mobile-heading-h6 font-normal">{profileData?.speciality ? profileData?.speciality : '-'}</p>
        </div>
        <div className="text-wrapper">
          <p className="text-neutral-500 para-p2 font-normal">{PROVIDER_STRING.BRIEF_INFO}</p>
          <p className="text-neutral-800 mobile-heading-h6 font-normal">{profileData?.bio ? expandBio(profileData?.bio) : '-'}</p>
        </div>
        <div className="text-wrapper">
          <p className="text-neutral-500 para-p2 font-normal">{PROVIDER_STRING.BIRTH_DATE}</p>
          <p className="text-neutral-800 mobile-heading-h6 font-normal">{profileData?.birthDate ? dayjs(profileData?.birthDate).format('MM-DD-YYYY') : '-'}</p>
        </div>

        <div className="text-wrapper">
          <p className="text-neutral-500 para-p2 font-normal">{PROVIDER_STRING.ADDRESS}</p>
          <p className="text-neutral-800 mobile-heading-h6 font-normal">
            {[profileData?.street, profileData?.apartment, profileData?.city, profileData?.state, profileData?.zipcode].filter(Boolean).join(', ')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Detail

import { Select } from 'antd'
import { CalendarOutline, CalenderCheckIcon, CalenderDotIcon, DownIcon, FacilityCheckIcon, UserNurse } from 'assets/svgs'

const mockData = [
  {
    id: 1,
    icon: <CalendarOutline />,
    name: 'Brooklyn Simmons',
    type: 'Created shift for',
    value: 'CNA at rocky mount care of heber on 22/12/2023 .',
    time: '9:05 pm',
  },
  {
    id: 2,
    icon: <CalenderCheckIcon className="calculator-svg" />,
    name: 'Brooklyn Simmons',
    type: 'Assigned a shift to',
    value: 'Victoria perry.',
    time: '10:00 pm',
  },
  {
    id: 3,
    icon: <CalendarOutline />,
    name: 'Brooklyn Simmons',
    type: 'Cancelled a Shift',
    value: 'CNA at rocky mount care of heber on 22/12/2023 .',
    time: '10:30 pm',
  },
  {
    id: 4,
    icon: <CalenderDotIcon />,
    name: 'Catherine James',
    type: 'Visited',
    value: 'schedule',
    time: '10:30 pm',
  },
  {
    id: 5,
    icon: <UserNurse />,
    name: 'Catherine James',
    type: 'Visited',
    value: 'providers',
    time: '10:30 pm',
  },
  {
    id: 6,
    icon: <FacilityCheckIcon />,
    name: 'Catherine James',
    type: 'Approved timecard for shift',
    value: 'CNA at rocky mount care of heber on 22/12/2023.',
    time: '10:30 pm',
  },
]

const FacilityActivity = () => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  return (
    <div className="flex px-10 flex-col items-center gap-10 self-stretch">
      <div className="activity-wrapper flex flex-col items-start gap-6 w-full max-w-1000 ">
        <div className="flex items-center gap-6 self-stretch">
          <p className="text-neutral-500 para-p3 font-normal w-full">12TH DECEMBER, 2023 </p>
          <Select
            value="today"
            className="w-120 h-45 activity-select"
            onChange={handleChange}
            suffixIcon={<DownIcon />}
            options={[
              { value: 'today', label: 'Today' },
              { value: 'yesterday', label: 'Yesterday' },
            ]}
          />
        </div>
        <div className="flex flex-col items-start self-stretch">
          {mockData.map((item) => {
            return (
              <div key={item.id} className="flex justify-between w-full flex-wrap">
                <div>
                  <div className="flex gap-3 items-center">
                    <div
                      style={{ height: '24px', width: '24px', border: '1px solid #C4C5C7', borderRadius: '100px', marginLeft: '-4px' }}
                      className="flex items-center justify-center"
                    >
                      {item.icon}
                    </div>
                    <p className="flex gap-2">
                      <span className="text-neutral-800 font-normal para-p1 text-decoration-line ">{item.name}</span>
                      <span className="text-neutral-500 font-light para-p1 text-decoration-none"> {item.type}</span>
                      <span className="text-neutral-800 font-normal para-p1 text-decoration-line ">{item.value}</span>
                    </p>
                  </div>
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  <div className={`${mockData.length !== item.id && 'activity-dot mb-0'}`} />
                </div>
                <p className="text-neutral-500 font-small para-p3">{item.time} </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FacilityActivity

import React from 'react'

export const PlusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8334 9.16536H10.8334V4.16536C10.8334 3.66536 10.5 3.33203 10 3.33203C9.50004 3.33203 9.16671 3.66536 9.16671 4.16536V9.16536H4.16671C3.66671 9.16536 3.33337 9.4987 3.33337 9.9987C3.33337 10.4987 3.66671 10.832 4.16671 10.832H9.16671V15.832C9.16671 16.332 9.50004 16.6654 10 16.6654C10.5 16.6654 10.8334 16.332 10.8334 15.832V10.832H15.8334C16.3334 10.832 16.6667 10.4987 16.6667 9.9987C16.6667 9.4987 16.3334 9.16536 15.8334 9.16536Z"
        fill="white"
      />
    </svg>
  )
}

export const PlusIconSolid = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8333 9.16683H10.8333V4.16683C10.8333 3.66683 10.4999 3.3335 9.99992 3.3335C9.49992 3.3335 9.16658 3.66683 9.16658 4.16683V9.16683H4.16659C3.66659 9.16683 3.33325 9.50016 3.33325 10.0002C3.33325 10.5002 3.66659 10.8335 4.16659 10.8335H9.16658V15.8335C9.16658 16.3335 9.49992 16.6668 9.99992 16.6668C10.4999 16.6668 10.8333 16.3335 10.8333 15.8335V10.8335H15.8333C16.3333 10.8335 16.6666 10.5002 16.6666 10.0002C16.6666 9.50016 16.3333 9.16683 15.8333 9.16683Z"
        fill="#494A4C"
      />
    </svg>
  )
}

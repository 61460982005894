/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'

const initialState = {
  email: '',
  password: '',
  name: '',
  mobile_no: '',
  onlyCountryCode: '',
}

export const signUpSlice = createSlice({
  name: 'signUp', // Corrected slice name to 'signUp'
  initialState,
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setFormData } = signUpSlice.actions

export const selectSignUp = (state: RootState) => state.signUp

export default signUpSlice.reducer

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-bg-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), lightgray 50%/cover no-repeat;
  opacity: 0.5;
  pointer-events: "cursor";
  inset: 0;
}

.upload-profile-img {
  height: 200px;
  max-height: 200px;
  min-width: 200px;
  border-radius: 999px !important;
  border: 1px solid #e5e5e6;
  object-fit: cover;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), lightgray 50%/cover no-repeat;
}

.media-button {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/component/UploadMedia/UploadMedia.scss"],"names":[],"mappings":"AAEA;EACE,gHACE;EAEF,YAAA;EACA,wBAAA;EACA,QAAA;AAHF;;AAMA;EACE,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,+BAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gHACE;AAJJ;;AAOA;EACE,wBAAA;AAJF","sourcesContent":["@import '../../styles/variables';\n\n.upload-bg-overlay {\n  background:\n    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),\n    lightgray 50% / cover no-repeat;\n  opacity: 0.5;\n  pointer-events: 'cursor';\n  inset: 0;\n}\n\n.upload-profile-img {\n  height: 200px;\n  max-height: 200px;\n  min-width: 200px;\n  border-radius: 999px !important;\n  border: 1px solid $stroke-color;\n  object-fit: cover;\n  border-radius: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background:\n    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),\n    lightgray 50% / cover no-repeat;\n}\n.media-button {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Button } from 'antd'

import './Button.scss'

interface IButton {
  htmlType?: 'button' | 'submit' | 'reset'
  type?: 'text' | 'link' | 'default' | 'primary' | 'dashed'
  className?: string
  text?: string
  isIcon?: boolean
  svgIcon?: JSX.Element
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const CustomButton = ({ htmlType = 'button', type = 'primary', text, className, isIcon, svgIcon, onClick, disabled }: IButton) => {
  return (
    <>
      {isIcon ? (
        <Button
          size="large"
          icon={svgIcon}
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          className={`${type !== 'default' ?? 'custom-icon-btn'} btn-padding flex items-center justify-center ${className}`}
          onClick={onClick}
          type={type}
          htmlType={htmlType}
          disabled={disabled}
        >
          {text}
        </Button>
      ) : (
        <Button
          disabled={disabled}
          htmlType={htmlType}
          type={type}
          className={`${className as string} custom-button w-full h-64 ${type === 'default' ? 'text-neutral-700' : ''}`}
          onClick={onClick}
        >
          {text}
        </Button>
      )}
    </>
  )
}

export default CustomButton

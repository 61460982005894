import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'
import { PAGE_TITLE_STRING } from 'constants/Constants'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import Document from './components/Document'
import FacilityActivity from './components/FacilityActivity'
import HoliDays from './components/HoliDays'
import ManageAccount from './components/ManageAccount'
import Notification from './components/Notification'
import Profile from './components/Profile'
import RateSheet from './components/RateSheet'

import './FacilityProfile.scss'

const FacilityProfile = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)

  useEffect(() => {
    if (!isFacility) {
      navigate('/')
    }
  }, [isFacility, navigate])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.FACILITY_PROFILE,
      })
    )
  }, [])

  const onChange = (key: string) => {
    console.log(key)
  }

  const items = [
    {
      key: '1',
      label: 'Profile',
      children: <Profile />,
    },
    {
      key: '2',
      label: 'Holidays',
      children: <HoliDays />,
    },
    {
      key: '3',
      label: 'Rate Sheet',
      children: <RateSheet />,
    },
    {
      key: '4',
      label: 'Facility Activity',
      children: <FacilityActivity />,
    },
    {
      key: '5',
      label: 'Documents',
      children: <Document />,
    },
    {
      key: '6',
      label: 'Notification Preferances',
      children: <Notification />,
    },
    {
      key: '7',
      label: 'Manage Account',
      children: <ManageAccount />,
    },
  ]
  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
      className="facility-profile-tabs flex pb-10 justify-center items-center self-stretch rounded-4 bg-white gap-16  "
    />
  )
}

export default FacilityProfile

import React from 'react'

const PrevIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M8.83285 10L12.9162 5.91667C13.2495 5.58333 13.2495 5.08333 12.9162 4.75C12.5828 4.41667 12.0828 4.41667 11.7495 4.75L6.99951 9.5C6.66618 9.83333 6.66618 10.3333 6.99951 10.6667L11.7495 15.4167C11.9162 15.5833 12.0828 15.6667 12.3328 15.6667C12.5828 15.6667 12.7495 15.5833 12.9162 15.4167C13.2495 15.0833 13.2495 14.5833 12.9162 14.25L8.83285 10Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default PrevIcon

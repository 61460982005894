import { useState } from 'react'
import { Dropdown, type MenuProps } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import HeadingText from 'component/HeadingText'
import CustomTable from 'component/Table/CustomTable'
import { BUTTON_STRING, FACILITY_USER_PROFILE } from 'constants/Constants'
import { type IAddHoliday } from 'types'

import AddHoliday from '../Modal/AddHolidays'

const tableData = [{ id: 1, name: 'New Year’s Day', description: '-', billing: 'YES', date: 'Sunday, 01/01/2024' }]

const HoliDays = () => {
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(tableData.length).fill(false))
  const [isModelOpen, setModelOpen] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 5,
    offset: 0,
    limit: 5,
    current: 1,
  })

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const getMenu = (record: IAddHoliday, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper flex p-4 items-center gap-2 self-stretch cursor-pointer">
              <EditPenIcon />
              <span className="activity-text para-p1 text-neutral-800">{BUTTON_STRING.EDIT}</span>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper flex p-4 items-center gap-2 self-stretch cursor-pointer">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text para-p1 text-error-500">{BUTTON_STRING.DELETE}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: FACILITY_USER_PROFILE.HOLIDAY_TABLE.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 380,
      render(value, record) {
        return <HeadingText text={record.name} classString="text-neutral-800 para-p1 font-medium" />
      },
    },
    {
      title: FACILITY_USER_PROFILE.HOLIDAY_TABLE.DESCRIPTION,
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 380,
    },
    {
      title: FACILITY_USER_PROFILE.HOLIDAY_TABLE.BILLING,
      dataIndex: 'billing',
      key: 'billing',
      align: 'center',
      width: 150,
    },
    {
      title: FACILITY_USER_PROFILE.HOLIDAY_TABLE.DATE,
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: 380,
    },
    {
      title: FACILITY_USER_PROFILE.HOLIDAY_TABLE.ACTION,
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 152,
      render: (_value, record: any) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell p-2 border-stroke rounded-2 flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const handleClick = () => {
    setModelOpen(true)
  }

  return (
    <div className="holidays-wrapper flex px-10 flex-col items-center gap-4 self-stretch">
      <div className="flex justify-end items-center self-stretch">
        <CustomButton isIcon svgIcon={<PlusIcon />} text={BUTTON_STRING.ADD_HOLIDAY} type="primary" className="rounded-3 border-0" onClick={handleClick} />
      </div>
      <CustomTable
        className="border-stroke rounded-4 overflow-hidden"
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={tableData}
        name={FACILITY_USER_PROFILE.HOLIDAYS}
      />

      {isModelOpen && (
        <CustomModal
          width={600}
          open={isModelOpen}
          title={BUTTON_STRING.ADD_HOLIDAY}
          onCancel={() => {
            setModelOpen(false)
          }}
          footer={null}
          className="hide-closeicon-modal"
          content={<AddHoliday isModalOpen={setModelOpen} />}
        />
      )}
    </div>
  )
}

export default HoliDays

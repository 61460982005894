import React from 'react'

const CalenderCrossIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8334 3.33341H14.1667V2.50008C14.1667 2.27907 14.079 2.06711 13.9227 1.91083C13.7664 1.75455 13.5544 1.66675 13.3334 1.66675C13.1124 1.66675 12.9004 1.75455 12.7442 1.91083C12.5879 2.06711 12.5001 2.27907 12.5001 2.50008V3.33341H7.50008V2.50008C7.50008 2.27907 7.41228 2.06711 7.256 1.91083C7.09972 1.75455 6.88776 1.66675 6.66675 1.66675C6.44573 1.66675 6.23377 1.75455 6.07749 1.91083C5.92121 2.06711 5.83342 2.27907 5.83342 2.50008V3.33341H4.16675C3.50371 3.33341 2.86782 3.59681 2.39898 4.06565C1.93014 4.53449 1.66675 5.17037 1.66675 5.83342V15.8334C1.66675 16.4965 1.93014 17.1323 2.39898 17.6012C2.86782 18.07 3.50371 18.3334 4.16675 18.3334H15.8334C16.4965 18.3334 17.1323 18.07 17.6012 17.6012C18.07 17.1323 18.3334 16.4965 18.3334 15.8334V5.83342C18.3334 5.17037 18.07 4.53449 17.6012 4.06565C17.1323 3.59681 16.4965 3.33341 15.8334 3.33341ZM16.6667 15.8334C16.6667 16.0544 16.579 16.2664 16.4227 16.4227C16.2664 16.579 16.0544 16.6667 15.8334 16.6667H4.16675C3.94573 16.6667 3.73377 16.579 3.57749 16.4227C3.42121 16.2664 3.33341 16.0544 3.33341 15.8334V10.0001H16.6667V15.8334ZM16.6667 8.33342H3.33341V5.83342C3.33341 5.6124 3.42121 5.40044 3.57749 5.24416C3.73377 5.08788 3.94573 5.00008 4.16675 5.00008H5.83342V5.83342C5.83342 6.05443 5.92121 6.26639 6.07749 6.42267C6.23377 6.57895 6.44573 6.66675 6.66675 6.66675C6.88776 6.66675 7.09972 6.57895 7.256 6.42267C7.41228 6.26639 7.50008 6.05443 7.50008 5.83342V5.00008H12.5001V5.83342C12.5001 6.05443 12.5879 6.26639 12.7442 6.42267C12.9004 6.57895 13.1124 6.66675 13.3334 6.66675C13.5544 6.66675 13.7664 6.57895 13.9227 6.42267C14.079 6.26639 14.1667 6.05443 14.1667 5.83342V5.00008H15.8334C16.0544 5.00008 16.2664 5.08788 16.4227 5.24416C16.579 5.40044 16.6667 5.6124 16.6667 5.83342V8.33342Z"
        fill="#494A4C"
      />
      <path
        d="M11.175 13.3333L11.8417 12.675C11.9194 12.5973 11.981 12.5051 12.0231 12.4035C12.0651 12.302 12.0868 12.1932 12.0868 12.0833C12.0868 11.9734 12.0651 11.8646 12.0231 11.7631C11.981 11.6616 11.9194 11.5694 11.8417 11.4917C11.764 11.414 11.6718 11.3523 11.5702 11.3103C11.4687 11.2682 11.3599 11.2466 11.25 11.2466C11.1401 11.2466 11.0313 11.2682 10.9298 11.3103C10.8283 11.3523 10.7361 11.414 10.6584 11.4917L10 12.1583L9.3417 11.4917C9.264 11.414 9.17176 11.3523 9.07024 11.3103C8.96872 11.2682 8.85991 11.2466 8.75003 11.2466C8.52811 11.2466 8.31528 11.3347 8.15836 11.4917C8.08067 11.5694 8.01903 11.6616 7.97698 11.7631C7.93493 11.8646 7.91329 11.9734 7.91329 12.0833C7.91329 12.3052 8.00144 12.5181 8.15836 12.675L8.82503 13.3333L8.15836 13.9917C8.08026 14.0691 8.01826 14.1613 7.97595 14.2628C7.93365 14.3644 7.91187 14.4733 7.91187 14.5833C7.91187 14.6933 7.93365 14.8023 7.97595 14.9038C8.01826 15.0054 8.08026 15.0975 8.15836 15.175C8.23583 15.2531 8.328 15.3151 8.42955 15.3574C8.5311 15.3997 8.64002 15.4215 8.75003 15.4215C8.86004 15.4215 8.96896 15.3997 9.07051 15.3574C9.17206 15.3151 9.26423 15.2531 9.3417 15.175L10 14.5083L10.6584 15.175C10.7358 15.2531 10.828 15.3151 10.9295 15.3574C11.0311 15.3997 11.14 15.4215 11.25 15.4215C11.36 15.4215 11.469 15.3997 11.5705 15.3574C11.6721 15.3151 11.7642 15.2531 11.8417 15.175C11.9198 15.0975 11.9818 15.0054 12.0241 14.9038C12.0664 14.8023 12.0882 14.6933 12.0882 14.5833C12.0882 14.4733 12.0664 14.3644 12.0241 14.2628C11.9818 14.1613 11.9198 14.0691 11.8417 13.9917L11.175 13.3333Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default CalenderCrossIcon

import message from 'antd/es/message'
import { http } from 'service/Service'
import type { IChangePassword, IProfileData } from 'types'

export const GET_USER_PROFILE = async () => {
  try {
    const response = http.get('/facility-user')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const UPDATE_USER_PROFILE = async (payload: IProfileData | any) => {
  try {
    const response = http.patch('/facility-user', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const CHANGE_USER_PROFILE_PASSWORD = async (payload: IChangePassword) => {
  try {
    const response = http.patch('/auth/change-password/facility_user', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

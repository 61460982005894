import React from 'react'

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M9.99984 2.16699C5.4165 2.16699 1.6665 5.91699 1.6665 10.5003C1.6665 15.0837 5.4165 18.8337 9.99984 18.8337C14.5832 18.8337 18.3332 15.0837 18.3332 10.5003C18.3332 5.91699 14.5832 2.16699 9.99984 2.16699ZM13.4998 9.08366L9.49984 13.0837C9.1665 13.417 8.6665 13.417 8.33317 13.0837L6.49984 11.2503C6.1665 10.917 6.1665 10.417 6.49984 10.0837C6.83317 9.75033 7.33317 9.75033 7.6665 10.0837L8.9165 11.3337L12.3332 7.91699C12.6665 7.58366 13.1665 7.58366 13.4998 7.91699C13.8332 8.25033 13.8332 8.75033 13.4998 9.08366Z"
        fill="#359766"
      />
    </svg>
  )
}

export default CheckIcon

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { DownIcon } from 'assets/svgs'
import CustomAutoComplete from 'component/AutoComplete/AutoComplete'
import InputField from 'component/InputField/InputField'
import { COMMON, WELCOME } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import useDebounce from 'hooks/useDebounce'
import { setWlcFormData, welcomeData } from 'store/slice/WelcomeDataSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonInputFieldValidation, zipCodeValidation } from 'utils/Validations'

import { searchCity, updateFacilityProfile } from '../api'
import { type IMultiLevelForm, type ISignUpWelcomeFormData, type IUpdateProfileData } from '../types'

const FacilityAddress = ({ onClick, back }: IMultiLevelForm) => {
  const [error, setError] = useState({
    street_address: '',
    city: '',
    zip_code: '',
    house_no: '',
  })
  const [cityData, setCityData] = useState<string>()
  const [cityError, setCityError] = useState<string>()
  const [search, setSearch] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedProvince, setSelectedProvince] = useState<any>({})
  const debounceSearch = useDebounce(search, 800)
  const [selectedCityId, setSelectedCityId] = useState('')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') ?? ''
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const formData = useSelector(welcomeData)

  useEffect(() => {
    // form.setFieldsValue(formData)
    form.setFieldValue('state', selectedProvince?.state?.name)
    form.setFieldValue('country', selectedProvince?.state?.country?.name)
  }, [form, formData, selectedProvince, selectedCity, selectedCityId])

  const onFinish = (values: ISignUpWelcomeFormData) => {
    const payload = {
      house_no: values?.house_no,
      street_address: values?.street_address,
      zip_code: values?.zip_code,
      country: selectedProvince?.state?.country?.id,
      state: selectedProvince?.state?.id,
      city: selectedCityId,
      name: formData?.name,
      image: formData?.image,
      facility_type: formData?.faciliy_type,
      total_beds: Number(formData.total_beds),
    }
    dispatch(setWlcFormData(values))
    if (selectedCityId === '') {
      setCityError(VALIDATION_ERROR.COMMON.CITY)
    } else if (onClick) {
      void handleUpdateProfile(payload)
    }
  }

  const handleUpdateProfile = async (data: IUpdateProfileData) => {
    const response = await updateFacilityProfile(id, data)
    if (response?.data?.statusCode === 1) {
      navigate('/login')
      void message.success(response?.data?.message)
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value)
  }

  const handleCitySearch = async () => {
    const response = await searchCity(search)
    if (response?.data?.statusCode === 1) {
      setCityData(() => {
        if (!search) {
          return []
        }
        return response?.data?.data?.map((singleCity: any) => {
          const modifiedCity = {
            ...singleCity,
            newProperty: 'value',
          }

          return {
            label: `${modifiedCity?.name as string} - ${modifiedCity?.state?.name as string}`,
            value: modifiedCity?.id,
            state: modifiedCity?.state,
          }
        })
      })
    } else {
      void message.error(response?.data?.message)
    }
  }

  useEffect(() => {
    if (debounceSearch) {
      void handleCitySearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch])

  const handleChange = (value: string) => {
    setSearch(value)
    setSelectedCity(value)
  }

  const handleSelect = (value: string, option: { label: string; id: string; state: string }) => {
    setSelectedProvince(option)
    setSelectedCity(option?.label)
    setSelectedCityId(value)
  }

  return (
    <div className="bg-white mx-auto max-w-532  flex flex-col gap-12 items-center welcome-card w-full ">
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={back}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="flex flex-col items-center gap-10 padding-32 w-full min-w-full wlc-box-shadow ">
        <div className="flex flex-col items-center gap-2">
          <p className="desktop-heading-h3 font-medium text-center">{WELCOME.ADD_FACILITY_ADDRESS}</p>
        </div>
        <div className="flex flex-col gap-6 items-start w-full">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="min-w-full"
            requiredMark={false}
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setError)
            }}
          >
            <InputField name="street_address" placeholder={COMMON.STREET} rules={commonInputFieldValidation('street address')} error={error.street_address} />

            <InputField name="house_no" placeholder={COMMON.APARTMENT} />

            <InputField name="zip_code" placeholder={COMMON.ZIP} rules={zipCodeValidation('zip code')} error={error.zip_code} maxLength={5} />

            <CustomAutoComplete
              value={selectedCity}
              onChange={handleChange}
              options={cityData}
              placeholder={COMMON.CITY}
              onSearch={onSearch}
              onSelect={handleSelect}
              rules={commonInputFieldValidation('city name')}
            />

            <InputField disabled name="state" placeholder={COMMON.STATE} />
            <InputField disabled name="country" placeholder={COMMON.COUNTRY} />

            <Button type="primary" htmlType="submit" className="common-button w-full">
              {COMMON.CONTINUE}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default FacilityAddress

import React from 'react'

const InfoIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.99935 1.66699C8.35118 1.66699 6.74001 2.15573 5.3696 3.07141C3.99919 3.98709 2.93109 5.28858 2.30036 6.8113C1.66963 8.33401 1.5046 10.0096 1.82614 11.6261C2.14769 13.2426 2.94136 14.7274 4.1068 15.8929C5.27223 17.0583 6.75709 17.852 8.3736 18.1735C9.99011 18.4951 11.6657 18.3301 13.1884 17.6993C14.7111 17.0686 16.0126 16.0005 16.9283 14.6301C17.8439 13.2597 18.3327 11.6485 18.3327 10.0003C18.3302 7.79094 17.4515 5.67275 15.8892 4.11048C14.3269 2.54821 12.2087 1.66945 9.99935 1.66699ZM9.99935 16.667C8.68081 16.667 7.39188 16.276 6.29555 15.5435C5.19922 14.8109 4.34474 13.7697 3.84016 12.5515C3.33557 11.3334 3.20355 9.99293 3.46078 8.69972C3.71802 7.40652 4.35296 6.21863 5.28531 5.28628C6.21766 4.35393 7.40554 3.71899 8.69875 3.46176C9.99196 3.20452 11.3324 3.33654 12.5506 3.84113C13.7687 4.34571 14.8099 5.2002 15.5425 6.29652C16.275 7.39285 16.666 8.68178 16.666 10.0003C16.664 11.7678 15.961 13.4623 14.7112 14.7121C13.4614 15.9619 11.7668 16.665 9.99935 16.667ZM9.99935 9.58366C9.77834 9.58366 9.56638 9.67146 9.4101 9.82774C9.25382 9.98402 9.16602 10.196 9.16602 10.417V12.917C9.16602 13.138 9.25382 13.35 9.4101 13.5062C9.56638 13.6625 9.77834 13.7503 9.99935 13.7503C10.2204 13.7503 10.4323 13.6625 10.5886 13.5062C10.7449 13.35 10.8327 13.138 10.8327 12.917V10.417C10.8327 10.196 10.7449 9.98402 10.5886 9.82774C10.4323 9.67146 10.2204 9.58366 9.99935 9.58366ZM9.99935 6.25033C9.79333 6.25033 9.59194 6.31142 9.42063 6.42588C9.24933 6.54034 9.11582 6.70302 9.03698 6.89336C8.95814 7.0837 8.93751 7.29315 8.9777 7.49521C9.01789 7.69728 9.1171 7.88288 9.26278 8.02856C9.40846 8.17424 9.59407 8.27345 9.79613 8.31364C9.9982 8.35384 10.2076 8.33321 10.398 8.25437C10.5883 8.17553 10.751 8.04201 10.8655 7.87071C10.9799 7.69941 11.041 7.49801 11.041 7.29199C11.041 7.01573 10.9313 6.75077 10.7359 6.55542C10.5406 6.36007 10.2756 6.25033 9.99935 6.25033Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default InfoIcon

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyC8Dy_6b_-RfLsLQqX32GRmaLL9qgaBWXw',
  authDomain: 'authentication-370306.firebaseapp.com',
  projectId: 'authentication-370306',
  storageBucket: 'authentication-370306.appspot.com',
  messagingSenderId: '202181843124',
  appId: '1:202181843124:web:faf24e0b023ec402515fe1',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const app = initializeApp(firebaseConfig)
      const messaging = getMessaging(app)

      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js')

      const currentToken = await getToken(messaging, {
        vapidKey: 'BO469OLUPN4SAUZ8AzbGKTy99LMMgaT5eldifFagO2JCa-1BmjAJO1DybKGrYr1tvsLgHAhXXv7z735KMoyt008',
        serviceWorkerRegistration: registration,
      })

      if (currentToken) {
        return currentToken
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (error) {
    return false
  }
}

export const onForegroundMessage = (callback: (payload: any) => void) => {
  onMessage(messaging, callback)
}

const CalendarOutline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.6668 2.66634H11.3335V1.99967C11.3335 1.82286 11.2633 1.65329 11.1382 1.52827C11.0132 1.40325 10.8436 1.33301 10.6668 1.33301C10.49 1.33301 10.3204 1.40325 10.1954 1.52827C10.0704 1.65329 10.0002 1.82286 10.0002 1.99967V2.66634H6.00016V1.99967C6.00016 1.82286 5.92992 1.65329 5.8049 1.52827C5.67988 1.40325 5.51031 1.33301 5.3335 1.33301C5.15669 1.33301 4.98712 1.40325 4.86209 1.52827C4.73707 1.65329 4.66683 1.82286 4.66683 1.99967V2.66634H3.3335C2.80306 2.66634 2.29436 2.87705 1.91928 3.25213C1.54421 3.6272 1.3335 4.13591 1.3335 4.66634V12.6663C1.3335 13.1968 1.54421 13.7055 1.91928 14.0806C2.29436 14.4556 2.80306 14.6663 3.3335 14.6663H12.6668C13.1973 14.6663 13.706 14.4556 14.081 14.0806C14.4561 13.7055 14.6668 13.1968 14.6668 12.6663V4.66634C14.6668 4.13591 14.4561 3.6272 14.081 3.25213C13.706 2.87705 13.1973 2.66634 12.6668 2.66634ZM13.3335 12.6663C13.3335 12.8432 13.2633 13.0127 13.1382 13.1377C13.0132 13.2628 12.8436 13.333 12.6668 13.333H3.3335C3.15669 13.333 2.98712 13.2628 2.86209 13.1377C2.73707 13.0127 2.66683 12.8432 2.66683 12.6663V7.99967H13.3335V12.6663ZM13.3335 6.66634H2.66683V4.66634C2.66683 4.48953 2.73707 4.31996 2.86209 4.19494C2.98712 4.06991 3.15669 3.99967 3.3335 3.99967H4.66683V4.66634C4.66683 4.84315 4.73707 5.01272 4.86209 5.13775C4.98712 5.26277 5.15669 5.33301 5.3335 5.33301C5.51031 5.33301 5.67988 5.26277 5.8049 5.13775C5.92992 5.01272 6.00016 4.84315 6.00016 4.66634V3.99967H10.0002V4.66634C10.0002 4.84315 10.0704 5.01272 10.1954 5.13775C10.3204 5.26277 10.49 5.33301 10.6668 5.33301C10.8436 5.33301 11.0132 5.26277 11.1382 5.13775C11.2633 5.01272 11.3335 4.84315 11.3335 4.66634V3.99967H12.6668C12.8436 3.99967 13.0132 4.06991 13.1382 4.19494C13.2633 4.31996 13.3335 4.48953 13.3335 4.66634V6.66634Z"
        fill="#68696C"
      />
    </svg>
  )
}

export default CalendarOutline

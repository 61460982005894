import React from 'react'

const CupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.00016 11.3333H8.66683C9.55088 11.3333 10.3987 10.9821 11.0239 10.357C11.649 9.7319 12.0002 8.88406 12.0002 8V7.33333H12.6668C13.1973 7.33333 13.706 7.12262 14.081 6.74755C14.4561 6.37247 14.6668 5.86377 14.6668 5.33333C14.6668 4.8029 14.4561 4.29419 14.081 3.91912C13.706 3.54405 13.1973 3.33333 12.6668 3.33333H12.0002V2.66667C12.0002 2.48986 11.9299 2.32029 11.8049 2.19526C11.6799 2.07024 11.5103 2 11.3335 2H3.3335C3.15669 2 2.98712 2.07024 2.86209 2.19526C2.73707 2.32029 2.66683 2.48986 2.66683 2.66667V8C2.66683 8.88406 3.01802 9.7319 3.64314 10.357C4.26826 10.9821 5.11611 11.3333 6.00016 11.3333ZM12.0002 4.66667H12.6668C12.8436 4.66667 13.0132 4.7369 13.1382 4.86193C13.2633 4.98695 13.3335 5.15652 13.3335 5.33333C13.3335 5.51014 13.2633 5.67971 13.1382 5.80474C13.0132 5.92976 12.8436 6 12.6668 6H12.0002V4.66667ZM4.00016 3.33333H10.6668V8C10.6668 8.53043 10.4561 9.03914 10.081 9.41421C9.70597 9.78929 9.19726 10 8.66683 10H6.00016C5.46973 10 4.96102 9.78929 4.58595 9.41421C4.21088 9.03914 4.00016 8.53043 4.00016 8V3.33333ZM14.0002 12.6667H2.00016C1.82335 12.6667 1.65378 12.7369 1.52876 12.8619C1.40373 12.987 1.3335 13.1565 1.3335 13.3333C1.3335 13.5101 1.40373 13.6797 1.52876 13.8047C1.65378 13.9298 1.82335 14 2.00016 14H14.0002C14.177 14 14.3465 13.9298 14.4716 13.8047C14.5966 13.6797 14.6668 13.5101 14.6668 13.3333C14.6668 13.1565 14.5966 12.987 14.4716 12.8619C14.3465 12.7369 14.177 12.6667 14.0002 12.6667Z"
        fill="#68696C"
      />
    </svg>
  )
}

export default CupIcon

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { Form, Image as Img, message } from 'antd'
import { profile } from 'assets/imgs'
import { CameraIcon, Edit } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import ChangePwdForm from 'component/ChangePassword/ChangePassword'
import CustomModal from 'component/CustomModal/CustomModal'
import HeadingText from 'component/HeadingText'
import InputField from 'component/InputField/InputField'
import UploadMedia from 'component/UploadMedia/UploadMedia'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, USER_PROFILE_STRING } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { mediaUpload } from 'pages/Welcome/api'
import { setProfileData, setSignature } from 'store/slice/AuthSlice'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IProfileData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonInputFieldValidation, mobileValidations } from 'utils/Validations'

import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from './api'

import './UserProfile.scss'

const UserProfile = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const signPadRef = useRef<SignatureCanvas>(null)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [updatedProfileData, setUpdatedProfileData] = useState<IProfileData>()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false)
  const [imageLink, setImageLink] = useState<string>('')
  const [signatureLink, setSignatureLink] = useState('')
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    role: '',
    email: '',
    mobile_no: '',
    avtar: '',
  })
  const [form] = Form.useForm()
  const isFacility = useAppSelector((state) => state?.auth?.auth?.profileDetails?.isFacility)
  const signatureData: any = useAppSelector((state) => state?.auth?.auth)

  useEffect(() => {
    if (isFacility) {
      navigate('/')
    }
  }, [isFacility, navigate])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.MY_PROFILE,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isEdit])

  useEffect(() => {
    setImageLink(updatedProfileData?.image ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  useEffect(() => {
    form.setFieldsValue(updatedProfileData)
  }, [form, updatedProfileData])

  const showModal = () => {
    setIsPasswordModalOpen(true)
  }

  const handleEndSignature = async () => {
    if (signPadRef.current) {
      // No need to clear the canvas here; just get the signature
      const signatureDataUrl = signPadRef.current.toDataURL()
      await handleSignatureUpload(signatureDataUrl)
    }
  }

  useEffect(() => {
    const loadSignature = async () => {
      if (signatureData?.signature && signPadRef.current) {
        // signPadRef.current.clear()

        const imageUrl = `${signatureData?.profileDetails?.base_url as string}${signatureData?.signature as string}`
        const img = new Image()
        img.crossOrigin = 'anonymous' // Ensure the server allows this
        img.src = imageUrl

        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          const ctx: any = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          const base64String = canvas.toDataURL('image/png')
          signPadRef?.current?.fromDataURL(base64String)
        }
      }
    }

    void loadSignature()
  }, [isEdit, signatureData?.signature])

  const hideModal = () => {
    setIsPasswordModalOpen(false)
  }

  const clearSignature = async () => {
    if (signPadRef.current) {
      const payload = {
        signature: null,
      }
      signPadRef.current.toDataURL()
      signPadRef.current.clear()
      await UPDATE_USER_PROFILE(payload)
      dispatch(setSignature(''))
    }
  }

  const handleUpdateProfile = async (data: IProfileData) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await UPDATE_USER_PROFILE(data)
      if (response?.data?.statusCode === 1) {
        setIsEdit(false)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = (values: IProfileData) => {
    const payload = {
      ...values,
      image: imageLink,
    }
    void handleUpdateProfile(payload)
  }

  const handleGetProfile = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_USER_PROFILE()
      if (response?.data?.statusCode === 1) {
        const resData = {
          full_name: String(response?.data?.data.first_name) + ' ' + String(response?.data?.data.last_name),
          ...response?.data?.data,
        }
        setImageLink(response?.data?.data?.image)
        dispatch(setProfileData(response?.data?.data))
        setUpdatedProfileData(resData)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleMediaUpload = async (data: any) => {
    dispatch(setGlobalLoader(true))
    try {
      const formData = new FormData()
      formData.append('image', data)
      formData.append('folder', 'facility')
      const response = await mediaUpload(formData)
      if (response?.data?.statusCode === 1) {
        setImageLink(response?.data?.data?.image)
        void message.success('Image uploaded successfully')
      }
    } catch (error) {
      void message.error('Failed to upload image. Please try again.')
    }
    dispatch(setGlobalLoader(false))
  }

  const dataURLtoBlob = (dataurl: any) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleSignatureUpload = async (dataUrl: string) => {
    if (typeof dataUrl !== 'string') {
      return
    }

    dispatch(setGlobalLoader(true))
    try {
      const formData = new FormData()
      const blob = dataURLtoBlob(dataUrl)
      formData.append('image', blob, 'signature.png')
      formData.append('folder', 'signature')
      const response = await mediaUpload(formData)
      if (response?.data?.statusCode === 1) {
        const payload = {
          signature: response?.data?.data?.image,
        }
        await UPDATE_USER_PROFILE(payload)
        dispatch(setSignature(response?.data?.data?.image))
        setSignatureLink(response?.data?.data?.image)
        void message.success('Image uploaded successfully')
      } else {
        void message.error('Failed to upload image. Please try again.')
      }
    } catch (error) {
      void message.error('Failed to upload image. Please try again.')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (!file.type.startsWith('image/')) {
        setError((prev) => ({ ...prev, avatar: VALIDATION_ERROR.IMAGE.ONLYIMG }))
        return
      }

      void handleMediaUpload(file)

      setError((prev) => ({ ...prev, image: '' }))
    }
  }

  return (
    <div className="user-profile-container box-shadow h-full flex justify-center items-start self-stretch rounded-4 bg-white overflow-y-scroll p-10">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="flex justify-center items-center user-form-tag"
        requiredMark={false}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex flex-col items-center gap-16 user-profile-form">
          <div className="flex flex-col">
            <div className="flex items-start self-stretch user-avtar">
              <HeadingText text={USER_PROFILE_STRING.AVATAR} classString="mobile-heading-h3 font-medium text-neutral-800 user-text" />
              {isEdit ? (
                <UploadMedia
                  className="flex justify-center items-center"
                  icon={<CameraIcon />}
                  imgHeight={200}
                  imgWidth={200}
                  handleChange={handleMediaChange}
                  imageUrl={imageLink}
                  edit={isEdit}
                  applyOverlay={true}
                  baseUrl={updatedProfileData?.base_url as string}
                  styleImgClass="upload-profile-img"
                />
              ) : (
                <Img
                  preview={false}
                  src={`${!isEdit && imageLink ? `${updatedProfileData?.base_url as string}${imageLink}` : String(profile)}`}
                  alt="Profile Picture"
                  className="profile-pic-image"
                />
              )}

              {isEdit ? (
                <CustomButton isIcon={false} text={BUTTON_STRING.SAVE} htmlType="submit" className="w-160 h-49" />
              ) : (
                <CustomButton
                  isIcon={true}
                  type="default"
                  className="user-icon w-160 h-49"
                  svgIcon={<Edit className="svg" />}
                  text={BUTTON_STRING.EDIT_profile}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                    e.preventDefault()
                    setIsEdit(true)
                  }}
                />
              )}
            </div>
            {error.avtar && isEdit && <p className="error-container flex justify-center">{error.avtar}</p>}
          </div>
          <div className="flex justify-center items-start self-stretch user-detail">
            <HeadingText text={USER_PROFILE_STRING.BASIC_DETAIL} classString="mobile-heading-h3 font-medium text-neutral-800 user-text" />
            <div className="flex flex-col justify-center user-form">
              {isEdit ? (
                <div className="w-full">
                  <div className={`flex edit-name gap-6 h-64  ${error.last_name || error.first_name ? '' : 'mb-6'} `}>
                    <div className="flex w-50">
                      <InputField
                        placeholder={COMMON.FIRST_NAME}
                        disabled={!isEdit}
                        rules={commonInputFieldValidation('your first name')}
                        name="first_name"
                        inputClass="login-input w-full hover-100"
                      />
                    </div>
                    <div className="flex w-50">
                      <InputField
                        placeholder={COMMON.LAST_NAME}
                        disabled={!isEdit}
                        rules={commonInputFieldValidation('your last name')}
                        inputClass="login-input w-full hover-100"
                        name="last_name"
                      />
                    </div>
                  </div>

                  {(error.last_name || error.first_name) && isEdit ? (
                    <div className="flex gap-6 edit-name">
                      <div className="flex items-center w-50">
                        <p className="error-container mb-6">{error.first_name}</p>
                      </div>
                      <div className="flex items-center w-50">
                        <p className="error-container mb-6">{error.last_name}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <InputField
                  placeholder={COMMON.FULL_NAME}
                  disabled={!isEdit}
                  labelClass="profile-label"
                  textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                  name="full_name"
                />
              )}
              <InputField placeholder={COMMON.EMAIL} disabled labelClass="profile-label" textClass={!isEdit ? 'profile-input-text' : 'hover-100'} name="email" />
              <InputField
                placeholder={COMMON.MOBILE_NO}
                disabled
                rules={mobileValidations}
                labelClass="profile-label"
                textClass={!isEdit ? 'profile-input-text' : 'hover-100'}
                name="mobile_no"
              />
              {isEdit && (
                <div className="flex justify-end">
                  <CustomButton isIcon={false} text={BUTTON_STRING.CHANGE_PASS} onClick={showModal} className="w-180 h-49" />
                </div>
              )}
            </div>
          </div>
          {!isEdit && (
            <div className="flex justify-center items-start self-stretch user-signature">
              <HeadingText text={USER_PROFILE_STRING.SIGNATURE} classString="mobile-heading-h3 font-medium text-neutral-800 user-text" />
              <div className="flex flex-col items-start signature rounded-2 bg-white  box-shadow">
                <SignatureCanvas onEnd={handleEndSignature} penColor="black" canvasProps={{ width: 480, height: 132, className: 'sigCanvas' }} ref={signPadRef} />
                <HeadingText text={USER_PROFILE_STRING.RE_DRAW_SIGNATURE} classString="text-neutral-700 para-p3 cursor-pointer" onClick={clearSignature} />
              </div>
            </div>
          )}
        </div>
      </Form>
      {isPasswordModalOpen && (
        <CustomModal
          open={isPasswordModalOpen}
          onCancel={hideModal}
          title={BUTTON_STRING.CHANGE_PASS}
          showCancelBtn="none"
          footer={null}
          content={<ChangePwdForm setIsModalOpen={setIsPasswordModalOpen} isModalOpen={isPasswordModalOpen} email={updatedProfileData?.email ?? ''} />}
        />
      )}
    </div>
  )
}

export default UserProfile

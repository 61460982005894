import ActivityFeed from '../ActivityFeed'

const ProviderTracking = ({ isActivity }: any) => {
  return (
    <>
      <div className="provider-tracking-wrapper gap-4  flex item-start justify-center flex-1 self-stretch w-full overflow-x-scroll">
        <div className="tracking-container flex p-6 flex-col items-start gap-4 flex-1 self-stretch overflow-y-scroll">
          <div className="map-wrapper w-full h-full flex items-center justify-center">
            <iframe
              title="Google Maps Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3108.718724676611!2d-76.75482492425508!3d38.81599777174145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7941ee27a7689:0x5b831516d6b8dd13!2s14504 Main St, Upper Marlboro, MD 20772, USA!5e0!3m2!1sen!2sin!4v1715859277573!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: 12 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        {isActivity && <ActivityFeed />}
      </div>
    </>
  )
}

export default ProviderTracking

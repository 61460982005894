import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'

const initialState = {
  accessToken: '',
  permission: [],
  defaultFacility: '',
  signature: '',
  profileDetails: {
    country_code: '',
    description: '',
    email: '',
    facility_type: '',
    first_name: '',
    last_name: '',
    facility_name: '',
    facility_id: '',
    mobile_no: '',
    is_master: '',
    image: '',
    isFacility: false,
    base_url: '',
  },
  facility: {
    id: '',
    name: '',
    facility_type: '',
    is_master: '',
    image: '',
    city: null,
    state: null,
    country: null,
  },
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuthLogin: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.permission = action.payload.permission
      state.profileDetails = action.payload.profileDetails
    },
    setLogOut: (state) => {
      state.accessToken = ''
      state.permission = []
      state.signature = ''
    },
    setProfileData: (state, action) => {
      state.profileDetails = action.payload
    },
    setSignature: (state, action) => {
      state.signature = action.payload
    },
    setFacilityID: (state, action) => {
      state.defaultFacility = action.payload
    },
    setSelectedFacilityData: (state, action) => {
      state.facility = action.payload
    },
  },
})

export const { setAuthLogin, setLogOut, setSignature, setProfileData, setFacilityID, setSelectedFacilityData } = authSlice.actions

export const selectAuth = (state: RootState) => state.auth

export default authSlice.reducer

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .ant-modal-content {
  padding: 0;
}
.custom-modal .ant-modal-content .ant-modal-close {
  inset-inline-end: 24px;
  border-radius: 8px;
  top: 18px;
  border: 1px solid #e5e5e6;
}
.custom-modal .ant-modal-content .ant-modal-close:hover {
  background: transparent !important;
}
.custom-modal .ant-modal-content .ant-modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #e5e5e6;
  display: flex;
  align-items: center;
}
.custom-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 2.4rem;
}
.custom-modal .ant-modal-content .ant-modal-body {
  padding: 32px;
}
.custom-modal .ant-modal-content .ant-modal-footer {
  padding: 32px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.custom-modal .ant-modal-content .ant-modal-footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 1.6rem;
  width: 160px;
  height: 50px;
  border: none;
}

.hide-closeicon-modal .ant-modal-close {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/component/CustomModal/CustomModal.scss"],"names":[],"mappings":"AAGE;EACE,UAAA;AAFJ;AAGI;EACE,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,yBAAA;AADN;AAEM;EACE,kCAAA;AAAR;AAGI;EACE,kBAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;AADN;AAEM;EACE,iBAAA;AAAR;AAGI;EACE,aAAA;AADN;AAGI;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;AADN;AAGM;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AADR;;AAQE;EACE,aAAA;AALJ","sourcesContent":["@import '../../styles/variables';\n\n.custom-modal {\n  .ant-modal-content {\n    padding: 0;\n    .ant-modal-close {\n      inset-inline-end: 24px;\n      border-radius: 8px;\n      top: 18px;\n      border: 1px solid $stroke-color;\n      &:hover {\n        background: transparent !important;\n      }\n    }\n    .ant-modal-header {\n      padding: 16px 24px;\n      border-bottom: 1px solid $stroke-color;\n      display: flex;\n      align-items: center;\n      .ant-modal-title {\n        font-size: 2.4rem;\n      }\n    }\n    .ant-modal-body {\n      padding: 32px;\n    }\n    .ant-modal-footer {\n      padding: 32px;\n      padding-top: 0px;\n      display: flex;\n      align-items: center;\n      justify-content: end;\n\n      button {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 12px 24px;\n        border-radius: 12px;\n        font-size: 1.6rem;\n        width: 160px;\n        height: 50px;\n        border: none;\n      }\n    }\n  }\n}\n\n.hide-closeicon-modal {\n  .ant-modal-close {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

const FilterIcon = ({ hideRedDot }: { hideRedDot: boolean }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16669 10.3337C3.66669 10.3337 3.33335 10.667 3.33335 11.167V19.5003C3.33335 20.0003 3.66669 20.3337 4.16669 20.3337C4.66669 20.3337 5.00002 20.0003 5.00002 19.5003V11.167C5.00002 10.667 4.66669 10.3337 4.16669 10.3337ZM5.83335 7.00033H5.00002V4.50033C5.00002 4.00033 4.66669 3.66699 4.16669 3.66699C3.66669 3.66699 3.33335 4.00033 3.33335 4.50033V7.00033H2.50002C2.00002 7.00033 1.66669 7.33366 1.66669 7.83366C1.66669 8.33366 2.00002 8.66699 2.50002 8.66699H5.83335C6.33335 8.66699 6.66669 8.33366 6.66669 7.83366C6.66669 7.33366 6.33335 7.00033 5.83335 7.00033ZM10 17.0003C9.50002 17.0003 9.16669 17.3337 9.16669 17.8337V19.5003C9.16669 20.0003 9.50002 20.3337 10 20.3337C10.5 20.3337 10.8334 20.0003 10.8334 19.5003V17.8337C10.8334 17.3337 10.5 17.0003 10 17.0003ZM17.5 10.3337H16.6667V4.50033C16.6667 4.00033 16.3334 3.66699 15.8334 3.66699C15.3334 3.66699 15 4.00033 15 4.50033V10.3337H14.1667C13.6667 10.3337 13.3334 10.667 13.3334 11.167C13.3334 11.667 13.6667 12.0003 14.1667 12.0003H17.5C18 12.0003 18.3334 11.667 18.3334 11.167C18.3334 10.667 18 10.3337 17.5 10.3337ZM15.8334 13.667C15.3334 13.667 15 14.0003 15 14.5003V19.5003C15 20.0003 15.3334 20.3337 15.8334 20.3337C16.3334 20.3337 16.6667 20.0003 16.6667 19.5003V14.5003C16.6667 14.0003 16.3334 13.667 15.8334 13.667ZM11.6667 13.667H10.8334V4.50033C10.8334 4.00033 10.5 3.66699 10 3.66699C9.50002 3.66699 9.16669 4.00033 9.16669 4.50033V13.667H8.33335C7.83335 13.667 7.50002 14.0003 7.50002 14.5003C7.50002 15.0003 7.83335 15.3337 8.33335 15.3337H11.6667C12.1667 15.3337 12.5 15.0003 12.5 14.5003C12.5 14.0003 12.1667 13.667 11.6667 13.667Z"
        fill="#494A4C"
      />
      {hideRedDot && <circle cx="18" cy="4" r="3.5" fill="#D1293D" stroke="white" />}
    </svg>
  )
}

export default FilterIcon

import { useState } from 'react'
import { Form, message } from 'antd'
import type { Rule } from 'antd/es/form'
import CustomButton from 'component/Button/CustomButton'
import PasswordField from 'component/PasswordField/PasswordField'
import { BUTTON_STRING, COMMON } from 'constants/Constants'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IChangePassword, type IFieldData, type IPasswordFormProps } from 'types'
import { handleFieldsChange } from 'utils/helper'

import { CHANGE_FACILITY_USER_PASSWORD } from './api'

export const passwordValidations: Rule[] = [
  { required: true, message: 'Please input your password!' },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error('Password minimum 8 characters long'))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error('Include at least one number, one special character, one uppercase letter, and one lowercase letter.'))
        }
      }

      await Promise.resolve()
    },
  }),
]

const ChangePwdForm = ({ setIsModalOpen, isModalOpen, email }: IPasswordFormProps) => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState({
    old_password: '',
    new_password: '',
    confirmPassword: '',
  })

  const handleFormFinish = (values: IChangePassword) => {
    const payload = {
      email,
      old_password: values?.old_password,
      new_password: values?.new_password,
    }
    void handleChangePassword(payload)
  }

  const handleChangePassword = async (data: IChangePassword) => {
    dispatch(setGlobalLoader(true))
    const response = await CHANGE_FACILITY_USER_PASSWORD(data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsModalOpen(false)
    } else {
      void message.success(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const validateConfirmPassword = ({ getFieldValue }: { getFieldValue: (name: string) => any }) => ({
    async validator(_: any, value: any) {
      await new Promise<void>((resolve, reject) => {
        if (!value || getFieldValue('new_password') === value) {
          resolve()
        } else {
          reject(new Error('Confirm password does not match!'))
        }
      })
    },
  })

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      className="flex flex-col  change-password-modal"
      requiredMark={false}
      onFinish={handleFormFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div>
        <PasswordField
          placeholder={COMMON.CURRENT_PASSWORD}
          rules={[{ required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_CURRENT_PASSWORD }]}
          name="old_password"
          inputClass={'login-input password-input'}
          error={error.old_password}
        />
      </div>
      <div>
        <PasswordField placeholder={COMMON.NEW_PASSWORD} inputClass="login-input password-input" rules={passwordValidations} name="new_password" error={error.new_password} />
      </div>
      <div>
        <PasswordField
          placeholder={COMMON.CON_NEW_PASS}
          rules={[{ required: true, message: VALIDATION_ERROR.PASSWORD.EMPTY_CONFIRM_PASSWORD }, validateConfirmPassword]}
          name="confirmPassword"
          inputClass={'login-input password-input'}
          error={error.confirmPassword}
        />
      </div>
      <div className="footer-modal flex items-end justify-end">
        <CustomButton text={BUTTON_STRING.UPDATE} type="primary" htmlType="submit" className="w-160 rounded-3 border-0 h-49" />
      </div>
    </Form>
  )
}
export default ChangePwdForm

import { message } from 'antd'
import { http } from 'service/Service'
import { type IChangePassword } from 'types'

export const CHANGE_FACILITY_USER_PASSWORD = async (payload: IChangePassword) => {
  try {
    const response = await http.patch('/auth/change-password/facility_user', payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

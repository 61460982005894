import React from 'react'

const UsaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23" fill="none">
      <g clipPath="url(#clip0_4985_47991)">
        <rect width="32.2" height="23" rx="3" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H13.8V10.7333H0V0Z" fill="#1A47B8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8 0V1.53333H32.2V0H13.8ZM13.8 3.06667V4.6H32.2V3.06667H13.8ZM13.8 6.13333V7.66667H32.2V6.13333H13.8ZM13.8 9.2V10.7333H32.2V9.2H13.8ZM0 12.2667V13.8H32.2V12.2667H0ZM0 15.3333V16.8667H32.2V15.3333H0ZM0 18.4V19.9333H32.2V18.4H0ZM0 21.4667V23H32.2V21.4667H0Z"
          fill="#F93939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5332 1.53333V3.06666H3.06654V1.53333H1.5332ZM4.59987 1.53333V3.06666H6.1332V1.53333H4.59987ZM7.66654 1.53333V3.06666H9.19987V1.53333H7.66654ZM10.7332 1.53333V3.06666H12.2665V1.53333H10.7332ZM9.19987 3.06666V4.59999H10.7332V3.06666H9.19987ZM6.1332 3.06666V4.59999H7.66654V3.06666H6.1332ZM3.06654 3.06666V4.59999H4.59987V3.06666H3.06654ZM1.5332 4.59999V6.13333H3.06654V4.59999H1.5332ZM4.59987 4.59999V6.13333H6.1332V4.59999H4.59987ZM7.66654 4.59999V6.13333H9.19987V4.59999H7.66654ZM10.7332 4.59999V6.13333H12.2665V4.59999H10.7332ZM1.5332 7.66666V9.19999H3.06654V7.66666H1.5332ZM4.59987 7.66666V9.19999H6.1332V7.66666H4.59987ZM7.66654 7.66666V9.19999H9.19987V7.66666H7.66654ZM10.7332 7.66666V9.19999H12.2665V7.66666H10.7332ZM9.19987 6.13333V7.66666H10.7332V6.13333H9.19987ZM6.1332 6.13333V7.66666H7.66654V6.13333H6.1332ZM3.06654 6.13333V7.66666H4.59987V6.13333H3.06654Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4985_47991">
          <rect width="32.2" height="23" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UsaIcon

import React from 'react'

const DuplicateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15 5.83333H14.1667V5C14.1667 4.33696 13.9033 3.70107 13.4344 3.23223C12.9656 2.76339 12.3297 2.5 11.6667 2.5H5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5V11.6667C2.5 12.3297 2.76339 12.9656 3.23223 13.4344C3.70107 13.9033 4.33696 14.1667 5 14.1667H5.83333V15C5.83333 15.663 6.09673 16.2989 6.56557 16.7678C7.03441 17.2366 7.67029 17.5 8.33333 17.5H15C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15V8.33333C17.5 7.67029 17.2366 7.03441 16.7678 6.56557C16.2989 6.09673 15.663 5.83333 15 5.83333ZM5 12.5C4.77899 12.5 4.56702 12.4122 4.41074 12.2559C4.25446 12.0996 4.16667 11.8877 4.16667 11.6667V5C4.16667 4.77899 4.25446 4.56702 4.41074 4.41074C4.56702 4.25446 4.77899 4.16667 5 4.16667H11.6667C11.8877 4.16667 12.0996 4.25446 12.2559 4.41074C12.4122 4.56702 12.5 4.77899 12.5 5V5.83333H8.33333C7.67029 5.83333 7.03441 6.09673 6.56557 6.56557C6.09673 7.03441 5.83333 7.67029 5.83333 8.33333V12.5H5ZM15.8333 15C15.8333 15.221 15.7455 15.433 15.5893 15.5893C15.433 15.7455 15.221 15.8333 15 15.8333H8.33333C8.11232 15.8333 7.90036 15.7455 7.74408 15.5893C7.5878 15.433 7.5 15.221 7.5 15V8.33333C7.5 8.11232 7.5878 7.90036 7.74408 7.74408C7.90036 7.5878 8.11232 7.5 8.33333 7.5H15C15.221 7.5 15.433 7.5878 15.5893 7.74408C15.7455 7.90036 15.8333 8.11232 15.8333 8.33333V15Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default DuplicateIcon

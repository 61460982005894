import React from 'react'

const FlagIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? '14'} height={props.height ?? '16'} viewBox="0 0 14 16" fill="none">
      <path
        d="M13.0585 1.9C12.8683 1.76293 12.6484 1.67263 12.4167 1.63643C12.1851 1.60023 11.9482 1.61916 11.7252 1.69167C11.137 1.85707 10.5278 1.93568 9.91684 1.925C9.014 1.87599 8.12877 1.65539 7.30851 1.275C6.29519 0.80636 5.19912 0.542963 4.08351 0.5C1.67518 0.5 0.750177 1.33333 0.58351 1.45C0.503767 1.52816 0.440545 1.62155 0.397599 1.72462C0.354653 1.82769 0.332858 1.93834 0.33351 2.05V14.6667C0.33351 14.8877 0.421308 15.0996 0.577588 15.2559C0.733868 15.4122 0.94583 15.5 1.16684 15.5C1.38786 15.5 1.59982 15.4122 1.7561 15.2559C1.91238 15.0996 2.00018 14.8877 2.00018 14.6667V11.0833C2.6642 10.8291 3.37307 10.7128 4.08351 10.7417C4.98635 10.7907 5.87158 11.0113 6.69184 11.3917C7.70516 11.8603 8.80123 12.1237 9.91684 12.1667C10.9214 12.2046 11.9208 12.0047 12.8335 11.5833C13.0795 11.4678 13.2881 11.2854 13.4354 11.057C13.5828 10.8286 13.663 10.5634 13.6668 10.2917V3.09167C13.6665 2.85915 13.6113 2.62999 13.5056 2.42289C13.3998 2.21579 13.2467 2.03662 13.0585 1.9ZM12.0002 10.1583C11.3359 10.4117 10.6272 10.5279 9.91684 10.5C9.01289 10.4521 8.12693 10.2285 7.30851 9.84167C6.29326 9.37979 5.198 9.11942 4.08351 9.075C3.38143 9.0629 2.68085 9.14417 2.00018 9.31667V2.51667C2.66265 2.25611 3.37226 2.1369 4.08351 2.16667C4.98747 2.21457 5.87343 2.43818 6.69184 2.825C7.70709 3.28687 8.80236 3.54725 9.91684 3.59167C10.6194 3.60204 11.3201 3.51795 12.0002 3.34167V10.1583Z"
        fill={props.fill ?? '#494A4C'}
      />
    </svg>
  )
}

export default FlagIcon

const Edit = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M18.3333 6.5327C18.3339 6.42302 18.3129 6.31431 18.2715 6.21277C18.23 6.11124 18.1689 6.0189 18.0916 5.94103L14.5583 2.4077C14.4804 2.33046 14.3881 2.26936 14.2866 2.22789C14.185 2.18642 14.0763 2.1654 13.9666 2.16603C13.857 2.1654 13.7482 2.18642 13.6467 2.22789C13.5452 2.26936 13.4528 2.33046 13.375 2.4077L11.0166 4.76603L1.90831 13.8744C1.83107 13.9522 1.76997 14.0446 1.7285 14.1461C1.68703 14.2476 1.66601 14.3564 1.66664 14.466V17.9994C1.66664 18.2204 1.75444 18.4323 1.91072 18.5886C2.067 18.7449 2.27896 18.8327 2.49997 18.8327H6.03331C6.14991 18.839 6.26655 18.8208 6.37566 18.7792C6.48476 18.7375 6.5839 18.6734 6.66664 18.591L15.725 9.4827L18.0916 7.16603C18.1677 7.08526 18.2297 6.99231 18.275 6.89103C18.283 6.8246 18.283 6.75745 18.275 6.69103C18.2789 6.65224 18.2789 6.61315 18.275 6.57436L18.3333 6.5327ZM5.69164 17.166H3.33331V14.8077L11.6083 6.5327L13.9666 8.89103L5.69164 17.166ZM15.1416 7.71603L12.7833 5.3577L13.9666 4.1827L16.3166 6.5327L15.1416 7.71603Z"
        fill={props.className ?? '#494A4C'}
      />
    </svg>
  )
}
export default Edit

import { useState } from 'react'
import { Form, Radio, type RadioChangeEvent } from 'antd'
import CustomButton from 'component/Button/CustomButton'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import InputField from 'component/InputField/InputField'
import CommonTextArea from 'component/textArea/CommonTextArea'
import { BUTTON_STRING, COMMON, FACILITY_USER_PROFILE } from 'constants/Constants'
import { type IAddHoliday, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { commonInputFieldValidation, commonSelectFieldValidation, commonSelectValidation } from 'utils/Validations'

const AddHoliday = ({ isModalOpen }: any) => {
  const [value, setValue] = useState(1)
  const [errorData, setErrorData] = useState({
    name: '',
    bill_holiday: '',
    date: '',
  })
  const [form] = Form.useForm()
  const onRadioChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const handleCancel = () => {
    isModalOpen(false)
  }

  const onFinish = (values: IAddHoliday) => {
    console.log('Success:', values)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <Form
      form={form}
      className="flex flex-col self-stretch"
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setErrorData)
      }}
    >
      <InputField
        name="name"
        placeholder={FACILITY_USER_PROFILE.SELECT_CREATE_HOLIDAY}
        rules={commonInputFieldValidation('holiday')}
        error={errorData.name}
        value={getValue('name')}
      />
      <div className={`w-full`}>
        <CommonTextArea name="description" placeholder={COMMON.DESCRIPTION} rows={4} value={getValue('description')} />
      </div>
      <div className="flex flex-col gap-2 justify-center items-start self-stretch">
        <p className="text-neutral-800 para-p2 font-medium">{FACILITY_USER_PROFILE.BILL_HOLIDAY}</p>
        <div className={`w-full radio-btn-group ${errorData.bill_holiday ? '' : 'mb-6'}`}>
          <Form.Item name="bill_holiday" rules={commonSelectFieldValidation('bill option')}>
            <Radio.Group onChange={onRadioChange} value={value}>
              <Radio value={1}>{COMMON.YES}</Radio>
              <Radio value={2}>{COMMON.NO}</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {errorData.bill_holiday && <p className="error-container">{errorData.bill_holiday}</p>}
      </div>
      <CustomDatePickerField form={form} name="date" placeholder={COMMON.SELECT_DATE} rules={commonSelectValidation('date')} error={errorData.date} value={getValue('date')} />
      <div className="flex gap-4 w-full justify-end">
        <CustomButton type="default" text={BUTTON_STRING.CANCEL} className="w-160 h-49" onClick={handleCancel} />
        <CustomButton text={BUTTON_STRING.ADD} type="primary" htmlType="submit" className="w-160 h-49" />
      </div>
    </Form>
  )
}

export default AddHoliday

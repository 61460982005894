import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Dropdown, Image, message, Tabs, type TabsProps } from 'antd'
import { profile } from 'assets/imgs'
import { BlackFlag, ChatProvider, CheckIcon, CloseIcon, ProviderProfileBadge } from 'assets/svgs'
import { ChatsIcon } from 'assets/svgs/sidebar/SidebarMenuIcons'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, PROVIDER_STRING } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/helper'

import Credential from './components/Credential'
import Detail from './components/Detail'
import Evaluation from './components/Evaluation'
import Schedule from './components/Schedule'
import ShiftHistory from './components/ShiftHistory'
import AddEvaluation from './modal/AddEvaluation'
import FlagAsDnr from './modal/FlagAsDnr'
import ReqProviderToWork from './modal/RequestProviderToWork'
import { GET_PROVIDER_SUMMARY, UPDATE_PROVIDER_FLAG } from './api'

import './Provider.scss'

const ProviderProfile = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [isAddEvaluation, setIsAddEvaluation] = useState<boolean>(false)
  const [isReqProviderToWork, setIsOpenReqProvToWork] = useState<boolean>(false)
  const [profileData, setProfileData] = useState({
    profileImg: '',
    name: '',
    first_name: '',
    email: '',
    mobile_no: '',
    certificate: '',
    certificate_bgcolor: '',
    certificate_textcolor: '',
    attendance: '100%',
    hoursWork: '',
    onTimeRate: '',
    shiftCompleted: '',
    showRate: '',
    city: '',
    state: '',
  })
  const [selectedRowData, setSelectedRowData] = useState({
    id: '',
    first_name: '',
  })
  const [isFlagAsDnr, setIsFlagAsDnrOpen] = useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { record, tabNo } = location.state || {}
  const [flagName, setFlagName] = useState(record?.flag ?? '')

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: <Detail profileDetailId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '2',
      label: 'Schedule',
      children: <Schedule providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '3',
      label: 'Credentials',
      children: <Credential providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '4',
      label: 'Shifts History',
      children: <ShiftHistory providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '5',
      label: 'Evaluations',
      children: <Evaluation />,
    },
  ]

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.PROVIDER_PROFILE,
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (record) {
      if (record?.provider?.id) void getProfileData(record?.provider?.id)
    }
  }, [record])

  const getProfileData = async (profileId: any) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_PROVIDER_SUMMARY(profileId)
      if (response?.status === 200) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { first_name, last_name, profile_image, base_url, email, mobile_no, certificate, address, total_worked, shifts_completed, show_rate, on_time_rate } =
          // eslint-disable-next-line no-unsafe-optional-chaining
          response?.data?.data
        setProfileData({
          profileImg: profile_image ? `${base_url as string}${profile_image as string}` : profile,
          name: `${String(first_name)} ${String(last_name)}` ?? '',
          first_name: `${String(first_name)}`,
          email: email ?? '',
          mobile_no: mobile_no ?? '',
          certificate: certificate?.name ?? '',
          certificate_bgcolor: certificate?.background_color ?? '',
          certificate_textcolor: certificate?.text_color ?? '',
          attendance: '0%',
          hoursWork: total_worked ?? 0,
          onTimeRate: on_time_rate ?? 0,
          shiftCompleted: shifts_completed ?? 0,
          showRate: show_rate ?? 0,
          city: address?.city?.name ?? '',
          state: address?.state?.name ?? '',
        })
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const updateFlag = async (flagname: string, id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await UPDATE_PROVIDER_FLAG(id, { flag: flagname })
      if (response?.status === 200) {
        record.flag = flagname
        void message.success(response?.data?.message)
        setFlagName(flagname)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDnrClick = () => {
    setIsFlagAsDnrOpen(true)
  }

  const onChange = (key: string) => {
    setActiveTab(key)
  }

  const handleEvaluationClick = () => {
    setIsAddEvaluation(true)
  }

  const handleOpenReqToWorkClick = () => {
    setIsOpenReqProvToWork(true)
  }

  return (
    <div className="provider-profile-container flex justify-start items-start rounded-4 w-full">
      <div className="profile-main-wrapper flex flex-col items-center gap-6">
        <div className="profile-pick-container flex flex-col items-center gap-5">
          <div className="img-wrapper">
            <Image src={profileData?.profileImg ? profileData?.profileImg : profile} className="profile-img" preview={false} />
            <div className="profile-badge">
              <ProviderProfileBadge />
            </div>
          </div>
        </div>
        <div className="profile-info-wrapper flex flex-col items-center gap-2">
          {profileData?.name && <p className="mobile-heading-h6 font-medium text-neutral-800">{formatTitle(profileData?.name)}</p>}
          {profileData?.certificate && (
            <div className="badge-info flex justify-center items-start gap-2">
              <p
                className="profile-certificate-text flex justify-center items-center gap-2"
                style={{
                  background: profileData?.certificate_bgcolor || 'none',
                  color: profileData?.certificate_textcolor ?? '',
                }}
              >
                {profileData?.certificate}
              </p>
            </div>
          )}
        </div>

        <div className="emp-detail-wrapper flex items-start justify-start gap-2 flex-wrap">
          <div className="attendance-box detail-box">
            <p className="text-20 font-semi-bold text-neutral-800">{profileData?.attendance}</p>
            <p className="text-neutral-600 para-p3 font-normal">{PROVIDER_STRING.ATTENDANCE_SCORE}</p>
          </div>
          <div className="grid grid-col-2 gap-2 w-full">
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.hoursWork}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.HOUSE_WORKED}</p>
            </div>
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.onTimeRate}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.ON_TIME_RATE}</p>
            </div>
            <div className="hours-box shift-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.shiftCompleted}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.SHIFT_COMPLETED}</p>
            </div>
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.showRate}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.SHOW_RATE}</p>
            </div>
          </div>
        </div>
        <div className="location-box">
          {(profileData?.city || profileData?.state) && (
            <div className="location-wrapper">
              <p className="text-neutral-500 para-p3 font-normal">{PROVIDER_STRING.LOCATION}</p>
              <p className="text-neutral-800 para-p2 font-normal">
                {profileData?.city}
                {profileData?.city && profileData?.state ? ', ' : ''}
                {profileData?.state}
              </p>
            </div>
          )}
          <div className="mobile-box">
            <p className="text-neutral-500 para-p3 font-normal">{PROVIDER_STRING.MOBILE_EMAIL}</p>
            <div className="contact-wrapper">
              {profileData?.mobile_no && (
                <div className="mobile-wrapper">
                  <p className="text-neutral-800 para-p2 font-normal">{profileData?.mobile_no}</p>
                  <CheckIcon />
                </div>
              )}
              {profileData.email && (
                <div className="mobile-wrapper">
                  <p className="text-neutral-800 para-p2 font-normal">{profileData?.email}</p>
                  <CheckIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-wrapper">
        <Tabs defaultActiveKey={tabNo || '1'} items={items} onChange={onChange} rootClassName="provider-tab" />
        <div className="btn-wrapper">
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div className={`flag-option-wrapper ${flagName === 'preferred' ? 'opacity-30 cursor-not-allowed' : ''}`}>
                      <BlackFlag className="green-flag" />
                      <span className="text-neutral-800 para-p3 font-normal">{PROVIDER_STRING.FLAG_GREEN}</span>
                    </div>
                  ),
                  key: 0,
                  onClick: () => {
                    if (flagName !== 'preferred') void updateFlag('preferred', record?.id)
                  },
                },
                {
                  label: (
                    <div className={`flag-option-wrapper ${flagName === 'caution' ? 'opacity-30 cursor-not-allowed' : ''}`}>
                      <BlackFlag className="yellow-flag" />
                      <span className="text-neutral-800 para-p3 font-normal">{PROVIDER_STRING.FLAG_YELLOW}</span>
                    </div>
                  ),
                  key: 1,
                  onClick: () => {
                    if (flagName !== 'caution') void updateFlag('caution', record?.id)
                  },
                },
                {
                  label: (
                    <div className={`flag-option-wrapper ${flagName === 'dnr' ? 'opacity-30 cursor-not-allowed' : ''}`}>
                      <BlackFlag className="red-flag" />
                      <span className="text-neutral-800 para-p3 font-normal">{PROVIDER_STRING.FLAG_DNR}</span>
                    </div>
                  ),
                  key: 2,
                  onClick: () => {
                    if (flagName !== 'dnr') {
                      setSelectedRowData({
                        id: record.id ?? '',
                        first_name: record?.provider?.first_name,
                      })
                      handleDnrClick()
                    }
                  },
                },
              ],
            }}
            trigger={['click']}
          >
            <div className="flex justify-center">
              <div className={`flex items-center justify-center width-40 h-45 rounded-2 border-stroke`}>
                <BlackFlag
                  className={(() => {
                    switch (flagName) {
                      case 'preferred':
                        return 'green-flag'
                      case 'caution':
                        return 'yellow-flag'
                      case 'dnr':
                        return 'red-flag'
                      default:
                        return '#7D7E82'
                    }
                  })()}
                />
              </div>
            </div>
          </Dropdown>
          <CustomButton isIcon svgIcon={<ChatProvider />} className="p-2 rounded-2 width-40 h-45 border-stroke" type="text" />
          {activeTab === '5' ? (
            <CustomButton text={BUTTON_STRING.ADD_EVALUATION} className="rounded-2 border-0 btn-text para-p3 h-38" onClick={handleEvaluationClick} />
          ) : (
            <CustomButton text={BUTTON_STRING.REQ_TO_WORK} className="rounded-2 border-0 btn-text para-p3 h-38" onClick={handleOpenReqToWorkClick} />
          )}
        </div>
      </div>
      {isAddEvaluation && (
        <CustomModal
          width={1063}
          closeIcon={<CloseIcon fill={'#494A4C'} />}
          onCancel={() => {
            setIsAddEvaluation(false)
          }}
          open={isAddEvaluation}
          title={PROVIDER_STRING.NEW_EVALUATION}
          footer={null}
          closable={false}
          content={<AddEvaluation setIsModalOpen={setIsAddEvaluation} />}
        />
      )}
      {isReqProviderToWork && (
        <CustomModal
          width={1063}
          closeIcon={<CloseIcon fill={'#494A4C'} />}
          onCancel={() => {
            setIsOpenReqProvToWork(false)
          }}
          open={isReqProviderToWork}
          title={`${COMMON.REQUEST} ${profileData.first_name ? profileData.first_name : 'Catherine'} ${COMMON.FOR_WORK}`}
          footer={null}
          closable={false}
          content={<ReqProviderToWork setIsModalOpen={setIsOpenReqProvToWork} />}
        />
      )}
      {isFlagAsDnr && (
        <CustomModal
          onCancel={() => {
            setIsFlagAsDnrOpen(false)
          }}
          open={isFlagAsDnr}
          title={`${PROVIDER_STRING.FLAG_DNR} ${selectedRowData ? selectedRowData?.first_name : 'Catherine'}?`}
          footer={null}
          className="hide-closeicon-modal"
          closable={false}
          content={<FlagAsDnr isModalOpen={setIsFlagAsDnrOpen} updateId={selectedRowData.id} setFlagName={setFlagName} />}
        />
      )}
    </div>
  )
}

export default ProviderProfile

import React, { useEffect, useState } from 'react'
import { Avatar, Button, Checkbox, Form, message, Modal, Radio, Tooltip } from 'antd'
import { profile } from 'assets/imgs/index'
import {
  AwardIcon,
  BuildingIcon,
  ClockIcon,
  CloseIcon,
  DatePickerIcon,
  DeleteIcon,
  DuplicateIcon,
  EditPenIcon,
  FacilityIcon,
  HospitalOutline,
  LocationIcon,
  MinusIcon,
  PlusIconSolid,
  StethoscopeIcon,
  TimePickerIcon,
  UserPlusIcon,
} from 'assets/svgs'
import CalenderSelectedDate from 'assets/svgs/CalenderSelectedDate'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import CustomDatePickerField from 'component/DatePicker/CustomDatePicker'
import DeleteCancelModal from 'component/DeleteCancelModal/DeleteCancelModal'
import CustomSelect from 'component/Select/CustomSelect'
import CommonTextArea from 'component/textArea/CommonTextArea'
import TimePickerField from 'component/TimePicker/TimePicker'
import { COMMON, SHIFTS } from 'constants/Constants'
import { consecutiveWeeksData, isRepeatOnData } from 'constants/MenuConstant'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { GET_CERTIFICATE, GET_FACILITY, GET_SPECIALITY } from 'service/api/Common'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { ICreteShiftModalProps, IFacility, IFieldData, IShift, SelectedDate } from 'types'
import { formatTime, handleFieldsChange, transformOptionGroup } from 'utils/helper'
import { commonSelectFieldValidation, commonSelectValidation } from 'utils/Validations'

import {
  ALL_UNPOSTED_SHIFT_DELETE,
  CLONE_SHIFT,
  CREATE_SHIFT,
  DELETE_UNPOSTED_SHIFT,
  GET_SHIFT_TYPE,
  GET_SINGLE_SHIFT,
  GET_UNPOSTED_SHIFTS,
  POST_SHIFT,
  UPDATE_SHIFT,
} from '../api'
import CustomCalender from '../components/CustomCalender'
import InviteProvider from '../InviteProvider'
import type { ICreateShift, IProviderInvite } from '../types'

import '../Shifts.scss'

const floorData = [
  {
    label: 'Floor A',
    value: 'Floor A',
  },
  {
    label: 'Floor B',
    value: 'Floor B',
  },
  {
    label: 'Floor C',
    value: 'Floor C',
  },
]

const CreateShift = ({ rowId, setRowId, isCreateShiftModalOpen, setIsCreateShiftModalOpen }: ICreteShiftModalProps) => {
  const [isRepeatOn, setIsRepeatOn] = useState<boolean>(false)
  const [facilityData, setFacilityData] = useState<IFacility[]>([])
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [certificareOriginData, setCertificateOriginData] = useState<IFacility[]>([])
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [specialityOriginData, setSpecialityOriginData] = useState<IFacility[]>([])
  const [unpostedData, setUnpostedData] = useState<IShift[] | any>([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string>('')
  const [createShiftValue, setCreateShiftValue] = useState<any>()
  const [shiftDetails, setShiftDetails] = useState<IShift | null>(null)
  const [isCommonCancelModalOpen, setIsCommonCancelModalOpen] = useState<boolean>(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false)
  const [selectedInviteProvider, setSelectedInviteProvider] = useState<IProviderInvite[]>([])
  const [deleteId, setDeleteId] = useState<string>('')
  const [isEditInvitedData, setIsEditInvitedData] = useState<boolean>(false)
  const [shiftTypeOption, setShiftTypeOption] = useState<any[]>([])
  const [isSelectedDateModalOpen, setIsSelectedDateModalOpen] = useState<boolean>(false)
  const [selectedDates, setSelectedDates] = useState<SelectedDate[]>([])
  const [error, setError] = useState({
    facility: '',
    start_date: '',
    certificate: '',
    speciality: '',
    start_time: '',
    shift_type: '',
    end_time: '',
    shiftTime: '',
    floor: '',
    repeat_on: '',
    end_date: '',
    days: '',
    calendarData: '',
  })

  const [selectedRadioData, setSelectedRadioData] = useState<string>(isRepeatOnData[0]?.value)
  const [counter, setCounter] = useState(1)
  const profileData: any = useAppSelector((state) => state?.auth?.auth?.profileDetails)
  const facilityId = useAppSelector((state) => state?.auth?.auth?.defaultFacility)
  const selectedFacilityData: any = useAppSelector((state) => state?.auth?.auth?.facility)
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null)
  const [reset, setReset] = useState<boolean>(false)
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const formData = form.getFieldsValue()

  useEffect(() => {
    if (facilityData && facilityData.length > 0) {
      const defaultFacility = facilityData.find((item) => item.id === facilityId)
      if (defaultFacility) {
        setSelectedFacility(facilityId)
        setCreateShiftValue((prev: any) => ({
          ...prev,
          facility: facilityId,
          facility_name: defaultFacility.name,
        }))
        form.setFieldValue('facility', facilityId)
        const perDiamItem = shiftTypeOption?.find((item) => item?.title === 'per_diem')
        form.setFieldValue('shift_type', perDiamItem?.options?.[0]?.value || '')
      }
    }
  }, [facilityData, setCreateShiftValue])

  const handleChange = (id: string) => {
    const selectedItem = facilityData.find((item) => item.id === id)
    if (selectedItem) {
      setCreateShiftValue({
        ...createShiftValue,
        facility: id,
        facility_name: selectedItem.name,
      })
      setSelectedFacility(id)
    }
  }

  useEffect(() => {
    void getFacility()
    void getCertificate()
    void getSpeciality()
    void getUnpostedShift()
    void getShiftType()
  }, [])

  useEffect(() => {
    if (rowId) {
      void getShift()
    }
  }, [rowId])

  useEffect(() => {
    form.setFieldsValue({
      certificate: shiftDetails?.certificate?.id,
      speciality: shiftDetails?.speciality?.id,
      facility: shiftDetails?.facility?.id,
      follower: profileData?.id,
      shift_type: shiftDetails?.shift_type,
    })
  }, [shiftDetails !== null])

  const handleMinusClick = () => {
    setCounter(counter > 1 ? counter - 1 : 1)
  }

  const getFacility = async () => {
    try {
      const response = await GET_FACILITY()
      if (response?.status === 200) {
        setFacilityData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getShiftType = async () => {
    try {
      const response = await GET_SHIFT_TYPE()
      if (response?.status === 200) {
        const result = transformOptionGroup(response?.data)
        const filteredData = result?.filter((item) => item?.options && item?.options?.length > 0)
        setShiftTypeOption(filteredData)
        const perDiamItem = result.find((item) => item?.title === 'per_diem')
        if (perDiamItem && perDiamItem?.options?.length > 0) {
          form.setFieldValue('shift_type', perDiamItem?.options[0]?.value)
        }
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const handleDeleteAllUnpostedShift = async () => {
    try {
      const response = await ALL_UNPOSTED_SHIFT_DELETE(facilityId)
      if (response?.status === 200) {
        setIsCreateShiftModalOpen(false)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const handleCreateModalClose = () => {
    unpostedData?.length === 0 ? setIsCreateShiftModalOpen(false) : setIsCommonCancelModalOpen(true)
  }

  const getShift = async () => {
    try {
      const response = await GET_SINGLE_SHIFT(rowId)
      if (response?.status === 200) {
        setShiftDetails(response?.data?.data)
        setRowId('')
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getCertificate = async () => {
    try {
      const response = await GET_CERTIFICATE()
      if (response?.status === 200) {
        setCertificateData(response?.data?.data)
        setCertificateOriginData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getSpeciality = async () => {
    try {
      const response = await GET_SPECIALITY()
      if (response?.status === 200) {
        setSpecialityData(response?.data?.data)
        setSpecialityOriginData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getUnpostedShift = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_UNPOSTED_SHIFTS(facilityId)
      if (response?.status === 200) {
        setUnpostedData(response?.data?.data)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handelRepeatChange = () => {
    if (!isRepeatOn) {
      setIsRepeatOn(true)
    } else {
      setIsRepeatOn(false)
    }
  }

  const handleRadioChange = (value: string) => {
    setSelectedRadioData(value)
  }

  const handlePlusClick = () => {
    setCounter(counter + 1)
  }

  const handleResetField = () => {
    form.resetFields()
    form.setFieldValue('facility', facilityId)
    const perDiamItem = shiftTypeOption?.find((item) => item?.title === 'per_diem')
    form.setFieldValue('shift_type', perDiamItem?.options?.[0]?.value || '')
    setReset(true)
  }

  const cloneShift = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await CLONE_SHIFT(id)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        void getUnpostedShift()
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePostData = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const ids: string[] = unpostedData?.map((shift: { id: string }) => shift.id)

      const payload = {
        shift: ids,
      }
      const response = await POST_SHIFT(payload)
      if (response?.data?.statusCode === 1) {
        setIsCreateShiftModalOpen(false)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditUnpostedShift = (value: IShift) => {
    setEditId(value?.id)
    setSelectedInviteProvider(value?.invited_provider)
    // setSelectedRadioData(value?.)
    form.setFieldsValue({
      certificate: value?.certificate?.id,
      speciality: value?.speciality?.id,
      facility: value?.facility?.id,
      follower: profileData?.id,
      shift_type: value?.shift_type_id,
      floor: 'Floor A',
      start_time: dayjs(value?.start_time, 'HH:mm:ss'),
      end_time: dayjs(value?.end_time, 'HH:mm:ss'),
      start_date: dayjs(value?.start_date),
      end_date: dayjs(value?.end_date),
      description: value?.description,
    })
  }

  const handleInviteClick = () => {
    setIsInviteModalOpen(true)
    setIsEditInvitedData(true)
  }

  const handleDeleteShift = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await DELETE_UNPOSTED_SHIFT(id)
      if (response?.status === 200) {
        setIsDeleteModalOpen(false)
        void message.success(response?.data?.message)
        void getUnpostedShift()
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = async (values: ICreateShift) => {
    const hasError = Object.values(error).some((message) => message !== '')
    if (hasError) {
      return
    }
    dispatch(setGlobalLoader(true))
    try {
      const ids: string[] = selectedInviteProvider?.map((provider: { id: string }) => provider?.id)
      const payload = {
        certificate: values?.certificate,
        speciality: values?.speciality,
        facility: values?.facility,
        follower: profileData?.id,
        shift_type: values?.shift_type,
        invited_provider: ids,
        floor: values?.floor,
        start_time: dayjs(values?.start_time).format('HH:mm:ss'),
        end_time: dayjs(values?.end_time).format('HH:mm:ss'),
        ...(!editId && { openings: counter }),
        ...(!editId && { is_repeat: values?.is_repeat }),
        ...(!editId && selectedRadioData && values?.is_repeat && { repeat_on: selectedRadioData }),
        ...(!editId && { days: values?.days }),
        ...(!editId && selectedDates?.length !== 0 && { specific_dates: selectedDates }),
        start_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
        description: values?.description,
      }
      let response
      if (editId) {
        response = await UPDATE_SHIFT(editId, payload)
      } else {
        response = await CREATE_SHIFT(payload)
      }

      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        form.resetFields()
        form.setFieldValue('facility', facilityId)
        const perDiamItem = shiftTypeOption?.find((item) => item?.title === 'per_diem')
        form.setFieldValue('shift_type', perDiamItem?.options?.[0]?.value || '')
        setCounter(1)
        setEditId('')
        setSelectedRadioData(isRepeatOnData[0]?.value)
        setSelectedInviteProvider([])
        setShiftDetails(null)
        setIsRepeatOn(false)
        void getUnpostedShift()
      } else {
        void message.error(response?.data?.message[0])
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const handleSearchLicense = (search: string) => {
    if (search !== '') {
      const filteredData = certificateData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setCertificateData(filteredData)
      return
    }
    setCertificateData(certificareOriginData)
  }

  const handleSearchSpeciality = (search: string) => {
    if (search !== '') {
      const filteredData = specialityData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setSpecialityData(filteredData)
      return
    }
    setSpecialityData(specialityOriginData)
  }

  return (
    <>
      <Modal
        open={isCreateShiftModalOpen}
        centered
        maskClosable
        onCancel={handleCreateModalClose}
        wrapClassName="modal-wrapper"
        closable={false}
        closeIcon={null}
        footer={null}
        className="create-shift-modal"
      >
        <div className="flex wrapper-create-shift">
          <div className="details-div">
            <div className="neutral w-auto px-3 py-6px w-fit">{SHIFTS.DRAFT}</div>
            <div className="mt-6">
              <p className="font-medium text-neutral-800 mobile-heading-h5">
                {createShiftValue?.facility_name ? createShiftValue?.facility_name : shiftDetails ? shiftDetails?.facility?.name : SHIFTS.NO_FACILITY}
              </p>
              <p className="mt-2 para-p2 text-neutral-700">{formData?.start_date ? dayjs(formData?.start_date).format('ddd, DD MMM YYYY') : SHIFTS.NO_DATE}</p>
            </div>

            <div className="mt-4 flex gap-2">
              <p>{createShiftValue?.certificate_name && createShiftValue?.certificate_name}</p>
              <p>{createShiftValue?.speciality_name && createShiftValue?.speciality_name}</p>
            </div>

            <div className="flex gap-2 mt-6">
              <ClockIcon fill="#919195" />
              <p className="text-neutral-600 para-p2 font-normal">
                {formData?.start_time && dayjs(formData?.start_time).format('h:mm A')} - {formData?.end_time && dayjs(formData?.end_time).format('h:mm A')}
              </p>
            </div>
            <div className="flex gap-2 mt-6">
              <BuildingIcon />
              <p className="text-neutral-600 para-p2 font-normal">
                {SHIFTS.FLOOR} {formData?.floor}
              </p>
            </div>
            <div className="flex gap-2 mt-6">
              <LocationIcon />
              <p className="text-neutral-600 para-p2 font-normal">{selectedFacilityData?.city?.name}</p>
            </div>

            <div className="mt-6">
              <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.PROVIDER}</p>
              {selectedInviteProvider.length === 0 ? (
                <CustomButton
                  disabled={!formData?.speciality || !formData?.certificate}
                  isIcon
                  svgIcon={<UserPlusIcon />}
                  text={COMMON.INVITE}
                  type="default"
                  className="text-primary-500 svg"
                  onClick={handleInviteClick}
                />
              ) : (
                <Avatar.Group
                  max={{
                    count: 4,
                    style: {
                      color: '#2B12DD',
                      backgroundColor: '#F2F0FE',
                    },
                  }}
                >
                  {selectedInviteProvider.map((provider: IProviderInvite) => {
                    return (
                      <Tooltip title={provider?.first_name} key={provider?.id}>
                        {/* eslint-disable-next-line */}
                        <Avatar src={provider?.profile_image ? `${provider?.base_url}${provider?.profile_image}` : profile} alt="Provider Image" onClick={handleInviteClick} />
                      </Tooltip>
                    )
                  })}
                </Avatar.Group>
              )}

              {!formData?.speciality || !formData?.certificate ? <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.INVITE_INSTRUCTION}</p> : null}
            </div>
            <div className="mt-6">
              <p className="text-neutral-500 para-p3 font-normal mb-2 mt-2">{SHIFTS.CONTACT}</p>
              <div className="flex gap-2 items-center">
                <Avatar src={profileData?.image ? `${profileData?.base_url as string}${profileData?.image as string}` : profile} />
                <p className="profile-text">{`${profileData?.first_name as string} ${profileData?.last_name as string}`}</p>
              </div>
            </div>
          </div>
          <Form
            className="form-div"
            form={form}
            onFinish={onFinish}
            onFieldsChange={(allFields: IFieldData[]) => {
              const hasNoSelectedDates = isRepeatOn && selectedRadioData === isRepeatOnData[3]?.value && selectedDates.length === 0
              setError((prev: any) => ({
                ...prev,
                calendarData: hasNoSelectedDates ? `${VALIDATION_ERROR.SINGLE_SELECT} date from calendar` : '',
              }))
              handleFieldsChange(allFields, setError)
            }}
          >
            <div className="form-header">
              <div className="flex h-full p-10px items-end border-b2-primary-500">
                <p className="para-p2 text-primary-500 font-medium">{SHIFTS.DETAILS}</p>
              </div>
              <div className="p-2 border-stroke flex bg-white rounded-2 h-38">
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <CloseIcon className="cursor-pointer" onClick={handleCreateModalClose} />
              </div>
            </div>
            <div className="form-wrapper w-full">
              <div className="create-form p-6 w-60 h-full ">
                <CustomSelect
                  value={selectedFacility ?? getValue('facility')}
                  onChange={handleChange}
                  label={SHIFTS.SELECT_FACILITY}
                  options={facilityData?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  isSearch
                  searchPlaceholder={COMMON.SEARCH_FACILITY}
                  prefixIcon={<FacilityIcon />}
                  name="facility"
                  error={error?.facility}
                  rules={commonSelectFieldValidation('facility!')}
                />

                <div className="grid grid-col-2 gap-4 w-full">
                  <CustomSelect
                    value={getValue('shift_type')}
                    onChange={(e) => {
                      setCreateShiftValue({
                        ...createShiftValue,
                        shift_type: e,
                      })
                    }}
                    label={SHIFTS.SHIFT_TYPE}
                    options={shiftTypeOption}
                    name="shift_type"
                    error={error?.shift_type}
                    rules={commonSelectFieldValidation('shift type!')}
                    reset={reset}
                  />

                  <CustomDatePickerField
                    name="start_date"
                    error={error?.start_date}
                    placeholder={SHIFTS.DATE}
                    icon={<DatePickerIcon />}
                    rules={commonSelectFieldValidation('date!')}
                    form={form}
                    value={getValue('start_date')}
                    reset={reset}
                  />
                </div>

                <div className="grid grid-col-2 gap-4 w-full">
                  <CustomSelect
                    label={COMMON.LICENSE}
                    options={certificateData?.map((item: { name: string; id: string }) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    value={getValue('certificate')}
                    onChange={(id) => {
                      const selectedItem = facilityData?.find((item) => item.id === id)
                      if (selectedItem) {
                        setCreateShiftValue({
                          ...createShiftValue,
                          certificate: id,
                          certificate_name: selectedItem.name,
                        })
                      }
                    }}
                    isSearch
                    searchPlaceholder={COMMON.SEARCH_LICENCE}
                    name="certificate"
                    error={error?.certificate}
                    rules={commonSelectFieldValidation('license!')}
                    searchData={handleSearchLicense}
                  />

                  <CustomSelect
                    label={COMMON.SPECIALITY}
                    options={specialityData?.map((item: { name: string; id: string }) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    value={getValue('speciality')}
                    onChange={(id) => {
                      const selectedItem = facilityData?.find((item) => item.id === id)
                      if (selectedItem) {
                        setCreateShiftValue({
                          ...createShiftValue,
                          speciality: id,
                          speciality_name: selectedItem.name,
                        })
                      }
                    }}
                    isSearch
                    searchPlaceholder={COMMON.SEARCH_SPECIALITY}
                    name="speciality"
                    error={error?.speciality}
                    rules={commonSelectFieldValidation('speciality!')}
                    searchData={handleSearchSpeciality}
                  />
                </div>

                <div className="grid grid-col-2 gap-4 w-full">
                  <TimePickerField
                    form={form}
                    reset={reset}
                    format="h:mm a"
                    name="start_time"
                    error={error?.start_time}
                    placeholder={SHIFTS.START_TIME}
                    icon={<TimePickerIcon />}
                    value={getValue('start_time')}
                    rules={commonSelectFieldValidation('start time!')}
                  />

                  <TimePickerField
                    form={form}
                    reset={reset}
                    format="h:mm a"
                    name="end_time"
                    error={error?.end_time}
                    placeholder={SHIFTS.END_TIME}
                    icon={<TimePickerIcon />}
                    value={getValue('end_time')}
                    rules={commonSelectFieldValidation('end time!')}
                  />
                </div>

                <div className="grid grid-col-2 gap-4 w-full">
                  {!editId && (
                    <div className="character-box">
                      <p className="character-text">{SHIFTS.OPENINGS}</p>
                      <div className="counter-box">
                        <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
                        <div className="counter-text-wrapper">
                          <p className="counter-text">{counter}</p>
                        </div>
                        <Button icon={<PlusIconSolid />} onClick={handlePlusClick} className="counter-btn-plus" />
                      </div>
                    </div>
                  )}

                  <CustomSelect
                    label={SHIFTS.FLOOR}
                    options={floorData}
                    name="floor"
                    value={getValue('floor')}
                    onChange={(floor) => {
                      setCreateShiftValue({
                        ...createShiftValue,
                        floor,
                      })
                    }}
                  />
                </div>

                {!editId && (
                  <div className="flex gap-4 w-full mb-6 items-center date-height">
                    <div className="w-full">
                      <Form.Item name="is_repeat" valuePropName="checked" className="repeat-checkbox">
                        <Checkbox checked={isRepeatOn} onChange={handelRepeatChange}>
                          {SHIFTS.REPEAT_ON}
                        </Checkbox>
                      </Form.Item>
                      {isRepeatOn && (
                        <div className="flex flex-col gap-4">
                          <Form.Item name="repeat_on" valuePropName="checked">
                            <Radio.Group
                              options={isRepeatOnData}
                              className="repeat-btn"
                              onChange={(e: any) => {
                                handleRadioChange(e?.target?.value)
                              }}
                              value={selectedRadioData}
                            />
                          </Form.Item>
                          {selectedRadioData === isRepeatOnData[3]?.value && (
                            <>
                              <div
                                className="rounded-2 flex gap-3 py-2 px-4 bg-primary-bg cursor-pointer w-fit"
                                onClick={() => {
                                  setIsSelectedDateModalOpen(true)
                                }}
                              >
                                <CalenderSelectedDate />
                                <p className="para-p3 font-semi-bold text-primary-500">{SHIFTS.SELECTED_DATES}</p>
                              </div>
                              {error?.calendarData && <p className="error-container">{error.calendarData}</p>}
                            </>
                          )}
                          {selectedRadioData === isRepeatOnData[2]?.value && (
                            <>
                              <Form.Item name="days" rules={commonSelectValidation('days')}>
                                <Checkbox.Group className="checkbox-week" options={consecutiveWeeksData} />
                              </Form.Item>
                              {error?.days && <p className="error-container">{error.days}</p>}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className={`w-full mb-6`}>
                  <CommonTextArea name="description" placeholder={SHIFTS.SHIFT_DETAILS} rows={4} value={getValue('description')} />
                </div>
              </div>
              <div className="flex flex-col gap-6 w-40 p-6 items-center border-l-stroke unposted-data">
                {unpostedData?.length === 0 ? (
                  <div className="flex flex-col gap-2 h-full justify-center items-center px-6 text-center">
                    <p className="text-neutral-800 font-semi-bold mobile-heading-h4 ">{SHIFTS.CARD_HEAD}</p>
                    <p className="">{SHIFTS.CARD_SUBHEAD}</p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 pb-4 w-full overflow-y-scroll unposted-sub">
                    {/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
                    {unpostedData &&
                      unpostedData?.length &&
                      unpostedData?.map((item: any) => {
                        return (
                          <div className="flex flex-col items-center gap-4" key={item?.id}>
                            <div className="shift-card-box">
                              <div className="shift-card-header-box">
                                <h3 className="text-neutral-800 font-medium text-20">{item?.facility?.name}</h3>
                                <p className="text-neutral-600 para-p2 font-normal">{item?.start_date}</p>
                              </div>
                              <div className="card-facility-wrapper">
                                <div className="facility-box">
                                  <AwardIcon fill="#68696C" />
                                  <div className="certificate-text-wrapper">
                                    <p className="certificate-text">{item?.certificate?.name}</p>
                                  </div>
                                </div>
                                <div className="facility-box">
                                  <StethoscopeIcon fill="#68696C" />
                                  <div className="facility-text-wrapper">
                                    <p className="facility-text">{item?.speciality?.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-2">
                                <span className="shift-card-clock-icon">
                                  <ClockIcon fill="#68696C" />
                                </span>
                                <p className="text-neutral-500 para-p2 font-normal">
                                  {formatTime(item?.start_time)} - {formatTime(item?.end_time)}
                                </p>
                              </div>
                              <div className="flex gap-2">
                                <HospitalOutline fill="#68696C" />
                                <p className="text-neutral-500 para-p2 font-normal">{item?.facility?.name}</p>
                              </div>
                              <div className="footer-box-wrapper">
                                <Avatar.Group max={{ count: 4 }} maxStyle={{ color: '#2B12DD', backgroundColor: '#F2F0FE' }}>
                                  {item?.invited_provider?.map((provider: IProviderInvite) => {
                                    return (
                                      <Tooltip title={provider?.first_name} key={provider?.id}>
                                        <Avatar
                                          src={provider?.profile_image && provider?.base_url ? `${String(provider.base_url)}${String(provider.profile_image)}` : profile}
                                          alt="Provider Image"
                                        />
                                      </Tooltip>
                                    )
                                  })}
                                </Avatar.Group>
                                <div className="flex items-start gap-4">
                                  <Button
                                    icon={<DeleteIcon />}
                                    className="footer-btn"
                                    onClick={() => {
                                      setIsDeleteModalOpen(true)
                                      setReset(false)
                                      setDeleteId(item?.id)
                                    }}
                                  />
                                  <Button
                                    icon={<EditPenIcon />}
                                    className="footer-btn"
                                    onClick={() => {
                                      setReset(false)
                                      handleEditUnpostedShift(item)
                                    }}
                                  />
                                  <Button
                                    icon={<DuplicateIcon />}
                                    className="footer-btn"
                                    onClick={() => {
                                      void cloneShift(item?.id)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div className={`footer-btn-wrapper w-60-btn ${unpostedData?.length === 0 ? 'border-r-stroke' : ''} `}>
                <CustomButton text={SHIFTS.RESET} type="default" className="w-160 text-neutral-800 h-49" onClick={handleResetField} />
                <CustomButton text={editId ? SHIFTS.UPDATE : SHIFTS.CREATE} type="primary" htmlType="submit" className="w-160 h-49" />
              </div>
              {unpostedData?.length !== 0 && (
                <div className="footer-btn-wrapper border-r-stroke border-t-stroke w-40">
                  <CustomButton text={SHIFTS.POST} type="primary" className="w-160 h-49" onClick={handlePostData} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          mainHead={SHIFTS.DELETE_SHIFT_HEAD}
          okClick={() => {
            void handleDeleteShift(deleteId)
          }}
          okText={COMMON.DELETE}
          cancelText={COMMON.CANCEL}
        />
      )}

      {isCommonCancelModalOpen && (
        <DeleteCancelModal
          isModalOpen={isCommonCancelModalOpen}
          setIsModalOpen={setIsCommonCancelModalOpen}
          mainHead={SHIFTS.MAIN_HEAD_DELETE}
          okClick={() => {
            void handleDeleteAllUnpostedShift()
          }}
          okText={SHIFTS.YES_CANCEL}
          cancelText={SHIFTS.GO_BACK}
          subHead={SHIFTS.SUB_HEAD_DELETE}
        />
      )}

      {isInviteModalOpen && (
        <CustomModal
          onCancel={() => {
            setIsInviteModalOpen(false)
          }}
          footer={null}
          closable={true}
          width={600}
          open={isInviteModalOpen}
          title={SHIFTS.INVITE_PROVIDER}
          className="invite-modal"
          content={
            <InviteProvider
              isEditInvitedData={isEditInvitedData}
              setIsEditInvitedData={setIsEditInvitedData}
              certificate={formData?.certificate}
              speciality={formData?.speciality}
              setIsModalOpen={setIsInviteModalOpen}
              setSelectedCheckboxes={setSelectedInviteProvider}
              selectedCheckboxes={selectedInviteProvider}
            />
          }
        />
      )}

      {isSelectedDateModalOpen && (
        <CustomModal
          onCancel={() => {
            setIsSelectedDateModalOpen(false)
          }}
          footer={null}
          closable={false}
          width={560}
          open={isSelectedDateModalOpen}
          // title={SHIFTS.INVITE_PROVIDER}
          className="invite-modal"
          content={<CustomCalender selectedDates={selectedDates} setSelectedDates={setSelectedDates} setIsModalOpen={setIsSelectedDateModalOpen} setError={setError} />}
        />
      )}
    </>
  )
}

export default CreateShift

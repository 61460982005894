import React, { useEffect, useRef, useState } from 'react'
import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import './CommonTextArea.scss'

interface IRules {
  required?: boolean
  message?: string
}

interface ITextArea {
  name: string
  disabled?: boolean
  type?: string
  rules?: IRules[]
  value?: string
  placeholder: string
  textAreaClass?: string
  labelClass?: string
  defaultValue?: string
  rows: number
  textClass?: string
  wrapperClass?: string
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  onClick?: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  error?: string | boolean
}

const CommonTextArea = ({
  name,
  disabled = false,
  value,
  error,
  type = 'text',
  rules,
  placeholder,
  textAreaClass,
  labelClass,
  defaultValue,
  textClass,
  wrapperClass,
  onChange,
  rows,
  ...props
}: ITextArea) => {
  const [focused, setFocused] = useState(false)
  const textRef = useRef<any>(null)

  useEffect(() => {
    const hasValue = textRef?.current ? textRef?.current?.input?.value !== '' : false
    setFocused(hasValue)
  }, [])

  useEffect(() => {
    if (value) {
      setFocused(true)
    } else {
      setFocused(false)
    }
  }, [value])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(e.target.value !== '')
    if (props.onBlur) props.onBlur(e)
  }

  return (
    <>
      <div className={`${wrapperClass as string} text-area-wrapper ${!error ? 'mb-6' : ''}`}>
        <div className={`floatingLabelTextArea ${textAreaClass as string}`}>
          <label
            htmlFor={name}
            className={`label-float ${focused ? 'actives' : ''} ${value ? 'actives' : ''} ${disabled ? 'actives text-neutral-300' : ''} ${labelClass as string}`}
          >
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules}>
            <TextArea
              id={name}
              typeof={type}
              size="small"
              value={value}
              ref={textRef}
              defaultValue={defaultValue}
              className={`common-text-area ${textClass as string}`}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={props.onKeyDown}
              onClick={props.onClick}
              onChange={onChange}
              rows={rows}
              {...props}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </>
  )
}

export default CommonTextArea

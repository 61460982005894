import React from 'react'

const OutlookLogo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5633 1.43823H8.20053C7.93277 1.43823 7.67597 1.5446 7.48663 1.73394C7.29729 1.92328 7.19092 2.18008 7.19092 2.44785V3.59553L14.159 5.75283L21.5729 3.59553V2.44785C21.5729 2.18008 21.4665 1.92328 21.2772 1.73394C21.0879 1.5446 20.8311 1.43823 20.5633 1.43823Z"
        fill="#0364B8"
      />
      <path
        d="M22.7592 12.5159C22.8645 12.1849 22.9486 11.8474 23.0108 11.5056C23.0107 11.4216 22.9886 11.3392 22.9465 11.2665C22.9045 11.1938 22.8441 11.1334 22.7714 11.0914L22.762 11.0856L22.7592 11.0842L14.9656 6.64448C14.9319 6.62266 14.8971 6.60274 14.8613 6.5848C14.7122 6.5108 14.5481 6.47229 14.3816 6.47229C14.2152 6.47229 14.0511 6.5108 13.902 6.5848C13.8662 6.60249 13.8314 6.62218 13.7977 6.64376L6.00413 11.0849L6.00125 11.0864L5.99262 11.0914C5.91976 11.1333 5.85921 11.1936 5.81705 11.2663C5.7749 11.339 5.75261 11.4216 5.75244 11.5056C5.81474 11.8474 5.8988 12.1849 6.00413 12.5159L14.268 18.56L22.7592 12.5159Z"
        fill="#0A2767"
      />
      <path d="M17.2585 3.59546H12.2248L10.7715 5.75276L12.2248 7.91006L17.2585 12.2247H21.5731V7.91006L17.2585 3.59546Z" fill="#28A8EA" />
      <path d="M7.19092 3.59546H12.2246V7.91006H7.19092V3.59546Z" fill="#0078D4" />
      <path d="M17.2583 3.59546H21.5729V7.91006H17.2583V3.59546Z" fill="#50D9FF" />
      <path d="M17.2583 12.2248L12.2246 7.91016H7.19092V12.2248L12.2246 16.5394L20.0139 17.8107L17.2583 12.2248Z" fill="#0364B8" />
      <path d="M12.2246 7.91016H17.2583V12.2248H12.2246V7.91016Z" fill="#0078D4" />
      <path d="M7.19092 12.2247H12.2246V16.5393H7.19092V12.2247Z" fill="#064A8C" />
      <path d="M17.2583 12.2247H21.5729V16.5393H17.2583V12.2247Z" fill="#0078D4" />
      <path
        opacity="0.5"
        d="M14.5184 18.1343L6.03809 11.95L6.39404 11.3244C6.39404 11.3244 14.1208 15.7253 14.2387 15.7915C14.287 15.8108 14.3387 15.82 14.3906 15.8185C14.4426 15.817 14.4937 15.8049 14.5407 15.7828L22.4026 11.3029L22.7593 11.9278L14.5184 18.1343Z"
        fill="#0A2767"
      />
      <path
        d="M22.7717 11.9205L22.7617 11.9263L22.7595 11.9277L14.9659 16.3675C14.8111 16.4673 14.6333 16.5257 14.4495 16.5371C14.2657 16.5486 14.082 16.5127 13.916 16.4329L16.6299 20.0723L22.5654 21.3645V21.3674C22.7034 21.2675 22.8158 21.1363 22.8933 20.9845C22.9708 20.8328 23.0112 20.6648 23.0112 20.4944V11.5056C23.0112 11.5897 22.9891 11.6723 22.9471 11.7452C22.905 11.818 22.8446 11.8785 22.7717 11.9205Z"
        fill="#1490DF"
      />
      <path
        opacity="0.05"
        d="M23.0112 20.4943V19.9637L15.8324 15.8734L14.9659 16.3674C14.8111 16.4672 14.6333 16.5257 14.4495 16.5371C14.2657 16.5486 14.082 16.5127 13.916 16.4329L16.6299 20.0722L22.5654 21.3645V21.3673C22.7034 21.2675 22.8158 21.1363 22.8933 20.9845C22.9708 20.8327 23.0112 20.6648 23.0112 20.4943Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M22.9752 20.7697L15.1061 16.2876L14.9659 16.3667C14.8112 16.4666 14.6334 16.5252 14.4496 16.5368C14.2658 16.5483 14.0821 16.5126 13.916 16.4329L16.6299 20.0722L22.5654 21.3644V21.3673C22.7666 21.2215 22.9114 21.0107 22.9752 20.7705V20.7697Z"
        fill="black"
      />
      <path
        d="M6.00415 11.9299V11.9227H5.99696L5.97538 11.9083C5.90664 11.8661 5.84998 11.8069 5.81092 11.7363C5.77186 11.6657 5.75172 11.5863 5.75246 11.5056V20.4944C5.75227 20.6361 5.78004 20.7764 5.83418 20.9074C5.88832 21.0383 5.96777 21.1573 6.06797 21.2575C6.16817 21.3577 6.28715 21.4372 6.41811 21.4913C6.54906 21.5454 6.68941 21.5732 6.83111 21.573H21.9322C22.0221 21.5721 22.1114 21.5601 22.1983 21.5371C22.2433 21.5292 22.287 21.5147 22.3277 21.4939C22.343 21.4924 22.3577 21.4875 22.3709 21.4795C22.4297 21.4555 22.4853 21.4241 22.5363 21.386C22.5506 21.3789 22.5578 21.3789 22.565 21.3645L6.00415 11.9299Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.1"
        d="M12.9434 17.738V5.99225C12.9427 5.73826 12.8414 5.49489 12.6618 5.31529C12.4822 5.13568 12.2389 5.03445 11.9849 5.03369H7.21221V10.3953L6.00413 11.0842L6.00053 11.0856L5.9919 11.0907C5.91907 11.1327 5.8586 11.1932 5.81656 11.266C5.77453 11.3389 5.75241 11.4215 5.75244 11.5056V18.6966H11.9849C12.2389 18.6958 12.4822 18.5946 12.6618 18.415C12.8414 18.2354 12.9427 17.992 12.9434 17.738Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M12.2243 18.4571V6.71137C12.2236 6.45738 12.1223 6.214 11.9427 6.0344C11.7631 5.8548 11.5198 5.75357 11.2658 5.75281H7.21221V10.3953L6.00413 11.0842L6.00053 11.0857L5.9919 11.0907C5.91907 11.1327 5.8586 11.1932 5.81656 11.2661C5.77453 11.3389 5.75241 11.4215 5.75244 11.5056V19.4157H11.2658C11.5198 19.4149 11.7631 19.3137 11.9427 19.1341C12.1223 18.9545 12.2236 18.7111 12.2243 18.4571Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M12.2243 17.0189V6.71137C12.2236 6.45738 12.1223 6.214 11.9427 6.0344C11.7631 5.8548 11.5198 5.75357 11.2658 5.75281H7.21221V10.3953L6.00413 11.0842L6.00053 11.0857L5.9919 11.0907C5.91907 11.1327 5.8586 11.1932 5.81656 11.2661C5.77453 11.3389 5.75241 11.4215 5.75244 11.5056V17.9775H11.2658C11.5198 17.9767 11.7631 17.8755 11.9427 17.6959C12.1223 17.5163 12.2236 17.2729 12.2243 17.0189Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.5052 17.0189V6.71137C11.5045 6.45738 11.4032 6.214 11.2236 6.0344C11.044 5.8548 10.8007 5.75357 10.5467 5.75281H7.21221V10.3953L6.00413 11.0842L6.00053 11.0857L5.9919 11.0907C5.91907 11.1327 5.8586 11.1932 5.81656 11.2661C5.77453 11.3389 5.75241 11.4215 5.75244 11.5056V17.9775H10.5467C10.8007 17.9767 11.044 17.8755 11.2236 17.6959C11.4032 17.5163 11.5045 17.2729 11.5052 17.0189Z"
        fill="black"
      />
      <path
        d="M0.95856 5.75281H10.547C10.8013 5.75281 11.0451 5.8538 11.2248 6.03356C11.4046 6.21333 11.5056 6.45714 11.5056 6.71137V16.2998C11.5056 16.5541 11.4046 16.7979 11.2248 16.9777C11.0451 17.1574 10.8013 17.2584 10.547 17.2584H0.95856C0.704334 17.2584 0.460521 17.1574 0.280756 16.9777C0.100991 16.7979 0 16.5541 0 16.2998L0 6.71137C0 6.45714 0.100991 6.21333 0.280756 6.03356C0.460521 5.8538 0.704334 5.75281 0.95856 5.75281Z"
        fill="#0078D4"
      />
      <path
        d="M2.78067 9.6848C3.03537 9.14197 3.44657 8.68769 3.96143 8.38035C4.53183 8.05382 5.1813 7.89108 5.83828 7.91006C6.4466 7.89686 7.04685 8.05109 7.57347 8.3559C8.06918 8.65117 8.4684 9.08412 8.72259 9.6021C8.9995 10.173 9.13748 10.8014 9.12529 11.4358C9.1386 12.0985 8.9965 12.7551 8.71036 13.3529C8.45065 13.8893 8.03939 14.3379 7.52745 14.643C6.98018 14.9575 6.35714 15.1159 5.7261 15.1011C5.10442 15.1159 4.49059 14.9599 3.95136 14.6502C3.45169 14.3544 3.04776 13.921 2.78786 13.4018C2.50905 12.8393 2.36923 12.2181 2.38013 11.5904C2.36807 10.9331 2.50501 10.2816 2.78067 9.6848ZM4.03909 12.7467C4.17478 13.0899 4.40491 13.3876 4.70282 13.6053C5.00639 13.8178 5.37005 13.9274 5.74048 13.9181C6.13509 13.9335 6.524 13.8202 6.84861 13.5953C7.14321 13.3776 7.36748 13.0783 7.49365 12.7345C7.63511 12.3514 7.70483 11.9455 7.69931 11.5372C7.7037 11.125 7.63804 10.715 7.50515 10.3248C7.38789 9.97374 7.17163 9.66408 6.88241 9.43311C6.56636 9.1969 6.17858 9.07678 5.78435 9.09298C5.40586 9.08341 5.03405 9.19392 4.72224 9.40866C4.41848 9.62679 4.18304 9.92682 4.04341 10.2737C3.73452 11.0691 3.73273 11.9509 4.03837 12.7474L4.03909 12.7467Z"
        fill="white"
      />
    </svg>
  )
}

export default OutlookLogo

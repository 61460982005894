import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form } from 'antd'
import { DownIcon } from 'assets/svgs'
import { COMMON, WELCOME } from 'constants/Constants'
import { WLC_LIST_DATA } from 'constants/Welcom'

import { type IMultiLevelForm } from '../types'

const WelcomeCard = ({ onClick }: IMultiLevelForm) => {
  const navigate = useNavigate()

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (onClick) onClick()
  }

  const handleBackClick = () => {
    navigate('/signup')
  }

  return (
    <>
      <div className="back-btn-wrapper flex justify-center items-center p-2 cursor-pointer" onClick={handleBackClick}>
        <DownIcon className="back-icon" />
        <p className="text-primary-500 para-p2">{COMMON.BACK}</p>
      </div>
      <div className="mx-auto max-w-532 w-full flex flex-col gap-12 items-center welcome-card">
        <div className="flex flex-col items-center gap-10 padding-32 w-full min-w-full wlc-box-shadow ">
          <div className="flex flex-col items-center gap-2">
            <p className="desktop-heading-h3 font-medium text-center">{WELCOME.WELCOME}</p>
            <p className="max-w-371 w-full para-p2 font-normal text-neutral-500 text-center">{WELCOME.WELCOME_SUB}</p>
          </div>
          <div className="flex flex-col gap-6 items=-start">
            {WLC_LIST_DATA.map((listData) => {
              return (
                <div className="flex items-start gap-2" key={listData.id}>
                  {listData.icon}
                  <span className="para-p1 text-neutral-800 font-normal">{listData.listText}</span>
                </div>
              )
            })}
          </div>
          <div className="min-w-full">
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" className="common-button" onClick={handleFormSubmit}>
                {COMMON.GET_STARTED}
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeCard

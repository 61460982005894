import { CalenderSolid, ChatSolid, Dollar, PlusCircle } from 'assets/svgs'

export const WLC_LIST_DATA = [
  {
    id: 1,
    icon: <PlusCircle />,
    listText: 'Post Shifts with Ease',
  },
  {
    id: 2,
    icon: <ChatSolid />,
    listText: 'Streamlined Communication',
  },
  {
    id: 3,
    icon: <CalenderSolid />,
    listText: 'Manage your schedule seamlessly',
  },
  {
    id: 4,
    icon: <Dollar />,
    listText: 'Automated Payroll Processing',
  },
]

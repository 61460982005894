import React, { useEffect, useState } from 'react'
import { Calendar, type CalendarProps, message } from 'antd'
import { NextIcon, PrevIcon } from 'assets/svgs'
import dayjs, { type Dayjs } from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICalendarShift } from 'types'

import { GET_SCHEDULED_SHIFT_CALENDAR } from '../api'

const getListData = (value: Dayjs, shiftData: Array<{ date: string; shifts: ICalendarShift[] }>) => {
  const formattedDate = value.format('YYYY-MM-DD')
  const dayData = shiftData.find((item) => item.date === formattedDate)
  const shifts = dayData?.shifts ?? []

  return shifts.map((shift) => {
    const startTime = dayjs(shift.start_time, 'HH:mm:ss').format('h:mm A').toLowerCase()
    const endTime = dayjs(shift.end_time, 'HH:mm:ss').format('h:mm A').toLowerCase()

    return {
      content: `${startTime} - ${endTime}`,
    }
  })
}

const Schedule = ({ providerId, tabNo }: { providerId: string; tabNo: string }) => {
  const dispatch = useAppDispatch()
  const [date, setDate] = useState(dayjs())
  const [shiftData, setShiftData] = useState<Array<{ date: string; shifts: any[] }>>([])

  useEffect(() => {
    if (providerId && tabNo === '2') {
      void getCredentialData(providerId)
    }
  }, [tabNo, date])

  const getCredentialData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const formattedDate = date.format('YYYY-MM-DD')
      const response = await GET_SCHEDULED_SHIFT_CALENDAR(id, String(formattedDate))
      if (response?.status === 200) {
        setShiftData(response?.data?.data ?? [])
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
      return 1394
    }
  }

  const onSelectDate = (e: dayjs.Dayjs) => {
    setDate(e)
  }

  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value)
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null
  }

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value, shiftData)
    return (
      <div className="gap-2 flex flex-col">
        {listData.map((item, index) => (
          <p className="date-detail" key={index}>
            {item.content}
          </p>
        ))}
      </div>
    )
  }

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current)
    if (info.type === 'month') return monthCellRender(current)
    return info.originNode
  }

  const handlePrevMonth = () => {
    const newDate = date.subtract(1, 'month')
    setDate(newDate)
  }

  const handleNextMonth = () => {
    const newDate = date.add(1, 'month')
    setDate(newDate)
  }

  const headerRender = () => {
    const monthName = date.format('MMMM')
    const year = date.format('YYYY')

    return (
      <div className="calendar-header-wrapper">
        <span className="calender-btn" onClick={handlePrevMonth}>
          <PrevIcon />
        </span>
        <span className="calender-month-wrapper">{`${monthName}  ${year}`}</span>
        <span className="calender-btn" onClick={handleNextMonth}>
          <NextIcon />
        </span>
      </div>
    )
  }

  return (
    <div className="calendar-card">
      <Calendar value={date} onSelect={onSelectDate} key={date.month()} cellRender={cellRender} headerRender={headerRender} />
    </div>
  )
}

export default Schedule

import React from 'react'

const DrawerOpen = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2002 6.89998C1.2002 5.07744 2.67766 3.59998 4.5002 3.59998H19.5002C21.3228 3.59998 22.8002 5.07744 22.8002 6.89998V17.1C22.8002 18.9225 21.3228 20.4 19.5002 20.4H4.5002C2.67766 20.4 1.2002 18.9225 1.2002 17.1V6.89998ZM7.8002 18.6H19.5002C20.3287 18.6 21.0002 17.9285 21.0002 17.1V6.89998C21.0002 6.07154 20.3287 5.39998 19.5002 5.39998H7.8002V18.6ZM6.0002 5.39998V18.6H4.5002C3.67176 18.6 3.0002 17.9285 3.0002 17.1V6.89998C3.0002 6.07154 3.67176 5.39998 4.5002 5.39998H6.0002ZM14.7022 8.6273C15.0738 8.29708 15.6427 8.33054 15.9729 8.70205L18.3729 11.4021C18.6759 11.743 18.6759 12.2569 18.3729 12.5979L15.9729 15.2979C15.6427 15.6695 15.0738 15.7028 14.7022 15.3727C14.3307 15.0425 14.2974 14.4735 14.6275 14.102L15.6961 12.9H11.1002C10.6031 12.9 10.2002 12.497 10.2002 12C10.2002 11.5029 10.6031 11.1 11.1002 11.1H15.6961L14.6275 9.8979C14.2974 9.5264 14.3307 8.95753 14.7022 8.6273Z"
      fill="#5E6782"
    />
  </svg>
)

export default DrawerOpen

import React from 'react'

const NotificationUnreadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="4" fill="#59D1F4" />
    </svg>
  )
}

export default NotificationUnreadIcon

import { useState } from 'react'
import { type ColumnsType } from 'antd/es/table'
import { UploadIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import CustomModal from 'component/CustomModal/CustomModal'
import CustomTable from 'component/Table/CustomTable'
import TableImage from 'component/Table/TableImage'
import { BUTTON_STRING, FACILITY_USER_PROFILE } from 'constants/Constants'

import AddDocument from '../Modal/AddDocument'

const tableData = [
  {
    id: 1,
    name: 'Rate_Adjustment-Docusign-Addington Place....pdf',
    documentCategory: 'Contract',
    dateEnding: '12/15/2023',
    note: 'Updated Rates - then realized we need to obtain full Agreement.',
    user: 'Brooklyn Simmons',
    dateUpload: '12/15/2023',
  },
]

const Document = () => {
  const [isOpenModal, setIsModalOpen] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 1,
    offset: 0,
    limit: 10,
    current: 1,
  })

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleClick = () => {
    setIsModalOpen(true)
  }

  const columns: ColumnsType = [
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.DOCUMENT_NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 320,
    },
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.DOCUMENT_CATEGORY,
      dataIndex: 'documentCategory',
      key: 'documentCategory',
      align: 'center',
      width: 193,
    },
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.Date_END,
      dataIndex: 'dateEnding',
      key: 'dateEnding',
      align: 'center',
      width: 193,
    },
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.NOTES,
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: 350,
    },
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.USER,
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      width: 250,
      render(value, record) {
        return <TableImage name={record.user} image={undefined} email={''} />
      },
    },
    {
      title: FACILITY_USER_PROFILE.ADD_DOCUMENT.DATE_UPLOADED,
      dataIndex: 'dateUpload',
      key: 'dateUpload',
      align: 'center',
      width: 192,
    },
  ]

  return (
    <div className="flex px-10 flex-col items-center gap-4 self-stretch ">
      <div className="flex w-full justify-end items-center">
        <CustomButton isIcon svgIcon={<UploadIcon />} text={BUTTON_STRING.UPLOAD_DOCUMENT} type="primary" className="rounded-3 border-0" onClick={handleClick} />
      </div>
      <CustomTable
        className="border-stroke rounded-4 overflow-hidden"
        column={columns}
        data={tableData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.offset}
        onChangePage={handlePageChange}
        name={FACILITY_USER_PROFILE.DOCUMENT}
      />
      {isOpenModal && (
        <CustomModal
          width={915}
          open={isOpenModal}
          title={FACILITY_USER_PROFILE.UPLOAD_DOCUMENT}
          onCancel={() => {
            setIsModalOpen(false)
          }}
          footer={null}
          className="hide-closeicon-modal"
          content={<AddDocument isModalOpen={setIsModalOpen} />}
        />
      )}
    </div>
  )
}

export default Document

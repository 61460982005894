// hooks/useDevice.js
import { useMemo } from 'react'
import DeviceDetector from 'device-detector-js'

export const useDevice = () => {
  const deviceDetector = new DeviceDetector()
  const userAgent = navigator.userAgent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const device = useMemo(() => deviceDetector.parse(userAgent), [userAgent])

  return {
    deviceId: device?.device?.brand ?? 'UnknownBrand',
    deviceModel: device?.device?.model ?? 'UnknownModel',
    deviceType: 'web', // since it's a web app
  }
}

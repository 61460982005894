import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, message } from 'antd'
import { LogoIcon } from 'assets/svgs'
import CustomButton from 'component/Button/CustomButton'
import InputField from 'component/InputField/InputField'
import PasswordField from 'component/PasswordField/PasswordField'
import { COMMON, LOGIN } from 'constants/Constants'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useDevice } from 'hooks/useDevice'
import { FACILITY_LOGIN, FORGOT_PASSWORD } from 'service/StaticTokenServices'
import { setAuthLogin, setSignature } from 'store/slice/AuthSlice'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData } from 'types'
import { handleFieldsChange } from 'utils/helper'
import { emailValidations, loginPasswordValidations } from 'utils/Validations'

import { requestPermission } from '../../firebase/messaging_init_in_sw'

import './login.scss'

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  })
  const [errorData, setErrorData] = useState({
    email: '',
    password: '',
  })
  const location = useLocation()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { deviceId, deviceModel, deviceType } = useDevice()
  const isFacilityUserLoginUrl = location.pathname === '/login'

  const onFinish = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const token = await requestPermission()

      const payload = {
        email: loginData?.email?.toLowerCase(),
        password: loginData?.password,
        device_id: '79112e3b66cb9b161383d05e5666c80b',
        device_name: `${deviceId}${deviceModel} ${deviceType}`,
        device_type: 'web',
        firebase: token,
      }

      const response = await FACILITY_LOGIN(location?.pathname === '/login' ? '/auth/facility-user/login' : '/auth/facility/login', payload)

      if (response?.status === 200) {
        const authData = {
          accessToken: response?.data?.data?.jwt,
          permission: response?.data?.data?.permission,
          profileDetails: {
            country_code: response?.data?.data?.country_code,
            description: response?.data?.data?.description,
            email: response?.data?.data?.email,
            facility_type: response?.data?.data?.facility_type,
            facility_id: response?.data?.data?.id,
            facility_name: response?.data?.data?.name,
            mobile_no: response?.data?.data?.mobile_no,
            is_master: response?.data?.data?.is_master,
            image: response?.data?.data?.image,
            isFacility: true,
            base_url: response?.data?.data?.base_url,
          },
        }

        const facilityUserAuthData = {
          accessToken: response?.data?.data?.jwt,
          permission: response?.data?.data?.permission,
          profileDetails: {
            id: response?.data?.data?.id,
            country_code: response?.data?.data?.country_code,
            description: response?.data?.data?.description,
            email: response?.data?.data?.email,
            facility_id: response?.data?.data?.facility_id,
            first_name: response?.data?.data?.first_name,
            last_name: response?.data?.data?.last_name,
            mobile_no: response?.data?.data?.mobile_no,
            is_master: false,
            image: response?.data?.data?.image,
            isFacility: false,
            base_url: response?.data?.data?.base_url,
          },
        }

        navigate('/')
        dispatch(setAuthLogin(location?.pathname === '/login' ? facilityUserAuthData : authData))
        dispatch(setSignature(response?.data?.data?.signature))
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleForgotPassword = () => {
    form
      .validateFields(['email'])
      .then(() => {
        const email = form.getFieldValue('email').toLowerCase()
        const payload = {
          email,
        }
        void forgotPass(payload)
      })
      .catch((errorInfo: any) => {
        setErrorData((prevError: any) => ({
          ...prevError,
          email: 'Please enter a valid email address to reset your password.',
        }))
      })
  }

  const forgotPass = async (payload: { email: string }) => {
    dispatch(setGlobalLoader(true))
    const response = await FORGOT_PASSWORD(payload, location?.pathname === '/login' ? 'facility_user' : 'facility')
    if (response?.data?.statusCode === 1) {
      navigate(`/forgot-password?email=${payload.email}`)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleSignup = () => {
    navigate('/signup')
  }
  const handleLogin = () => {
    navigate('/facility-login')
  }
  const handleFacilityUser = () => {
    navigate('/login')
  }

  return (
    <div className="bg-neutral-50">
      <div className="login-wrapper screen-height">
        <div className="login-sub-wrapper">
          <LogoIcon />
          <p className="text-wlcm mt-6 text-neutral-800 font-medium">{LOGIN.WELCOME_BACK}</p>

          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            className="login-form"
            onFieldsChange={(allFields: IFieldData[]) => {
              handleFieldsChange(allFields, setErrorData)
            }}
          >
            <InputField
              name="email"
              placeholder={COMMON?.EMAIL}
              value={loginData?.email}
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  email: e.target.value,
                })
              }}
              error={errorData?.email}
              rules={emailValidations}
            />
            <PasswordField
              error={errorData?.password}
              rules={loginPasswordValidations}
              placeholder={COMMON.PASSWORD}
              name="password"
              wrapperClass="mb-4"
              value={loginData?.password}
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  password: e.target.value,
                })
              }}
            />
            <div className="w-full flex justify-end forgot-btn-wrapper">
              <Button type="text" className="forgot-pass-btn flex justify-end forgot-btn font-medium para-p2" onClick={handleForgotPassword}>
                {COMMON.FORGOT}
              </Button>
            </div>

            <CustomButton className="mt-6" text={COMMON.CONTINUE} htmlType="submit" />
            {!isFacilityUserLoginUrl && (
              <>
                <div className="divider">
                  <span className="text">{COMMON.OR}</span>
                </div>
                <div className="signup-string">
                  <p>{LOGIN.DONT_HAVE}</p>
                  <span onClick={handleSignup}>{COMMON.SIGNUP}</span>
                </div>
                <div className="signup-string">
                  <p>{LOGIN.ARE_YOU_FACILITY_USER}</p>
                  <span onClick={handleFacilityUser}>{COMMON.LOGIN}</span>
                </div>
              </>
            )}
            {isFacilityUserLoginUrl && (
              <>
                <div className="divider">
                  <span className="text">{COMMON.OR}</span>
                </div>
                <div className="signup-string">
                  <p>{LOGIN.ARE_YOU_FACILITY}</p>
                  <span onClick={handleLogin}>{COMMON.LOGIN}</span>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login

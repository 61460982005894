const PlusCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.5 2C7 2 2.5 6.5 2.5 12C2.5 17.5 7 22 12.5 22C18 22 22.5 17.5 22.5 12C22.5 6.5 18 2 12.5 2ZM16.5 13H13.5V16C13.5 16.6 13.1 17 12.5 17C11.9 17 11.5 16.6 11.5 16V13H8.5C7.9 13 7.5 12.6 7.5 12C7.5 11.4 7.9 11 8.5 11H11.5V8C11.5 7.4 11.9 7 12.5 7C13.1 7 13.5 7.4 13.5 8V11H16.5C17.1 11 17.5 11.4 17.5 12C17.5 12.6 17.1 13 16.5 13Z"
        fill="#252431"
      />
    </svg>
  )
}

export default PlusCircle
